import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import { WiStars } from "react-icons/wi";
import JobCareerProspect from "../JobCareerProspect/JobCareerProspect";
import JobCoverLetter from "../JobCoverLetter/JobCoverLetter";
import JobFitCheck from "../JobFitCheck/JobFitCheck";
import JobLinkedInNetworking from "../JobLinkedInNetworking/JobLinkedInNetworking";
import {
  AIActionButtonsContainer,
  BenefitsContainer,
  CardContainer,
  CardHeader,
  CardListContainer,
  Container,
  HeaderContainer,
  JobDescriptionContainer,
  ListItem,
  ExtensionContainer,
} from "./SelectedJobs.styles";
const SelectedJobs = ({ uid, selectedJobs, profileComplete }) => {
  const [openFitCheck, setOpenFitCheck] = useState(false);
  const [openCoverLetter, setOpenCoverLetter] = useState(false);
  const [openCareerProspects, setOpenCareerProspects] = useState(false);
  const [openConnectRecruiters, setOpenConnectRecruiters] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = selectedJobs?.description || "";
  const truncatedDescription = description.slice(0, 250);
  return (
    <>
      <Container>
        <HeaderContainer>
          <Typography variant="h5" gutterBottom>
            {selectedJobs?.title}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {selectedJobs?.company_name}
            <span
              style={{
                color: "grey",
                marginLeft: "0.2rem",
                fontSize: "1rem",
              }}
            >
              {selectedJobs?.location}
            </span>
          </Typography>
          <ExtensionContainer>
            {selectedJobs?.extensions.map((ext, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ fontSize: "16px", color: "grey" }}
                gutterBottom
              >
                {ext}
              </Typography>
            ))}
          </ExtensionContainer>

          <Button
            variant="contained"
            onClick={() =>
              window.open(selectedJobs?.related_links[0]?.link, "_blank")
            }
            endIcon={<IoMdArrowForward />}
          >
            Apply
          </Button>
        </HeaderContainer>
        <AIActionButtonsContainer>
          <Button
            startIcon={<WiStars />}
            color="secondary"
            onClick={() => setOpenFitCheck(true)}
            sx={{
              textTransform: "none",
              borderColor: "primary.main",
              borderRadius: "20px",
              border: "1px solid #007bff",
              backgroundColor: "#ececec",
              "&:hover": {
                borderColor: "primary.main",
                border: "1px solid #007bff",
              },
              cursor: "pointer",
            }}
          >
            Am I a right fit here?
          </Button>
          <Button
            startIcon={<WiStars />}
            color="secondary"
            onClick={() => setOpenCoverLetter(true)}
            sx={{
              textTransform: "none",
              borderColor: "primary.main",
              borderRadius: "20px",
              border: "1px solid #007bff",
              backgroundColor: "#ececec",
              "&:hover": {
                borderColor: "primary.main",
                border: "1px solid #007bff",
              },
              cursor: "pointer",
            }}
          >
            Generate Cover Letter
          </Button>
          {/* <Button
            startIcon={<WiStars />}
            color="secondary"
            sx={{
              textTransform: "none",
              borderColor: "primary.main",
              backgroundColor: "#ececec",
              "&:hover": {
                borderColor: "primary.main",
              },
              cursor: "pointer",
            }}
            onClick={() => setOpenCareerProspects(true)}
          >
            What could my career trajectory look like in this company?
          </Button> */}
          <Button
            startIcon={<WiStars />}
            color="secondary"
            onClick={() => setOpenConnectRecruiters(true)}
            sx={{
              textTransform: "none",
              borderColor: "primary.main",
              borderRadius: "20px",
              border: "1px solid #007bff",
              backgroundColor: "#ececec",
              "&:hover": {
                borderColor: "primary.main",
                border: "1px solid #007bff",
              },
              cursor: "pointer",
            }}
          >
            Connect with {selectedJobs?.company_name} recruiters
          </Button>
        </AIActionButtonsContainer>
        <JobDescriptionContainer>
          <CardHeader variant="h6" gutterBottom>
            Description
          </CardHeader>
          <Typography>
            {showFullDescription ? description : truncatedDescription}
            {description.length > 250 && (
              <Button
                onClick={toggleDescription}
                sx={{ textTransform: "none" }}
                color="secondary"
              >
                {showFullDescription ? " View less..." : " View more..."}
              </Button>
            )}
          </Typography>
          <BenefitsContainer>
            {selectedJobs?.job_highlights?.map((highlights) => {
              const item_highlights = highlights?.items?.map((highlight) => {
                return <ListItem>{highlight}</ListItem>;
              });
              return (
                <CardContainer>
                  <CardHeader variant="h6">{highlights?.title}</CardHeader>
                  <CardListContainer>{item_highlights}</CardListContainer>
                </CardContainer>
              );
            })}
          </BenefitsContainer>
        </JobDescriptionContainer>
      </Container>
      <JobCareerProspect
        uid={uid}
        openCareerProspects={openCareerProspects}
        setOpenCareerProspects={setOpenCareerProspects}
        selectedJob={selectedJobs}
        profileComplete={true}
      />
      <JobCoverLetter
        uid={uid}
        openCoverLetter={openCoverLetter}
        setOpenCoverLetter={setOpenCoverLetter}
        selectedJob={selectedJobs}
        profileComplete={true}
      />
      <JobFitCheck
        uid={uid}
        openFitCheck={openFitCheck}
        setOpenFitCheck={setOpenFitCheck}
        selectedJob={selectedJobs}
        profileComplete={true}
      />
      <JobLinkedInNetworking
        uid={uid}
        openConnectRecruiters={openConnectRecruiters}
        setOpenConnectRecruiters={setOpenConnectRecruiters}
        infoData={{
          primaryRole: selectedJobs?.company_name,
          location: selectedJobs?.location,
        }}
        selectedJob={selectedJobs}
      />
    </>
  );
};

export default SelectedJobs;
