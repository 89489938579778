export const CalculateTokenCost = (text, modelName) => {
  if (modelName === "GPT") {
    const totalTokenCost =
      0.000019 * (text.prompt_tokens) + 0.000057 * (text.completion_tokens);
    const creditCount = totalTokenCost / 0.012;
    const newCreditCountValue = parseFloat(creditCount.toFixed(2));
    if (newCreditCountValue - Math.floor(newCreditCountValue) < 0.5) {
      if (Math.floor(newCreditCountValue) === 0) {
        return 1;
      }
      return Math.floor(newCreditCountValue);
    } else {
      return Math.round(newCreditCountValue);
    }
  }else{
    const totalTokenCost =
    0.000095 * text.prompt_tokens + 0.000095 * text.completion_tokens;
  const creditCount = totalTokenCost / 0.012;
  const newCreditCountValue = parseFloat(creditCount.toFixed(2));
  if (newCreditCountValue - Math.floor(newCreditCountValue) < 0.5) {
    if (Math.floor(newCreditCountValue) === 0) {
      return 1;
    }
    return Math.floor(newCreditCountValue);
  } else {
    return Math.round(newCreditCountValue);
  }
  }
};
