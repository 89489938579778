import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../../../../api/api";
import LoadingContainer from "../../../../../commonComponents/LoadingContainer/LoadingContainer";
import ToastNotification from "../../../../../commonComponents/ToastNotification/ToastNotification";
import { decrementPriceInformation } from "../../../../../redux/userSlice";
import { CalculateTokenCost } from "../../../../../utils/AICost";
import {
  getEmailFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../../../../utils/EmailUtil";
const SummaryDisplay = ({
  openAboutMe,
  refetch,
  profileInfo,
  type,
  setOpenAboutMe,
}) => {
  const [profileData, setProfileData] = useState({
    bio: profileInfo?.bio || "",
  });
  const [originalTextResponse, setOriginalTextResponse] = useState([
    profileInfo?.bio,
  ]);
  const { userInformation } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const [isLoading, setIsLoading] = useState(false);
  const uid = getUIDFromLocalStorage();
  const email = getEmailFromLocalStorage();
  const [currentVersionIndex, setCurrentVersionIndex] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const HandlingAbout = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post(`/profile/updating-profile/${uid}`, {
        profileData: { bio: originalTextResponse[currentVersionIndex - 1] },
      });
      setIsLoading(false);
      if (response.data.success) {
        showSnackbar("Successfully updated your profile", "success");
        setOpenAboutMe(false);
        refetch();
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const navigateVersion = (direction) => {
    let newIndex = currentVersionIndex + direction;
    newIndex = Math.max(1, Math.min(newIndex, originalTextResponse.length)); // Ensure index is within bounds and starts at 1
    setCurrentVersionIndex(newIndex);
    setProfileData({ ...profileData, bio: originalTextResponse[newIndex - 1] });
  };
  const handleGenerateClick = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(`/ai/enhanceProfile/${email}/${type}`, {
        text: originalTextResponse,
      });
      if (response.data.success) {
        pollForResult(response.data.jobId);
      } else {
        showSnackbar(
          "Looks like something went wrong. Please try again later",
          "error"
        );
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const pollForResult = async (jobId) => {
    try {
      const response = await api.get(`/ai/polling-result/${jobId}`);
      if (response.data.status === "done") {
        const creditCount = CalculateTokenCost(
          response.data.usage,
          response.data.modelName
        );
        setOriginalTextResponse((prevState) => [
          ...prevState,
          response.data.data,
        ]);
        setCurrentVersionIndex((prevIndex) => prevIndex + 1);
        setIsLoading(false);
        try {
          await api.post(`/price/updateCredit/${email}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
          setCurrentVersionIndex(currentVersionIndex + 1);
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      } else {
        setTimeout(() => pollForResult(jobId), 5000);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const handleTextChange = (text) => {
    const updatedTextResponse = [...originalTextResponse];
    updatedTextResponse[currentVersionIndex - 1] = text;
    setOriginalTextResponse(updatedTextResponse);
    setProfileData({ ...profileData, bio: text });
  };
  return (
    <>
      <Dialog
        open={openAboutMe}
        onClose={() => setOpenAboutMe(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Summary
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenAboutMe(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <LoadingContainer number={1000} />
        ) : (
          <>
            <DialogContent dividers>
              <form>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <TextField
                      color="secondary"
                      label="Summary"
                      multiline
                      rows={5}
                      required
                      fullWidth
                      value={
                        originalTextResponse[currentVersionIndex - 1] || ""
                      }
                      onChange={(e) => handleTextChange(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {pricingPlan?.price?.actionsIncluded <= 5 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/pricing")}
                      >
                        Upgrade
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                        onClick={handleGenerateClick}
                      >
                        Optimize
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      disabled={currentVersionIndex <= 1}
                      onClick={() => navigateVersion(-1)}
                      sx={{ mx: 1 }}
                    >
                      Prev
                    </Button>
                    <Typography sx={{ mx: 2 }}>
                      Version: {currentVersionIndex} /{" "}
                      {originalTextResponse.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      disabled={
                        currentVersionIndex >= originalTextResponse.length
                      }
                      onClick={() => navigateVersion(1)}
                      sx={{ mx: 1 }}
                    >
                      {" "}
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={HandlingAbout}>
                Save
              </Button>
              <Button onClick={() => setOpenAboutMe(false)} variant="contained">
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </>
  );
};

export default SummaryDisplay;
