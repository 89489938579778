import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";
import { useField } from "formik";

const DropDown = ({ label, options, required, fullWidth, color, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl
      variant="outlined"
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      color={color}
      error={meta.touched && Boolean(meta.error)}
      sx={{ margin: 0, marginBottom: "1rem" }}
    >
      <InputLabel id={`${label.toLowerCase().replace(/ /g, "-")}-label`}>
        {label}
      </InputLabel>
      <Select
        {...field}
        {...props}
        labelId={`${label.toLowerCase().replace(/ /g, "-")}-label`}
        id={`${label.toLowerCase().replace(/ /g, "-")}`}
        label={label}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography
            variant="p"
            color="error"
            sx={{
              fontSize: "0.9rem",
              margin: 0,
              textAlign: "left",
              paddingLeft: 1.3,
              paddingTop: 0.5,
            }}
          >
            {meta.error}
          </Typography>
        </Box>
      ) : null}
    </FormControl>
  );
};

export default DropDown;
