import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../../../api/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import { decrementPriceInformation } from "../../../../redux/userSlice";
import { CircularProgressContainer } from "../../../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import {
  LinkedInProfileContainer,
  LinkedInProfileListContainer,
} from "./JobLinkedInNetworking.styles";
const JobLinkedInNetworking = ({
  uid,
  openConnectRecruiters,
  setOpenConnectRecruiters,
  infoData,
  selectedJob,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkedinProfileList, setLinkedInProfileList] = useState([]);
  const { userInformation } = useSelector((state) => state.user);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recruiterLinkedInJob = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(`/jobs/recruiterLinkedIn/${uid}`, {
        infoData,
        selectedJob,
      });
      setIsLoading(false);
      if (response.data.success) {
        setLinkedInProfileList(response.data.data);
        try {
          await api.post(`/credit/updateCredit/${uid}`, {
            decrementBy: 3,
          });
          dispatch(decrementPriceInformation(3));
        } catch (error) {
          showSnackbar(
            "Looks like something went wrong. Please try again later",
            "error"
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  useEffect(() => {
    if (
      openConnectRecruiters &&
      userInformation?.price?.actionsIncluded >= 5 &&
      linkedinProfileList.length === 0
    ) {
      recruiterLinkedInJob();
    }
  }, [openConnectRecruiters]);
  return (
    <Dialog
      open={openConnectRecruiters}
      onClose={() => {
        setLinkedInProfileList([]);
        setOpenConnectRecruiters(false);
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h6" component="div" style={{ flex: 1 }}>
          Network with recruiters from {selectedJob?.company_name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setLinkedInProfileList([]);
            setOpenConnectRecruiters(false);
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
            cursor: "pointer",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <LinkedInProfileListContainer>
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          </LinkedInProfileListContainer>
        ) : (
          <>
            {linkedinProfileList.length > 0 && (
              <>
                <LinkedInProfileContainer>
                  <LinkedInProfileListContainer>
                    {linkedinProfileList.map((profile, index) => (
                      <li>
                        <p>{profile.title}</p>{" "}
                        <a href={profile.link} target="_blank" rel="noreferrer">
                          {profile.link}
                        </a>
                      </li>
                    ))}
                  </LinkedInProfileListContainer>
                </LinkedInProfileContainer>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {userInformation?.price?.actionsIncluded <= 5 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/pricing")}
          >
            Upgrade
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            setLinkedInProfileList([]);
            setOpenConnectRecruiters(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default JobLinkedInNetworking;
