export const getEmailFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user).email : null;
};
export const getUIDFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user).uid : null;
};

export const getFirstNameFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? user?.firstName : null;
};
export const getUserNameFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user).firstName : null;
};
