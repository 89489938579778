import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../api/api";
import { removeChatLog } from "../../redux/landingPageSlice";
import {
  getFirstNameFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../utils/EmailUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import { aiSuggestions } from "./Home.constants";
import {
  AIActionContainer,
  AIActionsList,
  Container,
  HomeContainer,
} from "./Home.style";
import AIActions from "./components/AIActions/AIActions";
const Home = () => {
  useTrackPageViewDuration("Home Page");
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [openAIActions, setOpenAIActions] = useState(false);
  const [actionType, setActionType] = useState("");
  const firstName = getFirstNameFromLocalStorage();
  const uid = getUIDFromLocalStorage();
  const navigate = useNavigate();
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);

  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);

  useEffect(() => {
    dispatch(removeChatLog());
  }, []);
  const handleNewChat = async (message, type) => {
    try {
      const response = await api.post(`/chatting/startNewChat/${uid}`, {
        message: message,
      });
      if (response.data.success) {
        navigate(`/search/${response.data.data.thread_id}`, {
          state: { startMessage: message, actionType: type },
        });
      }
    } catch (error) {
      console.error("Error starting new chat:", error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNewChat(message, "general");
    }
  };
  return (
    <Container>
      <HomeContainer>
        <Typography variant="h4" gutterBottom>
          Welcome {firstName}!
        </Typography>
        <TextField
          label="Ask MILA anything..."
          variant="outlined"
          value={message}
          color="secondary"
          onKeyDown={handleKeyPress}
          onChange={(e) => setMessage(e.target.value)}
          sx={{
            width: "55%",
          }}
          InputProps={{
            endAdornment: (
              <>
                {pricingPlan?.price?.actionsIncluded <= 3 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<BsStars />}
                    onClick={() => navigate("/pricing")}
                  >
                    Upgrade
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={message === ""}
                      onClick={() => handleNewChat(message, "general")}
                    >
                      <AiOutlineSend style={{ fontSize: "22px" }} />
                    </Button>
                  </>
                )}
              </>
            ),
          }}
        />
        <AIActions
          actionType={actionType}
          open={openAIActions}
          setOpenAIActions={setOpenAIActions}
          uid={uid}
          setMessage={setMessage}
          handleNewChat={handleNewChat}
          chatType="newChat"
        />
        <AIActionContainer>
          <AIActionsList direction="row" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Try our ai actions:
            </Typography>
            {aiSuggestions.map((suggestion, index) => (
              <Button
                key={index}
                variant="text"
                startIcon={suggestion.icon}
                onClick={() => {
                  setOpenAIActions(true);
                  setActionType(suggestion.id);
                }}
                sx={{
                  textTransform: "none",
                  color: "primary.contrastText",
                  borderColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                  cursor: "pointer",
                }}
              >
                {suggestion.text}
              </Button>
            ))}
          </AIActionsList>
        </AIActionContainer>
      </HomeContainer>
    </Container>
  );
};

export default Home;
