import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { PiSignOut } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import ToastNotification from "../../commonComponents/ToastNotification/ToastNotification";
import { clearUserInformation } from "../../redux/userSlice";
import {
  getEmailFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../utils/EmailUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import { CircularProgressContainer } from "../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import {
  GET_SETTINGS_PROFILE_DATA,
  GET_SETTINGS_PROFILE_DATA_URL,
} from "./Settings.constants";
import {
  BodyContainer,
  Container,
  HeaderContainer,
  LeftHeaderContainer,
  SettingCard,
  SettingCardBody,
  SettingCardHeader,
  SettingItem,
} from "./Settings.style";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";
const Settings = () => {
  const email = getEmailFromLocalStorage();
  const dispatch = useDispatch();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const navigate = useNavigate();
  const uid = getUIDFromLocalStorage();
  useTrackPageViewDuration("Chatbot Menu Page");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const {
    data: profileData,
    loading: isProfileLoading,
    error: isProfileError,
  } = useApiQuery(GET_SETTINGS_PROFILE_DATA, {
    method: API_CONSTANTS.GET,
    url: `${GET_SETTINGS_PROFILE_DATA_URL}/${uid}`,
    enabled: uid !== null,
  });

  useEffect(() => {
    if (isProfileError) {
      showSnackbar(
        "We had some issue with your profile. Please try again.",
        "error"
      );
    }
  }, [isProfileError]);

  const handleLogout = () => {
    dispatch(clearUserInformation());
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      {isProfileLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Container>
          <DeleteAccount
            openDialogBox={openDialogBox}
            setOpenDialogBox={setOpenDialogBox}
          />
          <HeaderContainer>
            <LeftHeaderContainer>
              {/* <Typography variant="h4">Settings</Typography> */}
            </LeftHeaderContainer>
          </HeaderContainer>
          <BodyContainer>
            <SettingCard>
              <SettingCardHeader>
                <Typography variant="h5">Settings</Typography>
              </SettingCardHeader>
              <SettingCardBody>
                <SettingItem>
                  <Typography variant="subtitle1">Email</Typography>
                  <Typography variant="body1">{email}</Typography>
                </SettingItem>
                <SettingItem>
                  <Typography variant="subtitle1">
                    Subscription plan (
                    {profileData?.data?.pricingPlan?.planType})
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/pricing")}
                    color="secondary"
                    startIcon={<BsStars />}
                  >
                    {profileData?.data?.pricingPlan?.planType === "Voyager"
                      ? "Change plan"
                      : "Upgrade"}
                  </Button>
                </SettingItem>
                <SettingItem>
                  <Typography variant="subtitle1">Credits left</Typography>
                  <Typography variant="body1">
                    {profileData?.data?.pricingPlan?.actionsIncluded}
                  </Typography>
                </SettingItem>
                <SettingItem bottomBorder={"true"}>
                  <Typography variant="subtitle1">Account active</Typography>
                  <Button
                    variant="contained"
                    onClick={handleLogout}
                    startIcon={<PiSignOut />}
                  >
                    Sign out
                  </Button>
                </SettingItem>
                <SettingItem bottomBorder={"false"}>
                  <Typography variant="subtitle1">Delete account</Typography>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenDialogBox(true)}
                    startIcon={<MdOutlineDelete />}
                  >
                    Delete
                  </Button>
                </SettingItem>
              </SettingCardBody>
            </SettingCard>
          </BodyContainer>
          <ToastNotification
            open={openSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarSeverity={snackbarSeverity}
          />
        </Container>
      )}
    </>
  );
};

export default Settings;
