import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInformation: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserPriceInformation: (state, action) => {
      return {
        ...state,
        userInformation: {
          ...state.userInformation,
          price: action.payload,
        },
      };
    },
    decrementPriceInformation: (state, action) => {
      const newActionsIncluded =
        state.userInformation.price.actionsIncluded - action.payload;
      return {
        ...state,
        userInformation: {
          ...state.userInformation,
          price: {
            ...state.userInformation.price,
            actionsIncluded: newActionsIncluded >= 0 ? newActionsIncluded : 0,
          },
        },
      };
    },
    clearUserInformation: () => {
      return initialState;
    },
  },
});

export const {
  addUserPriceInformation,
  decrementPriceInformation,
  clearUserInformation,
} = userSlice.actions;

export default userSlice.reducer;
