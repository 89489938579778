import { BsStars } from "react-icons/bs";
import {
  FaEnvelopeOpenText,
  FaFileAlt,
  FaProjectDiagram,
} from "react-icons/fa";
import { MdWorkOutline } from "react-icons/md";
import { TbStack2 } from "react-icons/tb";
export const TopNavItems = [
  {
    key: "1",
    label: "MILA",
    link: "/home",
    icon: <BsStars style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "2",
    label: "Threads",
    link: "/threads",
    icon: <TbStack2 style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "3",
    label: "Resume",
    link: "/profile",
    icon: <FaFileAlt style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "4",
    label: "Cover letter",
    link: "/coverLetter",
    icon: <FaEnvelopeOpenText style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "5",
    label: "Jobs",
    link: "/jobs",
    icon: <MdWorkOutline style={{ marginRight: "0.1rem" }} />,
  },
  // {
  //   key: "6",
  //   label: "Projects",
  //   link: "/projects",
  //   icon: <FaProjectDiagram style={{ marginRight: "0.1rem" }} />,
  // },
  // {
  //   key: "6",
  //   label: "People",
  //   link: "/people",
  //   icon: <MdOutlinePeopleAlt style={{ marginRight: "0.1rem" }} />,
  // },
];

export const SideBarMenuItems = {
  home: 1,
  search: 1,
  threads: 2,
  profile: 3,
  coverLetter: 4,
  jobs: 5,
  projects: 6,
  settings: 9,
};

export const GET_INVITES_COUNT = "GET_INVITES_COUNT";
export const GET_INVITES_COUNT_URL = "/connections/pendingConnectionsCount";
