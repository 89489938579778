import styled from "styled-components";
import { Box } from "@mui/material";
export const Container = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.show ? "row" : "column")};
  width: 100%;
  height: 100vh;
`;

export const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  background-color: ${(props) => (props.show ? "white" : "transparent")};
  height: 100%;
  z-index: 20;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
  width: ${(props) => (props.show ? "240px" : "0px")};
  overflow: hidden;
  box-shadow: ${(props) =>
    props.show ? "4px 0 5px -2px rgba(0,0,0,0.2)" : "none"};
`;

export const RightContainer = styled.div`
  margin-left: ${(props) => (props.show ? "240px" : "60px")};
  height: 100%;
  background: linear-gradient(to bottom, #e9f3ff 60%, #ffffff 100%);
  overflow-y: scroll;
  transition: margin-left 0.3s ease-in-out;
  @media (max-width: 426px) {
    margin-left: 0px;
  }
`;

export const Image = styled.img`
  margin: 0.5rem 0.5rem 0rem 1.4rem;
  width: 60px;
  height: 45px;
  cursor: pointer;
`;

export const TopContainer = styled(Box)`
  position: fixed;
  margin-right: 1rem;
`;
export const ImageContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
`;
