export const GET_FOLLOWER_COUNT = "GET_FOLLOWER_COUNT";
export const GET_FOLLOWER_COUNT_URL = "/connections/followersCount";
export const GET_SLUG_WEBSITE_STATUS = "GET_SLUG_WEBSITE_STATUS";
export const GET_SLUG_WEBSITE_STATUS_URL =
  "/connections/slugFollowerCountAndStatus";
export const POST_FOLLOW_USER = "POST_FOLLOW_USER";
export const POST_FOLLOW_USER_URL = "/connections/sendConnectionRequest";
export const POST_HANDLE_CONNECTIONS = "POST_HANDLE_CONNECTIONS";
export const POST_HANDLE_CONNECTIONS_URL =
  "/connections/handleConnectionRequest";
