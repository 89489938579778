import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../../../api/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
const EditThreadName = ({
  openEditDialogBox,
  setOpenEditDialogBox,
  HandlingGettingChats,
  selectedChatId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [threadName, setThreadName] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const uid = getUIDFromLocalStorage();
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    const handlingSingleChat = async () => {
      setIsLoading(true);
      try {
        const response = await api.get(
          `/chatting/singleChat/${selectedChatId}/${uid}`
        );
        if (response.data.success) {
          setThreadName(response.data.data.thread_name);
        }
        setIsLoading(false);
      } catch (error) {
        showSnackbar(
          "Looks like something went wrong. Please try again later",
          "error"
        );
      }
    };
    if (selectedChatId) {
      handlingSingleChat();
    }
  }, [selectedChatId, uid]);
  const handleEdit = async (chatId) => {
    try {
      const response = await api.patch(
        `/chatting/update-chat-name/${selectedChatId}/${uid}`,
        {
          newThreadName: threadName,
        }
      );
      if (response.data.success) {
        setIsLoading(false);
        HandlingGettingChats();
        setOpenEditDialogBox(false);
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  return (
    <Dialog
      open={openEditDialogBox}
      onClose={() => setOpenEditDialogBox(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth= 'md'
      PaperProps={{
        sx: {
          width: '34%'
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="div" sx={{ fontSize: '1.5rem' }}>
          Edit thread name
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenEditDialogBox(false)}
          sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={10} sx={{ mb: 2, mt: 1 }}>
              <TextField
                color="secondary"
                label="Thread Name"
                type="text"
                required
                fullWidth
                value={threadName}
                onChange={(e) => setThreadName(e.target.value)}
                inputProps={{
                  maxLength: 255
                }}
                helperText={
                  threadName.length >= 255
                    ? "You have reached the 255 character limit"
                    : `${threadName.length}/255 characters used`
                }
                FormHelperTextProps={{
                  style: {
                    color: threadName.length >= 255 && 'red',  // Changes color to red when limit is reached
                    fontSize: '0.9rem'
                  }
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Divider sx={{ mb:1 }}/>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={handleEdit}
          disabled={threadName === "" || threadName.length >= 255}
        >
          Edit Name
        </Button>
        <Button
          onClick={() => setOpenEditDialogBox(false)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default EditThreadName;
