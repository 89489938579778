import styled from "styled-components";
export const Wrapper = styled.div`
  margin: 0rem 10rem;
`;
export const LargeHeader = styled.h1`
  font-size: 3.1rem;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  a{
    color:black
  }
`;

export const SmallHeader = styled.h2`
  font-size: 1.7rem;
`;
export const ListedContainer = styled.div`
  li {
    font-size: 1.1rem;
  }
`;
