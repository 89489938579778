import { MdDeleteOutline } from "react-icons/md";
import { GoPencil } from "react-icons/go";
export const menuItems = (handleDelete, handleOpenEditDialog) => {
  return [
    {
      id: 1,
      label: "Edit",
      icon: <GoPencil />,
      onClick: handleOpenEditDialog,
    },
    {
      id: 2,
      label: "Delete",
      icon: <MdDeleteOutline />,
      onClick: handleDelete,
    },
  ];
};

export const GET_THREADS = "GET_THREADS";
export const GET_THREADS_URL = "/chatting/getChatLogs";
