import axios from "axios";

let baseURL;
switch (process.env.REACT_APP_API_ENDPOINT) {
  case "staging":
    baseURL = "https://hrherostage-ecb203589f80.herokuapp.com/api/v1";
    break;
  case "production":
    baseURL = "https://hrheroprod-f239fb7b49f1.herokuapp.com/api/v1";
    break;
  default:
    baseURL = "http://localhost:3002/api/v1";
}

export default axios.create({
  baseURL,
});
