import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../../../api/api";
import { clearUserInformation } from "../../../../redux/userSlice";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import { deleteGoogleUser } from "../../../../utils/SSO";
const DeleteAccount = ({ openDialogBox, setOpenDialogBox }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uid = getUIDFromLocalStorage();
  const handleDeleteUsers = async () => {
    deleteGoogleUser();
    try {
      await api.delete(`/profile/delete-account/${uid}`);
      dispatch(clearUserInformation());
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };
  return (
    <Dialog
      open={openDialogBox}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          marginBottom: "1rem",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete your account?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Never mind
        </Button>
        <Button
          onClick={handleDeleteUsers}
          autoFocus
          variant="outlined"
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccount;
