import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  div:last-child {
    color: #6e59d5;
    padding: 0rem 0.1rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 0.8rem;
  }
`;
export const Image = styled.img`
  margin: 0.5rem 0.5rem 0.5rem 2rem;
  width: 60px;
  height: 45px;
  cursor: pointer;
`;
