import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getFirstNameFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../utils/EmailUtil";
import { OrgSideBarItems, TopNavItems } from "./OrgSidebar.constants";
import {
  BottomMenuContainer,
  Container,
  IconStyle,
  MenuContainer,
  MenuItems,
  MenuLabel,
  BottomMenuItems,
} from "./OrgSidebar.styles";
const OrgSidebar = ({ onLinkClick }) => {
  const uid = getUIDFromLocalStorage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(1);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const location = useLocation();
  const name = getFirstNameFromLocalStorage();
  const userInformation = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const path = location.pathname.split("/")[2];
    console.log(path);
    setSelectedKey(OrgSideBarItems[path]);
  }, [location]);
  const handleItemClick = (link, key) => {
    setSelectedKey(key);
    if (userInformation) {
      navigate(link);
    } else {
      setOpenSignInDialog(true);
    }
    if (window.innerWidth <= 768) {
      onLinkClick();
    }
  };

  const bottomItems = [
    {
      key: "4",
      label: "Switch to my profile",
      link: "/home",
    },
  ];
  return (
    <Container>
      <MenuContainer>
        {TopNavItems.map((item) => (
          <MenuItems
            onClick={() => handleItemClick(item.link, item.key)}
            isSelected={selectedKey == item.key}
            key={item.key}
          >
            <IconStyle>{item.icon}</IconStyle>
            <MenuLabel>{item.label}</MenuLabel>
          </MenuItems>
        ))}
      </MenuContainer>
      <BottomMenuContainer>
        {bottomItems.map((item) => (
          <div key={item.key}>
            <BottomMenuItems key={item.key}>
              <MenuLabel>
                <Button color="secondary" variant="outlined">
                  {item.label}
                </Button>
              </MenuLabel>
            </BottomMenuItems>
          </div>
        ))}
      </BottomMenuContainer>
    </Container>
  );
};

export default OrgSidebar;
