import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useEffect, useState } from "react";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { useNavigate } from "react-router";
import { CircularProgressContainer } from "../../pages/Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import {
  POST_PROJECTS,
  POST_PROJECTS_URL,
  DifficultyLevelOptions,
  dummyDataProject,
} from "./Projects.constant";
import {
  Container,
  FilterOptionContainer,
  HeaderContainer,
  ProjectContainer,
  SearchContainer,
  ProjectCardContainer,
  ProjectCard,
  ProjectCardHeader,
  ProjectCardName,
  ProjectCardDescription,
  ProjectCardButton,
  ButtonContainer,
} from "./Projects.styles";

const Projects = () => {
  const [projectList, setProjectList] = useState(dummyDataProject);
  const [filterOptions, setFilterOptions] = useState({
    projectName: "",
    role: "",
    difficultyLevel: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const onSettled = (data) => {
    setIsLoading(false);
  };
  const { mutate: projectData } = useApiQuery(POST_PROJECTS, {
    method: API_CONSTANTS.POST,
    url: POST_PROJECTS_URL,
    onSettled: onSettled,
    enabled: false,
  });

  useEffect(() => {
    projectData({ filterOptions });
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        </>
      ) : (
        <>
          <Container>
            <HeaderContainer>
              <Typography variant="h4">Find your next project</Typography>
            </HeaderContainer>
            <ProjectContainer>
              <FilterOptionContainer>
                <SearchContainer>
                  <TextField
                    label="Project name..."
                    id="outlined-size-small"
                    size="small"
                    value={filterOptions?.jobTitle}
                    color="secondary"
                    onChange={(e) =>
                      setFilterOptions((prevState) => {
                        return { ...prevState, jobTitle: e.target.value };
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiMagnifyingGlass />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Role..."
                    id="outlined-size-small"
                    size="small"
                    value={filterOptions?.jobTitle}
                    color="secondary"
                    onChange={(e) =>
                      setFilterOptions((prevState) => {
                        return { ...prevState, jobTitle: e.target.value };
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiMagnifyingGlass />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ width: "250px" }}
                  >
                    <InputLabel id="difficulty-label">
                      Difficulty level...
                    </InputLabel>
                    <Select
                      labelId="difficulty-label"
                      id="difficulty-select"
                      value={filterOptions?.difficultyLevel || ""}
                      onChange={(e) =>
                        setFilterOptions((prevState) => {
                          return {
                            ...prevState,
                            difficultyLevel: e.target.value,
                          };
                        })
                      }
                      label="Difficulty level..."
                      startAdornment={
                        <InputAdornment position="start">
                          <HiMagnifyingGlass />
                        </InputAdornment>
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {DifficultyLevelOptions.map((option) => (
                        <MenuItem key={option?.code} value={option?.code}>
                          {option?.optionValue}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<HiMagnifyingGlass />}
                    onClick={() => {
                      setIsLoading(true);
                      projectData({ filterOptions });
                    }}
                  >
                    Search
                  </Button>
                </SearchContainer>
              </FilterOptionContainer>
              <ButtonContainer>
                <Button variant="contained" color="secondary">
                  Ask MILA for a project
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/organizationSetup")}
                >
                  Create an organization
                </Button>
              </ButtonContainer>
              <ProjectCardContainer>
                {projectList?.length === 0 ? (
                  <>
                    <Typography variant="h5">No projects found</Typography>
                  </>
                ) : (
                  <>
                    {projectList?.map((project) => (
                      <ProjectCard key={project?.projectName}>
                        <ProjectCardHeader />
                        <ProjectCardName>
                          {project?.projectName.length > 30
                            ? `${project?.projectName.substring(0, 30)}...`
                            : project?.projectName}
                        </ProjectCardName>
                        <ProjectCardDescription>
                          {project?.createdBy}
                        </ProjectCardDescription>
                        <ProjectCardButton>
                          <Button variant="contained" color="primary">
                            View
                          </Button>
                        </ProjectCardButton>
                      </ProjectCard>
                    ))}
                  </>
                )}
              </ProjectCardContainer>
            </ProjectContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default Projects;
