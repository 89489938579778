import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UpdatedCreditsContainer, ActionHrefLink } from "../../Jobs.styles";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../api/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import { decrementPriceInformation } from "../../../../redux/userSlice";
import { CalculateTokenCost } from "../../../../utils/AICost";
import { EnvironmentSelection } from "../../../../utils/EnvUtil";
const JobFitCheck = ({
  uid,
  openFitCheck,
  setOpenFitCheck,
  selectedJob,
  profileComplete,
}) => {
  const [fitCheckDescription, setFitCheckDescription] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInformation } = useSelector((state) => state.user);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const environment = EnvironmentSelection();
  const dispatch = useDispatch();
  const addingSelectedJob = async () => {
    try {
      const response = await api.post("/jobs/addingSelectedJob", {
        uid: uid,
        jobData: selectedJob,
      });
      if (response.data.success) {
        fetchCoverLetter(response.data.data.jobId);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const fetchCoverLetter = async (jobId) => {
    if (window.eventSource) {
      window.eventSource.close();
    }
    setIsLoading(true);
    const url = `${environment}jobs/jobAiAction?jobId=${jobId}&uid=${uid}&type=fitCheck`;
    window.eventSource = new EventSource(url);
    let isStartOfNewMessage = true;
    window.eventSource.onmessage = async (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.messageType === "ONGOING") {
        setIsLoading(false);
        if (isStartOfNewMessage) {
          setFitCheckDescription((prev) => prev + eventData.data);
        }
      } else if (eventData.messageType === "DONE") {
        isStartOfNewMessage = false;
        const tokenCounts = {
          prompt_tokens: eventData.data.totalInputTokens,
          completion_tokens: eventData.data.totalOutputTokens,
        };
        const creditCount = CalculateTokenCost(tokenCounts, "GPT");
        try {
          await api.post(`/credit/updateCredit/${uid}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
        } catch (error) {
          showSnackbar(
            "Looks like something went wrong. Please try again later",
            "error"
          );
        }
      }
    };
    window.eventSource.onerror = () => {
      window.eventSource.close();
    };
  };
  useEffect(() => {
    if (
      openFitCheck &&
      fitCheckDescription === "" &&
      userInformation?.price?.actionsIncluded >= 5
    ) {
      addingSelectedJob();
    }
  }, [openFitCheck]);
  return (
    <Dialog
      open={openFitCheck}
      onClose={() => setOpenFitCheck(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          Fit Check for {selectedJob?.company_name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenFitCheck(false)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {profileComplete ? (
          <>
            {userInformation?.price?.actionsIncluded >= 5 ? (
              <form>
                <Grid container spacing={2} sx={{ marginTop: "0.1rem" }}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      name="content"
                      color="secondary"
                      label="Fit Check Description"
                      rows={18}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      value={fitCheckDescription}
                      sx={{
                        width: "100%",
                        marginBottom: "0.5rem",
                        backgroundColor: "white",
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : (
              <UpdatedCreditsContainer>
                <Typography variant="h6">
                  You have exhausted your free actions. Please buy more actions
                  to continue.
                  <ActionHrefLink href="/pricing">Upgrade here.</ActionHrefLink>
                </Typography>
              </UpdatedCreditsContainer>
            )}
          </>
        ) : (
          <>
            <UpdatedCreditsContainer>
              <Typography variant="h6">
                Oh, it appears your profile's a bit on the shy side, hiding some
                details. Completing it is like giving me a pair of spectacles –
                the clearer I can see, the better accurately I can give you a
                score! Shall we fill in the blanks together?
                <ActionHrefLink href="/profile/personal-profile">
                  Fill Profile
                </ActionHrefLink>
              </Typography>
            </UpdatedCreditsContainer>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpenFitCheck(false)}>
          Close
        </Button>
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default JobFitCheck;
