export const stripePayment = [
  {
    price_data: {
      currency: "usd",
      product_data: "Navigator",
      mode: "subscription",
      unit_amount: 1200,
      product: "prod_PLBWErKUvRBrnU",
      price_id: "price_1OWV9UFxsHG4ljwqsXu9TzBQ",
    },
  },
  {
    price_data: {
      currency: "usd",
      product_data: "Voyager",
      mode: "subscription",
      unit_amount: 1800,
      product: "prod_PLBYjG8e7TevUZ",
      price_id: "price_1OWVAyFxsHG4ljwqnqLBids5",
    },
  },
];

export const stripe_prod_payment = [
  {
    price_data: {
      currency: "usd",
      product_data: "Navigator",
      mode: "subscription",
      unit_amount: 1200,
      product: "prod_PLBWErKUvRBrnU",
      price_id: "price_1OR52IFxsHG4ljwq4B5j3pFa",
    },
  },
  {
    price_data: {
      currency: "usd",
      product_data: "Voyager",
      mode: "subscription",
      unit_amount: 1800,
      product: "prod_PLBYjG8e7TevUZ",
      price_id: "price_1OR555FxsHG4ljwq487rDViU",
    },
  },
];
