import { Button, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { FaRegClipboard, FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import api from "../../api/api";
import logo from "../../assets/images/chatbot.jpg";
import { MdOutlineSaveAlt } from "react-icons/md";
import { decrementPriceInformation } from "../../redux/userSlice";
import { CalculateTokenCost } from "../../utils/AICost";
import {
  getEmailFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../utils/EmailUtil";
import { EnvironmentSelection } from "../../utils/EnvUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import CoverLetterStorage from "./CoverLetterStorage";
import {
  ActionItems,
  AnimatedEllipsis,
  AssistantIcon,
  AssistantMessage,
  AssistantMessageContainer,
  AssistantMessageIcon,
  Container,
  Disclaimer,
  InputContainer,
  MessageContainer,
  UserMessage,
  UserMessageContainer,
  UserMessageIcon,
} from "./Home.style";
import MiniSideBar from "./components/MiniSideBar/MiniSideBar";

const Search = () => {
  const [chatLog, setChatLog] = useState([]);
  const [message, setMessage] = useState("");
  const [openCoverLetterStorage, setOpenCoverLetterStorage] = useState(false);
  const [coverLetterContent, setCoverLetterContent] = useState("");
  const [botThinking, setBotThinking] = useState(false);
  const location = useLocation();
  const { threadId } = useParams();
  const { startMessage, actionType } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messageEndRef = useRef(null);
  const uid = getUIDFromLocalStorage();
  const environment = EnvironmentSelection();
  const email = getEmailFromLocalStorage();
  const [intentType, setIntentType] = useState("");
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  useTrackPageViewDuration("Chatting with MILA");
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    const handlingGettingChatLog = async () => {
      try {
        setBotThinking(true);
        const response = await api.post(`/chatting/getMessages/${uid}`, {
          threadId: threadId,
        });
        if (response.data.success) {
          setBotThinking(false);
          setChatLog(response.data.chatEntry);
        }
      } catch (error) {
        setBotThinking(false);
      }
    };

    if (startMessage) {
      sendPrompt(startMessage, actionType);
      setIntentType(actionType);
    } else {
      handlingGettingChatLog();
    }
  }, [startMessage, actionType]);
  const sendPrompt = async (message, type) => {
    if (window.eventSource) {
      window.eventSource.close();
    }
    setIntentType(type);
    let currentThreadId = threadId;
    let newMessage = message;
    if (type !== "general") {
      if (type === "coverLetter") {
        newMessage =
          message + "Generate a cover letter for this job description";
      }
    }
    const newUser = {
      threadId: currentThreadId,
      uid: uid,
      content: newMessage,
      sender: "user",
    };
    setChatLog((prevState) => [...prevState, newUser]);
    setBotThinking(true);
    const url = `${environment}api/v1/chatting/sendMessage?prompt=${encodeURIComponent(
      message
    )}&uid=${encodeURIComponent(uid)}&threadId=${encodeURIComponent(
      currentThreadId
    )}&type=${type}`;
    window.eventSource = new EventSource(url);
    let isStartOfNewMessage = true;
    window.eventSource.onmessage = async (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.messageType === "ONGOING") {
        const newMessageContent = eventData.data.replace(/<br><br>/g, " ");
        if (isStartOfNewMessage) {
          setChatLog((prevState) => [
            ...prevState,
            {
              thread_id: threadId,
              user_id: uid,
              content: newMessageContent,
              sender: "assistant",
              actionType: type,
            },
          ]);
          isStartOfNewMessage = false;
        } else {
          setChatLog((prevState) => {
            const newState = [...prevState];
            const lastMessage = newState[newState.length - 1];
            if (lastMessage && lastMessage.sender === "assistant") {
              lastMessage.content += newMessageContent;
            }
            return newState;
          });
        }
      } else if (eventData.messageType === "DONE") {
        const tokenCounts = {
          prompt_tokens: eventData.data.totalInputTokens,
          completion_tokens: eventData.data.totalOutputTokens,
        };
        setBotThinking(false);
        const creditCount = CalculateTokenCost(tokenCounts, "GPT");
        try {
          await api.post(`/price/updateCredit/${email}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      }
    };
    window.eventSource.onerror = () => {
      setBotThinking(false);
      window.eventSource.close();
    };
    setMessage("");
  };
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      showSnackbar("Failed to copy to clipboard. Please try again", "error");
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendPrompt(message);
    }
  };
  const formatBotMessage = (text) => {
    const paragraphs = text.split("\n");
    return paragraphs.map((paragraph, index) => (
      <Typography
        key={index}
        variant="subtitle1"
        sx={{ marginTop: "1rem", fontSize: "18px" }}
      >
        {paragraph
          .replace(/\*\*(.*?)\*\*/g, "$1")
          .replace(/```(.*?)```/g, "$1")
          .replace(/###/g, "")}
      </Typography>
    ));
  };

  return (
    <Container>
      <MessageContainer>
        {chatLog.map((entry) => {
          if (entry.sender === "user") {
            return (
              <UserMessageContainer>
                <UserMessageIcon>
                  <FaRegUserCircle
                    style={{ fontSize: "20px", marginRight: "8px" }}
                  />
                  You
                </UserMessageIcon>
                <UserMessage sx={{}}>
                  <Typography
                    variant="subtitle1"
                    sx={{ marginTop: "1rem", fontSize: "18px" }}
                  >
                    {entry.content}
                  </Typography>
                </UserMessage>
              </UserMessageContainer>
            );
          } else if (entry.sender === "assistant") {
            return (
              <>
                <AssistantMessageContainer>
                  <AssistantMessageIcon>
                    <AssistantIcon src={logo} alt="hrhero" />
                    MILA
                  </AssistantMessageIcon>
                  <AssistantMessage>
                    {formatBotMessage(entry.content)}
                  </AssistantMessage>
                </AssistantMessageContainer>
                {!botThinking && (
                  <ActionItems>
                    <IconButton
                      style={{
                        fontSize: "1.2rem",
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      <FaRegClipboard
                        onClick={() => copyToClipboard(entry.content)}
                      />
                    </IconButton>
                    {entry.actionType === "coverLetter" && (
                      <IconButton
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        <MdOutlineSaveAlt
                          onClick={() => {
                            setCoverLetterContent(entry.content);
                            setOpenCoverLetterStorage(true);
                          }}
                        />
                      </IconButton>
                    )}
                  </ActionItems>
                )}
              </>
            );
          }
        })}
        <CoverLetterStorage
          openCoverLetterStorage={openCoverLetterStorage}
          setOpenCoverLetterStorage={setOpenCoverLetterStorage}
          coverLetterContent={coverLetterContent}
        />
        <div ref={messageEndRef}></div>
      </MessageContainer>
      {/* {!botThinking && <MiniSideBar sendPrompt={sendPrompt} />} */}
      {botThinking && (
        <div style={{ textAlign: "center", margin: "1rem" }}>
          Working on the answer
          <AnimatedEllipsis>...</AnimatedEllipsis>
        </div>
      )}
      <InputContainer>
        <TextField
          label="Ask follow up..."
          variant="outlined"
          color="secondary"
          value={message}
          onKeyDown={handleKeyPress}
          onChange={(e) => setMessage(e.target.value)}
          sx={{
            width: "80%",
          }}
          InputProps={{
            endAdornment: (
              <>
                {pricingPlan?.price?.actionsIncluded <= 3 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<BsStars />}
                    onClick={() => navigate("/pricing")}
                  >
                    Upgrade
                  </Button>
                ) : (
                  <>
                    {botThinking ? (
                      <AnimatedEllipsis>...</AnimatedEllipsis>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={message === ""}
                        style={{ justifyContent: "center" }}
                        onClick={() => sendPrompt(message, "general")}
                      >
                        <AiOutlineSend style={{ fontSize: "22px" }} />
                      </Button>
                    )}
                  </>
                )}
              </>
            ),
          }}
        />
        <Disclaimer>
          MILA can make mistakes. Consider checking important information.
        </Disclaimer>
      </InputContainer>
    </Container>
  );
};

export default Search;
