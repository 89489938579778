import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { getUIDFromLocalStorage } from "./EmailUtil";
import { EnvironmentSelectionForMixPanel } from "./EnvUtil";
const MIX_TOKEN_PROD = "8caa69ba9df29a7cb8587e4a848aee10";
const MIX_TOKEN_STAGE = "ad08ba00940c3fbdae7822befd63e7a0";

const env = EnvironmentSelectionForMixPanel();
const mixpanelToken = env === "production" ? MIX_TOKEN_PROD : MIX_TOKEN_STAGE;
const getOrCreateSessionId = () => {
  let sessionId = localStorage.getItem("sessionId");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("sessionId", sessionId);
  }
  return sessionId;
};
mixpanel.init(mixpanelToken);
export const useTrackPageViewDuration = (pageName) => {
  const userId =
    pageName === "Landing Page"
      ? getOrCreateSessionId()
      : getUIDFromLocalStorage();
  useEffect(() => {
    const entryTime = Date.now();

    const trackDuration = () => {
      const exitTime = Date.now();
      const durationInSeconds = (exitTime - entryTime) / 1000;

      mixpanel.track(pageName === "Landing Page" ? "Landing Page" : pageName, {
        distinct_id: userId.toString(),
        "Page Name": pageName,
        network: "Client Side",
        "Duration in Seconds": durationInSeconds,
      });
    };

    window.addEventListener("beforeunload", trackDuration);

    return () => {
      trackDuration(); // Track the duration when the component unmounts
      window.removeEventListener("beforeunload", trackDuration);
    };
  }, [pageName]);
};

export const trackingNewUserSignUp = (action) => {
  let sessionId = localStorage.getItem("sessionId");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("sessionId", sessionId);
  }
  console.log("sessionId", sessionId);
  if (action === "sign-up-button-clicked") {
    mixpanel.track("Sign-Up Button Clicked", {
      distinct_id: sessionId,
      "Page Name": "Landing Page",
    });
  } else {
    mixpanel.track("Sign-Up Completed", {
      distinct_id: sessionId,
      "Page Name": "Landing Page",
    });
  }
};
