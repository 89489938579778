import { CircularProgress } from "@mui/material";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import { GET_PROFILE, GET_PROFILE_URL } from "./Profile.constants";
import { CircularProgressContainer, Container } from "./Profile.styles";
import AboutMe from "./components/AboutMe/AboutMe";
import Certification from "./components/Certification/Certification";
import Intro from "./components/Intro/Intro";
import Project from "./components/Project/Project";
import SkillSet from "./components/SkillSet/SkillSet";
import CourseList from "./components/CourseList/CourseList";
import Languages from "./components/Languages/Languages";
import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Achievements from "./components/Achievements/Achievements";
const Profile = () => {
  const uid = getUIDFromLocalStorage();
  const {
    data: profileData,
    isLoading: isProfileDataLoading,
    isError: isProfileError,
    refetch,
  } = useApiQuery(GET_PROFILE, {
    method: API_CONSTANTS.GET,
    url: `${GET_PROFILE_URL}/${uid}`,
    enabled: uid !== null,
  });

  return (
    <>
      {isProfileDataLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Container>
          <Intro
            profileData={profileData?.data}
            refetch={refetch}
            type={"profile"}
          />
          <AboutMe
            profileData={profileData?.data}
            refetch={refetch}
            type={"Bio"}
          />
          <Experience profileInfo={profileData?.data} refetch={refetch} />
          <Education profileInfo={profileData?.data} refetch={refetch} />
          <Project profileInfo={profileData?.data} refetch={refetch} />
          <Achievements
            profileInfo={profileData?.data}
            refetch={refetch}
            type={"Achievements"}
          />
          <Languages profileInfo={profileData?.data} refetch={refetch} />
          <SkillSet profileInfo={profileData?.data} refetch={refetch} />
          <Certification profileInfo={profileData?.data} refetch={refetch} />
          <CourseList profileInfo={profileData?.data} refetch={refetch} />
        </Container>
      )}
    </>
  );
};

export default Profile;
