import { Box, Card } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 10px;
  margin-bottom: 1rem;
`;

export const InvitationContainer = styled(Card)`
  width: 80%;
  padding: 1rem;
  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const InvitationBodyContainer = styled(Box)``;
export const InvitationHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PeopleListContainer = styled(Card)`
  width: 80%;
  padding: 1rem;
  @media (max-width: 1024px) {
    width: 70%;
  }
`;

export const PeopleHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const PeopleBodyContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
`;

export const ProfileCardContainer = styled(Card)`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  img {
    margin-right: 0.5rem;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 1px solid white;
  }
`;
