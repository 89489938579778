import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BsLightningChargeFill } from "react-icons/bs";
import {
  FaLinkedin,
  FaPencil,
  FaSquareGithub,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { useNavigate } from "react-router";
import api from "../../../../api/api";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { stringToColor } from "../../../../utils/ChangeColor";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import { createPdf } from "../../../../utils/PdfCreation";
import { CardContainer, HeaderContainer } from "../../Profile.styles";
import {
  GET_FOLLOWER_COUNT,
  GET_FOLLOWER_COUNT_URL,
  GET_SLUG_WEBSITE_STATUS,
  GET_SLUG_WEBSITE_STATUS_URL,
  POST_FOLLOW_USER,
  POST_FOLLOW_USER_URL,
  POST_HANDLE_CONNECTIONS,
  POST_HANDLE_CONNECTIONS_URL,
} from "./Intro.constants";
import {
  Container,
  FollowerCount,
  FollowerCountContainer,
  ImageContainer,
  InteractiveContainer,
  SpaceContainer,
} from "./Intro.styles";
import HomeAbout from "./components/HomeAbout/HomeAbout";
const Intro = ({ profileData, refetch, type }) => {
  const uid = getUIDFromLocalStorage();
  const [openHomeAbout, setOpenHomeAbout] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [connectionStatusName, setConnectionStatusName] = useState("Follow");
  const [acceptButton, setAcceptButton] = useState(false);
  const navigate = useNavigate();
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const onFollowSettled = (data) => {
    if (!data.error) {
      setConnectionStatusName("pending");
    }
  };

  const onConnectionStatusSettled = (data) => {
    if (!data.error) {
      setAcceptButton(false);
      setConnectionStatusName(data?.data?.status);
    }
  };
  const { data: followerCount } = useApiQuery(GET_FOLLOWER_COUNT, {
    method: API_CONSTANTS.GET,
    url: `${GET_FOLLOWER_COUNT_URL}/${uid}`,
    enabled: uid !== null && type !== "slugWebsite",
  });
  const { data: slugWebsiteInfo, refetch: slugProfileRefetch } = useApiQuery(
    GET_SLUG_WEBSITE_STATUS,
    {
      method: API_CONSTANTS.GET,
      url: `${GET_SLUG_WEBSITE_STATUS_URL}/${uid}/${profileData?._id}`,
      enabled: type === "slugWebsite",
    }
  );
  const { mutate: handleFollowButton } = useApiQuery(POST_FOLLOW_USER, {
    method: API_CONSTANTS.POST,
    url: POST_FOLLOW_USER_URL,
    onSettled: onFollowSettled,
    enabled: false,
  });

  const { mutate: handleConnectionStatus } = useApiQuery(
    POST_HANDLE_CONNECTIONS,
    {
      method: API_CONSTANTS.POST,
      url: POST_HANDLE_CONNECTIONS_URL,
      onSettled: onConnectionStatusSettled,
      enabled: false,
    }
  );

  useEffect(() => {
    slugProfileRefetch();
  }, [profileData?._id]);

  useEffect(() => {
    if (slugWebsiteInfo) {
      if (slugWebsiteInfo.connectionExistStatus) {
        setAcceptButton(true);
      } else {
        setAcceptButton(false);
        setConnectionStatusName(slugWebsiteInfo.connectionStatus);
      }
    }
  }, [slugWebsiteInfo]);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };
  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await api.post(
        `/register-info/adding-resume/${uid}`,
        formData
      );
      if (response.data.success === true) {
        const queryText =
          response.data.message +
          "Optimize and provide me a resume that will pass ATS and also always provide me an analysis on it by giving it a score.";
        navigate(`/search/${response.data.thread.thread_id}`, {
          state: { startMessage: queryText, actionType: "general" },
        });
      }
    } catch (error) {
      showSnackbar(
        "We had some issue with your resume. Please try again.",
        "error"
      );
    }
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      await handleImageUpload(selectedFile);
    }
  };

  const handleImageUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("profileImage", selectedFile);
    try {
      const response = await api.post(
        `/profile/update-profile-image/${uid}`,
        formData
      );
      if (response.data.success === true) {
        showSnackbar("Profile image uploaded successfully", "success");
        refetch();
      }
    } catch (error) {
      showSnackbar(
        "We had some issue with your profile image. Please try again.",
        "error"
      );
    }
  };
  return (
    <Container>
      <SpaceContainer>
        <ImageContainer
          onClick={() => document.getElementById("upload-image").click()}
        >
          {profileData?.profileImage ? (
            <img
              src={profileData.profileImage}
              alt={`${profileData.title} thumbnail`}
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: stringToColor(profileData?.name),
                width: 96,
                height: 96,
                border: "1px solid #000",
              }}
            >
              {profileData?.name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
          <input
            type="file"
            accept="image/*"
            id="upload-image"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </ImageContainer>
        {/* <FollowerCountContainer>
          <FollowerCount>
            <Typography variant="body1" gutterBottom>
              {type === "slugWebsite" ? 0 : followerCount?.count} Followers
            </Typography>
          </FollowerCount>
        </FollowerCountContainer> */}
      </SpaceContainer>
      <CardContainer>
        <HeaderContainer>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {profileData?.firstName} {profileData?.lastName}
          </Typography>
          {uid && uid === profileData?._id && (
            <ToolTip label={"Edit"}>
              <IconButton
                size="small"
                onClick={() => setOpenHomeAbout(true)}
                sx={{
                  padding: 0,
                  marginBottom: "0.3rem",
                  color: "black",
                }}
              >
                <FaPencil style={{ fontSize: "1rem" }} />
              </IconButton>
            </ToolTip>
          )}
        </HeaderContainer>
        <Box>
          <Typography variant="h6" gutterBottom>
            {profileData?.primaryRole}
            {" • "}
            {profileData?.location}
          </Typography>
        </Box>
        <Box>
          {profileData?.socialMedia &&
            Object.values(profileData?.socialMedia).length > 0 && (
              <Box display="flex" flexDirection="row" gap="10px">
                {profileData?.socialMedia?.hrhero && (
                  <ToolTip label={"hrhero.ai"}>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginBottom: "0.3rem",
                        color: "black",
                      }}
                    >
                      <BsLightningChargeFill
                        onClick={() =>
                          window.open(
                            profileData?.socialMedia?.hrhero,
                            "_blank"
                          )
                        }
                        style={{ "font-size": "1.2rem" }}
                      />
                    </IconButton>
                  </ToolTip>
                )}
                {profileData?.socialMedia?.github && (
                  <ToolTip label={"GitHub"}>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginBottom: "0.3rem",
                        color: "black",
                      }}
                    >
                      <FaSquareGithub
                        onClick={() =>
                          window.open(
                            profileData?.socialMedia?.github,
                            "_blank"
                          )
                        }
                        style={{ "font-size": "1.2rem" }}
                      />
                    </IconButton>
                  </ToolTip>
                )}
                {profileData?.socialMedia?.linkedin && (
                  <ToolTip label={"LinkedIn"}>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginBottom: "0.3rem",
                        color: "black",
                      }}
                    >
                      <FaLinkedin
                        onClick={() =>
                          window.open(
                            profileData?.socialMedia?.linkedin,
                            "_blank"
                          )
                        }
                        style={{ "font-size": "1.2rem" }}
                      />
                    </IconButton>
                  </ToolTip>
                )}
                {profileData?.socialMedia?.twitter && (
                  <ToolTip label={"Twitter/X"}>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginBottom: "0.3rem",
                        color: "black",
                      }}
                    >
                      <FaSquareXTwitter
                        onClick={() =>
                          window.open(
                            profileData?.socialMedia?.twitter,
                            "_blank"
                          )
                        }
                        style={{ "font-size": "1.2rem" }}
                      />
                    </IconButton>
                  </ToolTip>
                )}
              </Box>
            )}
        </Box>
        {uid === profileData?._id ? (
          <InteractiveContainer>
            <input
              type="file"
              accept=".pdf"
              id="upload-resume"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById("upload-resume").click()}
            >
              Upload your resume
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => createPdf(profileData)}
            >
              Export as PDF
            </Button>
          </InteractiveContainer>
        ) : (
          <>
            {acceptButton ? (
              <InteractiveContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleConnectionStatus({
                      user1: uid,
                      user2: profileData?._id,
                      action: "accept",
                    })
                  }
                >
                  Accept
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    handleConnectionStatus({
                      user1: uid,
                      user2: profileData?._id,
                      action: "reject",
                    })
                  }
                >
                  Ignore
                </Button>
              </InteractiveContainer>
            ) : (
              <InteractiveContainer>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    connectionStatusName === "accepted" ||
                    connectionStatusName === "pending"
                      ? handleConnectionStatus({
                          user1: uid,
                          user2: profileData?._id,
                          action: "reject",
                        })
                      : handleFollowButton({
                          user1: uid,
                          user2: profileData?._id,
                        });
                  }}
                >
                  {connectionStatusName === "accepted"
                    ? "Unfollow"
                    : connectionStatusName === "pending"
                    ? "Pending"
                    : "Follow"}
                </Button>
              </InteractiveContainer>
            )}
          </>
        )}
        <HomeAbout
          open={openHomeAbout}
          setOpenHomeAbout={setOpenHomeAbout}
          profileInfo={profileData}
          refreshProfile={refetch}
        />
        <ToastNotification
          open={openSnackbar}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
        />
      </CardContainer>
    </Container>
  );
};

export default Intro;
