import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/chatbot.jpg";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import Google from "../../commonComponents/SideBar/components/Google/Google";
import ToastNotification from "../../commonComponents/ToastNotification/ToastNotification";
import { addingChatLogs, removeChatLog } from "../../redux/landingPageSlice";
import { EnvironmentSelection } from "../../utils/EnvUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import { CircularProgressContainer } from "../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import {
  LandingPagePrompts,
  POST_LANDING_PAGE_SETTING_MESSAGE,
  POST_LANDING_PAGE_SETTING_MESSAGE_URL,
  POST_RESUME_EXTRACTOR,
  POST_RESUME_EXTRACTOR_URL,
} from "./LandingPage.constants";
import {
  AnimatedEllipsis,
  AssistantIcon,
  AssistantMessage,
  AssistantMessageContainer,
  AssistantMessageIcon,
  CardContainer,
  Cards,
  Container,
  Disclaimer,
  InputContainer,
  MessageContainer,
  UserMessage,
  UserMessageContainer,
  UserMessageIcon,
} from "./LandingPage.styles";

const LandingPage = () => {
  useTrackPageViewDuration("Landing Page");
  const dispatch = useDispatch();
  const messageEndRef = useRef(null);
  const [message, setMessage] = useState("");
  const [botThinking, setBotThinking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [chatLog, setChatLog] = useState([]);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [botStartedResponding, setBotStartedResponding] = useState(false);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    dispatch(removeChatLog());
  }, []);

  const environment = EnvironmentSelection();
  const onSettled = (data) => {
    if (!data.error) {
      setJobId(data.data.data.jobId);
    }
  };

  const onResumeSettled = (data) => {
    if (!data.error) {
      setMessage(data.data.message);
      setJobId(data.data.jobId);
      sendPrompt(data.data.message, data.data.jobId);
    } else {
      showSnackbar("Error in uploading resume", "error");
    }
  };

  const { mutate: settingPostMessage } = useApiQuery(
    POST_LANDING_PAGE_SETTING_MESSAGE,
    {
      method: API_CONSTANTS.POST,
      url: POST_LANDING_PAGE_SETTING_MESSAGE_URL,
      enabled: false,
      onSettled: onSettled,
    }
  );

  const { mutate: settingResumeExtractor } = useApiQuery(
    POST_RESUME_EXTRACTOR,
    {
      method: API_CONSTANTS.POST,
      url: POST_RESUME_EXTRACTOR_URL,
      enabled: false,
      onSettled: onResumeSettled,
    }
  );

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    await settingResumeExtractor(formData);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const addingCardToChatLog = async (prompt) => {
    const assistantMessage = {
      content: prompt,
      sender: "assistant",
      actionType: "general",
    };
    dispatch(addingChatLogs(assistantMessage));
    setChatLog((prevState) => [...prevState, assistantMessage]);
    await settingPostMessage({ data: prompt });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && chatLog.length < 12) {
      e.preventDefault();
      sendPrompt(message, jobId);
    }
  };

  const sendPrompt = async (message, jobId) => {
    if (chatLog.length >= 12) {
      setOpenSignInDialog(true);
      return;
    }
    if (window.eventSource) {
      window.eventSource.close();
    }
    const newUser = {
      content: message,
      sender: "user",
      actionType: "general",
    };
    dispatch(addingChatLogs(newUser));
    setBotThinking(true);
    setBotStartedResponding(false);
    setChatLog((prevState) => [...prevState, newUser]);
    const url = `${environment}api/v1/landingPage/messageLandingPage?prompt=${encodeURIComponent(
      message
    )}&jobId=${jobId}&type=general`;
    window.eventSource = new EventSource(url);
    let isStartOfNewMessage = true;
    window.eventSource.onmessage = async (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.messageType === "ONGOING") {
        const newMessageContent = eventData.data.replace(/<br><br>/g, " ");
        if (isStartOfNewMessage) {
          setChatLog((prevState) => [
            ...prevState,
            {
              content: newMessageContent,
              sender: "assistant",
            },
          ]);

          setBotStartedResponding(true);
          isStartOfNewMessage = false;
        } else {
          let lastMessage;
          setChatLog((prevState) => {
            const newState = [...prevState];
            lastMessage = newState[newState.length - 1];
            if (lastMessage && lastMessage.sender === "assistant") {
              lastMessage.content += newMessageContent;
            }
            return newState;
          });
        }
      } else if (eventData.messageType === "DONE") {
        const newAssistantMessage = {
          content: eventData.data.message,
          sender: "assistant",
          actionType: "general",
        };
        dispatch(addingChatLogs(newAssistantMessage));
        setBotThinking(false);
        setJobId(eventData.data.id);
      }
    };
    window.eventSource.onerror = () => {
      setBotThinking(false);
      window.eventSource.close();
    };
    setMessage("");
  };

  const formatBotMessage = (text) => {
    const paragraphs = text.split("\n");
    return paragraphs.map((paragraph, index) => (
      <Typography
        key={index}
        variant="subtitle1"
        sx={{ marginTop: "1rem", fontSize: "18px" }}
      >
        {paragraph
          .replace(/\*\*(.*?)\*\*/g, "$1")
          .replace(/```(.*?)```/g, "$1")
          .replace(/###/g, "")}
      </Typography>
    ));
  };

  return (
    <>
      {isLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Container>
          <MessageContainer isEmpty={chatLog.length === 0}>
            {chatLog.length === 0 ? (
              <>
                <CardContainer>
                  <Typography variant="h5" sx={{ marginBottom: "0.5rem" }}>
                    Ask MILA anything
                  </Typography>
                  <Cards>
                    {LandingPagePrompts.map((card, index) => {
                      const functionCall =
                        index === 1
                          ? () =>
                              document.getElementById("upload-resume").click()
                          : () => addingCardToChatLog(card.prompt);
                      return (
                        <Card
                          sx={{
                            minWidth: 300,
                            maxHeight: 250,
                            display: "flex",
                            flexDirection: "column",
                            padding: "0rem",
                            "@media (max-width: 1024px)": {
                              minWidth: 250,
                            },
                            "@media (max-width: 769px)": {
                              minWidth: 175,
                              maxHeight: 130, 
                            }
                          }}
                          onClick={functionCall}
                        >
                          <CardActionArea
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="135"
                              image={card.iconLink}
                              alt="card image"
                              sx={{
                                "@media (max-width: 769px)": {
                                  height: 90,
                                }
                              }}
                            />
                            <CardContent sx={{ flexGrow: 1,}}>
                              <Typography
                                variant="h5"
                                sx={{ height: "100%", textAlign: "center",
                                "@media (max-width: 1024px)": {
                                  fontSize: "1.25rem", 
                                },
                                "@media (max-width: 769px)": {
                                  fontSize: "0.75rem", 
                                }
                                 }}
                              >
                                {card.promptTitle}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      );
                    })}
                  </Cards>
                </CardContainer>
              </>
            ) : (
              <>
                {chatLog.map((entry, index) => {
                  if (entry.sender === "user") {
                    return (
                      <UserMessageContainer key={index}>
                        <UserMessageIcon>
                          <FaRegUserCircle
                            style={{ fontSize: "18px", marginRight: "8px" }}
                          />
                          You
                        </UserMessageIcon>
                        <UserMessage>
                          <Typography
                            variant="subtitle1"
                            sx={{ marginTop: "1rem", fontSize: "20px" }}
                          >
                            {entry.content}
                          </Typography>
                        </UserMessage>
                      </UserMessageContainer>
                    );
                  } else if (entry.sender === "assistant") {
                    return (
                      <AssistantMessageContainer key={index}>
                        <AssistantMessageIcon>
                          <AssistantIcon src={logo} alt="hrhero" />
                          MILA
                        </AssistantMessageIcon>
                        <AssistantMessage>
                          {botThinking &&
                          !botStartedResponding &&
                          index === chatLog.length - 1 ? (
                            <Typography
                              variant="subtitle1"
                              sx={{ fontSize: "20px" }}
                            >
                              Working on answer...
                            </Typography>
                          ) : (
                            formatBotMessage(entry.content)
                          )}
                        </AssistantMessage>
                      </AssistantMessageContainer>
                    );
                  }
                })}
              </>
            )}
            <div ref={messageEndRef}></div>
          </MessageContainer>
          <input
            type="file"
            accept=".pdf"
            id="upload-resume"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <InputContainer>
            <TextField
              label="Ask MILA anything...."
              variant="outlined"
              color="secondary"
              rows={20}
              value={message}
              onKeyDown={handleKeyPress}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                width: "80%",
                fontSize: "20px",
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {botThinking ? (
                      <AnimatedEllipsis>...</AnimatedEllipsis>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={message === ""}
                        style={{ justifyContent: "center" }}
                        onClick={() => sendPrompt(message, jobId)}
                      >
                        <AiOutlineSend style={{ fontSize: "22px" }} />
                      </Button>
                    )}
                  </>
                ),
              }}
            />
            <Disclaimer>
              MILA can make mistakes. Consider checking important information.
            </Disclaimer>
          </InputContainer>
          <Google
            openSignInDialog={openSignInDialog}
            setOpenSignInDialog={setOpenSignInDialog}
            type="landingPage"
            setIsLoading={setIsLoading}
          />
          <ToastNotification
            open={openSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarSeverity={snackbarSeverity}
          />
        </Container>
      )}
    </>
  );
};

export default LandingPage;
