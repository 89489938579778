import { Box, Card, Typography } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const HeaderContainer = styled(Card)``;

export const BenefitsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
export const CardContainer = styled(Box)`
  width: 95%;
`;
export const CardHeader = styled(Typography)``;

export const JobDescriptionContainer = styled(Card)`
  margin-bottom: 1rem;
`;

export const CardListContainer = styled.ul`
  width: 100%;
`;

export const AIActionButtonsContainer = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ListItem = styled.li`
  padding-bottom: 5px;
`;

export const ExtensionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
