import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../../../../api/api";
import { monthNames } from "../../../../../assets/ProfileConfigs/profileConfigs";
import LoadingContainer from "../../../../../commonComponents/LoadingContainer/LoadingContainer";
import ToastNotification from "../../../../../commonComponents/ToastNotification/ToastNotification";
import { decrementPriceInformation } from "../../../../../redux/userSlice";
import { CalculateTokenCost } from "../../../../../utils/AICost";
import {
  getEmailFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../../../../utils/EmailUtil";
const ExperienceDisplay = ({
  openExperience,
  OnClose,
  refreshProfile,
  infoId,
  type,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    company: "",
    location: "",
    fromMonth: "",
    fromYear: "",
    toMonth: "",
    toYear: "",
    current: false,
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { userInformation } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const uid = getUIDFromLocalStorage();
  const email = getEmailFromLocalStorage();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);

  const isFormValid = () => {
    const {
      title,
      company,
      location,
      fromMonth,
      fromYear,
      toMonth,
      toYear,
      current,
      description,
    } = formData;
    const isStartDateSelected = fromMonth && fromYear;
    const isEndDateSelected = toMonth && toYear;
    return (
      title &&
      company &&
      location &&
      description &&
      isStartDateSelected &&
      (isEndDateSelected || current)
    );
  };
  useEffect(() => {
    const HandleGetExperience = async () => {
      try {
        const response = await api.get(
          `/profile/experience-education/${infoId}/${uid}/${type}`
        );
        if (response.data.success) {
          setFormData(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (infoId) {
      HandleGetExperience();
    } else {
      setFormData({
        title: "",
        company: "",
        location: "",
        fromMonth: "",
        fromYear: "",
        toMonth: "",
        toYear: "",
        current: false,
        description: "",
      });
    }
  }, [infoId]);
  const handleCurrentChange = () => {
    setFormData((prev) => ({
      ...prev,
      current: !prev.current,
      to: !prev.current ? "" : prev.to,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (infoId) {
      try {
        const response = await api.put(
          `/profile/update-experience-education/${uid}`,
          {
            data: {
              formData,
              infoId,
              type,
            },
          }
        );
        if (response.data.success) {
          refreshProfile();
          OnClose();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await api.post(
          `/profile/add-experience/${uid}`,
          formData
        );
        if (response.data.success) {
          refreshProfile();
          OnClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleGenerateClick = async () => {
    try {
      setIsLoading(true);
      let endpoint = "/ai/enhanceExp";
      let payload = {
        text: formData.description,
        title: formData.title,
        company: formData.company,
        current: formData.current,
      };

      const response = await api.post(`${endpoint}/${email}`, payload);

      if (response.data.success) {
        pollForResult(response.data.jobId);
      } else {
        showSnackbar(response.data.message, "error");
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const pollForResult = async (jobId) => {
    try {
      const response = await api.get(`/ai/polling-result/${jobId}`);
      if (response.data.status === "done") {
        const creditCount = CalculateTokenCost(
          response.data.usage,
          response.data.modelName
        );
        setFormData((prev) => ({ ...prev, description: response.data.data }));
        setIsLoading(false);
        try {
          await api.post(`/price/updateCredit/${email}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      } else {
        setTimeout(() => pollForResult(jobId), 5000);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  return (
    <>
      <Dialog
        open={openExperience}
        onClose={OnClose}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "80vh",
            backgroundColor: "#fbfbfb",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Add/Edit Work Experience
          </Typography>
          <IconButton
            aria-label="close"
            onClick={OnClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <>
            <LoadingContainer number={1000} />
          </>
        ) : (
          <DialogContent dividers>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    name="title"
                    label="Title"
                    type="text"
                    required
                    value={formData?.title}
                    onChange={handleChange}
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    name="company"
                    label="Company"
                    type="text"
                    required
                    value={formData?.company}
                    onChange={handleChange}
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    name="location"
                    label="Location"
                    type="text"
                    required
                    value={formData?.location}
                    onChange={handleChange}
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    color="secondary"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  >
                    <InputLabel id="fromMonth-label">Start Month</InputLabel>
                    <Select
                      labelId="fromMonth-label"
                      name="fromMonth"
                      value={formData?.fromMonth}
                      onChange={handleChange}
                      label="Start Month"
                      required
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Month</em>
                      </MenuItem>
                      {monthNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    color="secondary"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  >
                    <InputLabel id="fromYear-label">Start Year</InputLabel>
                    <Select
                      labelId="fromYear-label"
                      name="fromYear"
                      value={formData?.fromYear}
                      onChange={handleChange}
                      label="Start Year"
                      required
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select Year</em>
                      </MenuItem>
                      {years.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    color="secondary"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  >
                    <InputLabel>End Month</InputLabel>
                    <Select
                      name="toMonth"
                      value={formData?.toMonth}
                      disabled={formData?.current}
                      label="End Month"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select Month</em>
                      </MenuItem>
                      {monthNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    color="secondary"
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  >
                    <InputLabel>End Year</InputLabel>
                    <Select
                      name="toYear"
                      value={formData?.toYear}
                      disabled={formData?.current}
                      label="End Year"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Select Year</em>
                      </MenuItem>
                      {years.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData?.current || false}
                        name="current"
                        color="secondary"
                        onChange={handleCurrentChange}
                      />
                    }
                    label="I am currently working here"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    name="description"
                    color="secondary"
                    label="Job Experience"
                    rows={8}
                    placeholder="Detail your roles, key responsibilities, and achievements. Don't just list tasks—highlight your contributions and how they made a difference. This is your opportunity to showcase your career journey."
                    value={formData?.description}
                    required
                    sx={{
                      width: "100%",
                      marginBottom: "0.5rem",
                      backgroundColor: "white",
                    }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  {pricingPlan?.price?.actionsIncluded <= 5 ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/pricing")}
                    >
                      Upgrade
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ mx: 1 }}
                      onClick={handleGenerateClick}
                    >
                      Optimize
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        )}
        {!isLoading && (
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              Save
            </Button>
            <Button onClick={OnClose} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </>
  );
};

export default ExperienceDisplay;
