import { Box, Card, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 90vh;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
`;

export const LeftHeaderContainer = styled(Box)`
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: row;
`;

export const RightHeaderContainer = styled(Box)`
  margin-bottom: 1rem;
`;
export const ThreadCollectionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;
export const ThreadContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: flex-start;
  @media (max-width: 1440px) {
    width: 75%;
  }
`;
export const ThreadCollectionHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
`;
export const ThreadCollectionHeader = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const ThreadCollectionIconContainer = styled(Box)`
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconButtonContainer = styled(IconButton)`
  && {
    color: black;
    border: 1px solid grey;
    border-radius: 50%;
    cursor: "pointer";
    padding: 0.66rem;
  }
`;

export const FilteredThreadContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1.5rem;
  border-bottom: ${(props) =>
    props.bottomBorder === "false" ? "none" : "1px solid #e0e0e0"};
  width: 100%;
`;

export const FilteredListContainer = styled(Box)`
  width: 100%;
  padding-bottom: 0.5rem;
  &:hover {
    color: #0154b1;
  }
`;

export const ListOptionContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const ListOptionLeftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 50%;
`;
export const ListOptionRightContainer = styled(Box)`
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const NoThreadCollectionContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f5f5f5;
  margin-top: 1rem;
`;

export const NoThreadMessages = styled(Typography)`
  && {
    color: #868680;
    text-align: center;
    margin-bottom: 0.5rem;
  }
`;

export const CollectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
`;
