import React, { useEffect, useState } from "react";
import AICoverLetter from "../AICoverLetter/AICoverLetter";
import FitCheck from "../FitCheck/FitCheck";
import LinkedInNetworkAssistant from "../LinkedinNetworkAssistant/LinkedInNetworkAssistant";
import InterviewPreparation from "../InterviewPreparation/InterviewPreparation";
import api from "../../../../api/api";
const AIActions = ({
  actionType,
  open,
  setOpenAIActions,
  uid,
  setMessage,
  handleNewChat,
  sendPrompt,
  chatType,
}) => {
  const [profileIncomplete, setProfileIncomplete] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const HandlingProfileData = async () => {
      try {
        const response = await api.get(`/profile/${uid}`);
        if (response.data.success) {
          const userData = response.data.data;
          setUserDetails({
            location: userData?.location,
            primaryRole: userData?.primaryRole,
          });
          if (
            userData.experiences.length === 0 ||
            userData.educations.length === 0
          ) {
            setProfileIncomplete(true);
          } else {
            setProfileIncomplete(false);
          }
        } else {
          console.log("Error fetching profile data");
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    HandlingProfileData();
  }, []);
  switch (actionType) {
    case "coverLetter":
      return (
        <AICoverLetter
          open={open}
          setOpenAIActions={setOpenAIActions}
          profileIncomplete={profileIncomplete}
          setMessage={setMessage}
          handleNewChat={handleNewChat}
          sendPrompt={sendPrompt}
          chatType={chatType}
        />
      );
    case "fitCheck":
      return (
        <FitCheck
          open={open}
          setOpenAIActions={setOpenAIActions}
          profileIncomplete={profileIncomplete}
          setMessage={setMessage}
          handleNewChat={handleNewChat}
          sendPrompt={sendPrompt}
          chatType={chatType}
        />
      );
    case "interviewPreparation":
      return (
        <InterviewPreparation
          open={open}
          setOpenAIActions={setOpenAIActions}
          profileIncomplete={profileIncomplete}
          setMessage={setMessage}
          handleNewChat={handleNewChat}
          sendPrompt={sendPrompt}
          chatType={chatType}
        />
      );
    case "linkedinNetworkAssistant":
      return (
        <LinkedInNetworkAssistant
          open={open}
          setOpenAIActions={setOpenAIActions}
          profileIncomplete={profileIncomplete}
          setMessage={setMessage}
          handleNewChat={handleNewChat}
          userDetails={userDetails}
          uid={uid}
        />
      );
    default:
      return null; // Render nothing if actionType doesn't match any case
  }
};

export default AIActions;
