import {
  LargeHeader,
  SmallHeader,
  Text,
  Wrapper,
} from "../PrivacyPolicy/PrivacyPolicy.styles";
const TermsOfService = () => {
  return (
    <div>
      <Wrapper>
        <LargeHeader>Terms of Service</LargeHeader>
        <Text>
          <i>Effective Date: November 27, 2023</i>
        </Text>
        <SmallHeader>1. Introduction</SmallHeader>
        <Text>
          Welcome to hrhero.ai, an AI-powered career development and networking
          platform. Our services include career guidance, resume building,
          interview preparation, and showcasing creator statistics across
          various social media platforms. These Terms of Service govern your use
          of our platform.
        </Text>
        <SmallHeader>2. Acceptance of Terms</SmallHeader>
        <Text>
          By accessing or using our services, you agree to be bound by these
          Terms of Service and our Privacy Policy.
        </Text>
        <SmallHeader>3. Eligibility</SmallHeader>
        <Text>
          You must be at least 18 years of age, or the age of majority in your
          jurisdiction, to use our services. By using hrhero.ai, you represent
          and warrant that you meet this age requirement.
        </Text>
        <SmallHeader>4. Privacy Policy</SmallHeader>
        <Text>
          Our Privacy Policy, which details how we handle your data, can be
          found at{" "}
          <a href="https://hrhero.ai/privacy-policy.">
            https://hrhero.ai/privacy-policy.
          </a>
        </Text>
        <SmallHeader>5. User Responsibilities</SmallHeader>
        <Text>
          Users are expected to use hrhero.ai responsibly, complying with all
          applicable laws and regulations. Unauthorized use of the service,
          including but not limited to misuse of information or services, is
          strictly prohibited.
        </Text>
        <SmallHeader>6. Content and Intellectual Property Rights</SmallHeader>
        <Text>
          You retain ownership of the content you create or share on our
          platform. By posting content, you grant us a license to use, modify,
          and display this content in connection with our services.
        </Text>
        <SmallHeader>7. API and Third-Party Integrations</SmallHeader>
        <Text>
          Our service integrates with APIs and services from third-party social
          media platforms. Your use of these integrations is subject to the
          terms and conditions of the respective third-party platforms.
        </Text>
        <SmallHeader>8. Service Modifications</SmallHeader>
        <Text>
          We reserve the right to modify, suspend, or discontinue our services
          at any time, without liability to you or any third party.
        </Text>
        <SmallHeader>9. Disclaimer of Warranties</SmallHeader>
        <Text>
          hrhero.ai is provided "as is" without warranties of any kind, either
          express or implied. We do not guarantee the accuracy, reliability, or
          completeness of our service.
        </Text>
        <SmallHeader>10. Limitation of Liability</SmallHeader>
        <Text>
          Our liability to you for any claims arising from your use of hrhero.ai
          is limited to the extent permitted by law.
        </Text>
        <SmallHeader>11. Indemnification</SmallHeader>
        <Text>
          You agree to defend, indemnify, and hold harmless hrhero.ai and its
          affiliates from any claims, damages, obligations, losses, liabilities,
          costs, and expenses arising from your use of our services.
        </Text>
        <SmallHeader>12. Termination</SmallHeader>
        <Text>
          We may terminate or suspend your access to our services for breach of
          these Terms, without prior notice or liability.
        </Text>
        <SmallHeader>13. Governing Law</SmallHeader>
        <Text>
          These Terms shall be governed by the laws of Delaware, United States
          without regard to its conflict of law principles.
        </Text>
        <SmallHeader>14. Amendments to Terms</SmallHeader>
        <Text>
          We reserve the right to amend these Terms at any time and will provide
          notice of any significant changes.
        </Text>
        <SmallHeader>15. Contact Information</SmallHeader>
        <Text>
          For questions about these Terms, please contact us at
          support@hrhero.ai
        </Text>
      </Wrapper>
    </div>
  );
};

export default TermsOfService;
