import { formatDistanceToNow } from "date-fns";
export const extractModelName = (path) => {
  return path.split("/")[2];
};
export const getTimeElapsed = (updatedAt) => {
  const date = new Date(updatedAt);
  const distance = formatDistanceToNow(date, { addSuffix: false });

  return distance;
};
