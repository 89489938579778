import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import {
  calculateDuration,
  processDescription,
} from "../../../../utils/ProfileUtil";
import {
  CardContainer,
  DescriptionContainer,
  HeaderContainer,
} from "../../Profile.styles";
import {
  DELETE_PROJECT_BY_ID,
  DELETE_PROJECT_URL,
} from "../../Profile.constants";
import ProjectDisplay from "./component/ProjectDisplay";
const Project = ({ profileInfo, refetch }) => {
  const uid = getUIDFromLocalStorage();
  const [openProject, setOpenProject] = useState(false);
  const [infoId, setInfoId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const onSettled = (data) => {
    if (data.data.success) {
      refetch();
      showSnackbar("Successfully updated your profile", "success");
    } else {
      showSnackbar("Failed to update your profile", "error");
    }
  };
  const { mutate: deleteProjects } = useApiQuery(DELETE_PROJECT_BY_ID, {
    method: API_CONSTANTS.DELETE,
    url: `${DELETE_PROJECT_URL}${uid}`,
    enabled: false,
    onSettled: onSettled,
  });
  return (
    <CardContainer>
      <HeaderContainer>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Projects
        </Typography>
        {uid && uid === profileInfo?._id && (
          <ToolTip label={"Add & Edit with AI"}>
            <IconButton
              size="small"
              onClick={() => setOpenProject(true)}
              sx={{
                padding: 0,
                marginBottom: "0.3rem",
                color: "black",
              }}
            >
              <FaPencil style={{ fontSize: "1rem" }} />
            </IconButton>
          </ToolTip>
        )}
      </HeaderContainer>
      {profileInfo?.projects?.length > 0 && (
        <div>
          {profileInfo?.projects?.map((project, index) => {
            return (
              <Box sx={{ marginBottom: "1.1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    fontWeight="bold"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {project?.projectName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {(project.fromYear && project.fromMonth) ||
                    (project.toYear && project.toMonth) ? (
                      <Typography variant="subtitle1">
                        {calculateDuration(
                          project.fromMonth,
                          project.fromYear,
                          project.toMonth,
                          project.toYear,
                          project.current
                        )}
                      </Typography>
                    ) : null}
                    {uid && uid === profileInfo?._id && (
                      <>
                        <ToolTip label={"Edit with AI"}>
                          <IconButton
                            onClick={() => {
                              setInfoId(project._id);
                              setOpenProject(true);
                            }}
                            size="small"
                            sx={{
                              padding: 0,
                              marginBottom: "0.1rem",
                              marginLeft: "0.3rem",
                            }}
                          >
                            <FaPencil style={{ fontSize: "1rem" }} />
                          </IconButton>
                        </ToolTip>
                        <ToolTip label={"Delete"}>
                          <IconButton
                            onClick={() =>
                              deleteProjects({
                                data: { id: project._id },
                              })
                            }
                            size="small"
                            sx={{
                              padding: 0,
                              marginBottom: "0.1rem",
                              marginLeft: "0.3rem",
                            }}
                          >
                            <FaRegTrashCan
                              style={{ fontSize: "1rem", color: "red" }}
                            />
                          </IconButton>
                        </ToolTip>
                      </>
                    )}
                  </Box>
                </Box>
                <DescriptionContainer>
                  {processDescription(project)}
                </DescriptionContainer>
              </Box>
            );
          })}
        </div>
      )}
      <ProjectDisplay
        openProject={openProject}
        OnClose={() => setOpenProject(false)}
        refreshProfile={refetch}
        infoId={infoId}
      />
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </CardContainer>
  );
};

export default Project;
