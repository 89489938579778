import { Box, Card } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LeftHeaderContainer = styled(Box)`
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: row;
`;

export const BodyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`;

export const SettingCard = styled(Card)`
  margin-top: 1rem;
  width: 50%;
`;

export const SettingCardHeader = styled(Box)`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;
export const SettingCardBody = styled(Box)`
  width: 100%;
`;

export const SettingItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem 1rem;
  border-bottom: ${(props) =>
    props.bottomBorder === "false" ? "none" : "1px solid #e0e0e0"};
`;
