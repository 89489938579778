import { FaUserTie } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import { HiPencilSquare } from "react-icons/hi2";
import { IoPeopleOutline } from "react-icons/io5";

export const aiSuggestions = [
  {
    text: "Generate Cover Letter",
    icon: <HiPencilSquare />,
    id: "coverLetter",
  },
  { text: "FitCheck", icon: <FcCheckmark />, id: "fitCheck" },
  {
    text: "LinkedIn Networking Assistant",
    icon: <IoPeopleOutline />,
    id: "linkedinNetworkAssistant",
  },
  {
    text: "Interview Preparation",
    icon: <FaUserTie />,
    id: "interviewPreparation",
  },
];
