import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const MenuContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-top: 1.5rem;
`;

export const MenuItems = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1.9rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  z-index: 1;
  gap: 0.5rem;
  background-color: ${({ isSelected }) =>
    isSelected ? "#fed33c" : "transparent"};
  &:hover {
    background-color: #fed33c;
  }
`;

export const MenuLabel = styled(Box)`
  font-size: 1rem;
`;

export const IconStyle = styled(Box)`
  font-size: 22px;
`;

export const BottomMenuContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
export const StyledIconStyle = styled(IconStyle)`
  margin-right: 10px;
`;

export const BottomMenuItems = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1.9rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  z-index: 1;
  gap: 0.5rem;
`;
