import { Box, Typography } from "@mui/material";
import {
  Line,
  LineBullet,
  LineContainer,
} from "../pages/SlugWebsite/SlugWebsite.styles";
export const processDescription = (education, type) => {
  const elements = [];
  if (type === "experience") {
    elements.push(
      <Box sx={{ display: "flex" }}>
        <Typography variant="body1" fontWeight={"bold"} gutterBottom>
          {education?.title}
        </Typography>
      </Box>
    );
    elements.push(
      <Box sx={{ display: "flex", gap: "10px" }}>
        {education?.location && (
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            gutterBottom
            color="text.secondary"
          >
            {education?.location},
          </Typography>
        )}

        <Typography variant="subtitle2" color="text.secondary">
          {`${education.fromMonth} ${education.fromYear}`}
          {education.current
            ? " - Present"
            : ` - ${education.toMonth} ${education.toYear}`}
        </Typography>
      </Box>
    );
  } else if (type === "education") {
    elements.push(
      <Typography variant="body1" fontWeight={"bold"} gutterBottom>
        {education?.degree} in {education?.fieldOfStudy}
      </Typography>
    );
    elements.push(
      <Typography variant="subtitle2" color="text.secondary">
        {`${education.fromMonth} ${education.fromYear}`}
        {education.current
          ? " - Present"
          : ` - ${education.toMonth} ${education.toYear}`}
      </Typography>
    );
  } else {
    elements.push(
      <Typography variant="subtitle2" color="text.secondary">
        {`${education.fromMonth} ${education.fromYear}`}
        {education.current
          ? " - Present"
          : ` - ${education.toMonth} ${education.toYear}`}
      </Typography>
    );
  }

  if (!education?.description) return elements;
  const lines = education?.description.split("\n");

  lines.forEach((line, index) => {
    const processedLine = line.replace(/^\s*-\s*/, "");
    elements.push(
      <LineContainer>
        <LineBullet>•</LineBullet>
        <Line key={index}>
          <Typography variant="body1">{processedLine}</Typography>
        </Line>
      </LineContainer>
    );
  });

  return elements;
};

export const processUrl = (url) => {
  if (!url) return null;
  const pattern = /^(https?:\/\/)?(www\.)?/;
  return url.replace(pattern, "");
};

export const displayArrayWithCommas = (array) => {
  if (!array || !Array.isArray(array) || array.length === 0) return "";
  return array.join(", ");
};
export const CalculateResumeCompletion = (profileData) => {
  let totalCompletion = 0;
  if (profileData?.experiences.length > 0) {
    totalCompletion += 25;
  }
  if (profileData?.educations.length > 0) {
    totalCompletion += 25;
  }
  if (profileData?.skillSet.length > 0) {
    totalCompletion += 25;
  }
  if (profileData?.projects.length > 0) {
    totalCompletion += 25;
  }
  return totalCompletion;
};

export const deriveSlugWebsite = (url) => {
  const segments = url.split("/").filter(Boolean);
  return segments[segments.length - 1];
};

export const isProfileComplete = (profileData) => {
  return (
    profileData?.experiences.length === 0 ||
    profileData?.educations.length === 0
  );
};

export const calculateDuration = (
  fromMonth,
  fromYear,
  toMonth,
  toYear,
  current
) => {
  const monthsMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const fromDate = new Date(fromYear, monthsMap[fromMonth]);
  const toDate = current ? new Date() : new Date(toYear, monthsMap[toMonth]);

  const diffInMilliseconds = toDate - fromDate;
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  const years = Math.floor(diffInDays / 365);
  const months = Math.floor((diffInDays % 365) / 30);
  const days = Math.floor((diffInDays % 365) % 30);

  let duration = "";
  if (years > 0) {
    duration += `${years} ${years > 1 ? "years" : "year"}`;
  }
  if (months > 0) {
    duration += ` ${months} ${months > 1 ? "months" : "month"}`;
  }
  if (years === 0 && months === 0 && days > 0) {
    duration += `${days} ${days > 1 ? "days" : "day"}`;
  }

  return duration.trim();
};
