import styled from "styled-components";
import { Card, Box } from "@mui/material";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 18rem;
  @media (max-width: 1440px) {
    margin: 0.5rem 12rem;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem 6rem;
  }
  @media (max-width: 1024px) {
    margin: 0.5rem 4rem;
  }
  @media (max-width: 425px) {
    margin: 0.5rem 2rem;
  }
`;
export const HomePortfolioContainer = styled.div`
  margin-bottom: 5rem;
`;

export const FreelancePortfolioContainer = styled.div``;
export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const TabItem = styled.p`
  cursor: pointer;
  min-height: 25px;
  padding-bottom: 0.1rem;
  border-bottom: ${(props) => (props.active ? "1px solid #ccc" : "none")};
`;

export const ProfileHeader = styled.h1``;

export const JobTitle = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
`;

export const Location = styled.h3`
  margin: 0rem;
  font-size: 1.2rem;
`;

export const WorkBio = styled.p``;

export const Title = styled.h2`
  font-weight: 500;
  text-decoration: underline;
`;
export const SubTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 0.1rem;
`;

export const JobTitleContainer = styled.div`
  display: flex;
  gap: 0.1rem;
  p {
    margin-top: 0rem;
  }
`;
export const DescriptionContainer = styled.div`
  font-size: 1.1rem;
`;

export const PayRateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1.2rem;
  p {
    font-weight: 600;
  }
`;
export const CreatorPortfolioContainer = styled.div``;

export const SocialMediaHandlesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SocialMedia = styled.div`
  width: 50%;
`;
export const SocialMediaButton = styled.button`
  background-color: ${(props) => props.bgColor};
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;

  cursor: pointer;
  img {
    height: 20px;
  }
`;

export const SocialMediaLogo = styled.img`
  height: 20px;
`;
export const SelectedSocialMedia = styled(Card)`
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 50%;
  p {
    margin: 0rem;
  }
`;

export const ConnectButton = styled.button`
  background-color: #fed33c;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
`;

export const PayContainer = styled.div`
  margin-top: 1rem;
  h2 {
    margin-bottom: 0.1rem;
    font-size: 1.3rem;
    font-family: "Visby bold";
    text-decoration: underline;
  }
`;
export const PayCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
`;
export const PayCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.8rem;
  width: 70%;
  border: 1px solid #ddd;
  margin-top: 0.8rem;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    width: 50%;
  }
`;

export const ImageContainer = styled.img`
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ExperienceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  h3 {
    margin-top: 1.1rem;
    font-size: 1rem;
    font-family: "Visby bold";
  }
`;

export const Experience = styled.div``;

export const SkillsContainer = styled.div`
  h2 {
    margin-bottom: 0.1rem;
    font-size: 1.1rem;
    font-family: "Visby bold";
    text-decoration: underline;
  }
`;

export const Bullet = styled.span`
  margin: 0 5px;
  font-size: 2rem;
  vertical-align: middle;
  font-family: "Visby bold";
  line-height: 0;
`;

export const Line = styled.span`
  display: block;
  margin-top: 12px;
`;
export const LineContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;
export const LineBullet = styled.div`
  font-size: 3rem;
  margin-right: 0.2rem;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-family: "Visby bold";
  margin-bottom: 1rem;
`;
export const SocialMediaHandles = styled.div`
  p {
    margin: 0rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }
`;
export const RoleContainer = styled.div`
  margin: 1rem 0rem;
  font-family: "Visby bold";
  font-size: 1.2rem;
`;

export const Bio = styled.div`
  text-align: left;
  text-justify: inter-word;
  margin: 0 auto;
  padding: 10px;
`;

export const Email = styled.div`
  font-weight: 500;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

export const ResumeTitleHeader = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0rem;
  text-decoration: underline;
`;
