import React from "react";
import {
  Wrapper,
  LargeHeader,
  Text,
  SmallHeader,
  ListedContainer,
} from "./PrivacyPolicy.styles";
const PrivacyPolicy = () => {
  return (
    <div>
      <Wrapper>
        <LargeHeader>Privacy Policy</LargeHeader>
        <Text>
          <i>Last Updated: November 17, 2023</i>
        </Text>
        <SmallHeader>1. Introduction</SmallHeader>
        <Text>
          Welcome to hrhero.ai. This Privacy Policy describes how we collect,
          use, process, and share your personal information when you use our
          services, including any associated mobile applications and websites.
        </Text>

        <SmallHeader>2. Information Collection</SmallHeader>
        <Text>We collect information in the following ways:</Text>
        <ListedContainer>
          <ul>
            <li>
              <b>Information You Provide: </b> This includes personal details
              you provide when you register, such as your name, email address,
              and professional details.
            </li>
            <li>
              <b>Data from Social Media Platforms:</b> If you use our feature to
              integrate with social media platforms, we will access information
              as authorized by you via the platform's API.
            </li>
            <li>
              <b>Usage Data and Cookies:</b> We collect information about your
              interactions with our services, including IP address, device
              information, and usage statistics. Cookies and similar
              technologies may be used to enhance your experience.
            </li>
          </ul>
        </ListedContainer>
        <SmallHeader>3. Use of Information</SmallHeader>
        <Text>Your information is used for the following purposes:</Text>
        <ListedContainer>
          <ul>
            <li>To provide, maintain, and improve our services.</li>
            <li>To personalize your experience.</li>
            <li>
              For communication purposes, such as responding to your queries.
            </li>
            <li>For analytics and research to enhance our platform.</li>
          </ul>
        </ListedContainer>
        <SmallHeader>4. Sharing of Information</SmallHeader>
        <Text>We may share your information with:</Text>
        <ListedContainer>
          <ul>
            <li>
              Third-party service providers assisting with our business
              operations.
            </li>
            <li>
              Social media platforms, in accordance with your chosen
              integrations.
            </li>
            <li>Law enforcement or legal requests, if required.</li>
          </ul>
        </ListedContainer>
        <SmallHeader>5. Data Security</SmallHeader>
        <Text>
          We implement reasonable measures to protect your information from
          unauthorized access, alteration, disclosure, or destruction.
        </Text>
        <SmallHeader>6. International Transfers</SmallHeader>
        <Text>
          As a global service, we may transfer and process your information in
          countries other than your own, always striving for a similar level of
          data protection.
        </Text>
        <SmallHeader>7. Your Rights</SmallHeader>
        <Text>
          You have the right to access, update, or delete your personal
          information. Additionally, you may have rights to object to or
          restrict certain processing of your data.
        </Text>
        <SmallHeader>8. Changes to This Policy</SmallHeader>
        <Text>
          We reserve the right to modify this Privacy Policy. Any changes will
          be posted on our platform, and we will indicate the date of the latest
          revision.
        </Text>
        <SmallHeader>9. Contact Us</SmallHeader>
        <Text>
          For questions or concerns regarding this Privacy Policy, please
          contact us at support@hrhero.ai
        </Text>
      </Wrapper>
    </div>
  );
};

export default PrivacyPolicy;
