import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FaPencil } from "react-icons/fa6";
import SummaryDisplay from "./Summary/SummaryDisplay";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { CardContainer, HeaderContainer } from "../../Profile.styles";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
const AboutMe = ({ profileData, refetch }) => {
  const uid = getUIDFromLocalStorage();
  const [openAboutMe, setOpenAboutMe] = useState(false);
  return (
    <CardContainer>
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          About Me
        </Typography>
        {uid && uid === profileData?._id && (
          <ToolTip label={"Add & Edit with AI"}>
            <IconButton
              size="small"
              onClick={() => setOpenAboutMe(true)}
              sx={{
                padding: 0,
                marginBottom: "0.3rem",
                color: "black",
              }}
            >
              <FaPencil style={{ fontSize: "1rem" }} />
            </IconButton>
          </ToolTip>
        )}
      </HeaderContainer>
      <SummaryDisplay
        refetch={refetch}
        profileInfo={profileData}
        openAboutMe={openAboutMe}
        setOpenAboutMe={setOpenAboutMe}
      />
      {profileData?.bio?.length > 0 && (
        <div>
          <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            {profileData?.bio}
          </Typography>
        </div>
      )}
    </CardContainer>
  );
};

export default AboutMe;
