import React from "react";
import { TextField, Typography, Box } from "@mui/material";
import { useField } from "formik";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        meta.touched && meta.error ? (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography
              variant="p"
              color="error"
              sx={{
                fontSize: "0.9rem",
                margin: 0,
                textAlign: "left",
                padding: 0,
              }}
            >
              {meta.error}
            </Typography>
          </Box>
        ) : null
      }
      sx={{
        marginBottom: "1rem",
      }}
      InputProps={{
        style: { backgroundColor: "white" },
      }}
    />
  );
};

export default TextInput;
