import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { FaPencil } from "react-icons/fa6";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import { displayArrayWithCommas } from "../../../../utils/ProfileUtil";
import { CardContainer, HeaderContainer } from "../../Profile.styles";
import {
  POST_PROFILE_DETAILS,
  POST_PROFILE_DETAILS_URL,
} from "../../Profile.constants";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
const Languages = ({ profileInfo, refetch }) => {
  const [openLanguages, setOpenLanguages] = useState(false);
  const uid = getUIDFromLocalStorage();
  const [languages, setLanguages] = useState("");
  const [profileData, setProfileData] = useState(profileInfo);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const onSettled = (data) => {
    if (data.error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    } else {
      setOpenLanguages(false);
      refetch();
    }
  };

  const { mutate: updatedProfileData } = useApiQuery(POST_PROFILE_DETAILS, {
    method: API_CONSTANTS.POST,
    url: `${POST_PROFILE_DETAILS_URL}/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });

  const HandlingDeleteCourse = (id) => {
    const newArray = profileData?.languages?.filter((item) => item !== id);
    setProfileData((prevState) => ({
      ...prevState,
      languages: newArray,
    }));
  };
  const showLanguages = profileData?.languages?.map((item, index) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        endIcon={<AiOutlineDelete style={{ fontSize: "15px" }} />}
        onClick={() => HandlingDeleteCourse(item)}
        style={{ marginRight: "1rem", marginBottom: "1rem" }}
      >
        {item}
      </Button>
    );
  });
  const HandlingAddingLanguages = () => {
    if (profileData?.languages) {
      setProfileData((prevState) => ({
        ...prevState,
        languages: [...prevState.languages, languages],
      }));
      setLanguages("");
    } else {
      setProfileData((prevState) => ({
        ...prevState,
        languages: [languages],
      }));
    }
  };
  return (
    <CardContainer>
      {" "}
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          Languages
        </Typography>
        {uid && uid === profileInfo?._id && (
          <ToolTip label={"Add & Edit"}>
            <IconButton
              size="small"
              onClick={() => setOpenLanguages(true)}
              sx={{
                padding: 0,
                marginBottom: "0.3rem",
                color: "black",
              }}
            >
              <FaPencil style={{ fontSize: "1rem" }} />
            </IconButton>
          </ToolTip>
        )}
      </HeaderContainer>
      <>
        <Dialog
          open={openLanguages}
          onClose={() => setOpenLanguages(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Typography variant="h6" component="div" style={{ flex: 1 }}>
              Languages
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenLanguages(false)}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "gray",
              }}
            >
              <AiOutlineClose />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={10} sm={10}>
                  <TextField
                    color="secondary"
                    label="Language"
                    type="text"
                    required
                    fullWidth
                    value={languages}
                    onChange={(e) => setLanguages(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Button
                    variant="contained"
                    onClick={HandlingAddingLanguages}
                    disabled={languages.length === 0}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Grid container spacing={2} style={{ marginTop: "16px" }}>
              {showLanguages}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                updatedProfileData({ profileData });
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setProfileData(profileInfo);
                setOpenLanguages(false);
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
      {profileData?.languages?.length > 0 && (
        <div>
          <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            {displayArrayWithCommas(profileData?.languages)}
          </Typography>
        </div>
      )}
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </CardContainer>
  );
};

export default Languages;
