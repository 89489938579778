import styled from "styled-components";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const InteractiveContainer = styled(Box)`
  margin: 1rem 0rem;
  display: flex;
  gap: 10px;
`;

export const SpaceContainer = styled(Box)`
  height: 7rem;
  border: 1px solid #ebebeb;
  width: 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  margin-bottom: 0rem;
  background: radial-gradient(ellipse at top, #e9f3ff, #bdd5f2),
    radial-gradient(ellipse at bottom, #4d9f0c, transparent);
  @media (max-width: 1024px) {
    width: 70%;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ImageContainer = styled(Box)`
  cursor: pointer;
  img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 1px solid white;
  }
`;

export const HeaderContainer = styled(Box)`
  margin-top: 5rem;
  text-align: center;
`;

export const FollowerCountContainer = styled(Box)``;

export const FollowerCount = styled(Box)`
  background-color: #f5f5f5;
  padding: 0.4rem;
  border-radius: 15px;
`;
