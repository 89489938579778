import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import logo from "../../../src/assets/images/logo.png";
import { useSelector } from "react-redux";
import {
  Container,
  LeftContainer,
  Image,
} from "./NavBar.styles";
const NavBar = ({ pricingPage, toggleSideBar }) => {
  const navigate = useNavigate();
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const location = useLocation();
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [location, userInformation]);
  return (
    <Container>
      <LeftContainer>
        <div>
          <Image
            src={logo}
            alt="logo"
            onClick={() => navigate("/home")}
          />
        </div>

        <div>Beta</div>
      </LeftContainer>
    </Container>
  );
};

export default NavBar;
