import { Box, Stack, Card } from "@mui/material";
import { keyframes, styled } from "styled-components";
export const Container = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
`;
export const HomeContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const MessageContainer = styled(Box)`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const UserMessageContainer = styled(Box)`
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.2rem;
  border-radius: 10px;
  border: 1px solid #e0dfdc;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
`;

export const UserMessageIcon = styled(Box)`
  font-family: "Visby Bold";
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const UserMessage = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AssistantMessageContainer = styled(Box)`
  width: 60%;
  margin-top: 1rem;
  line-height: 1.2rem;
  border-radius: 10px;
  border: 1px solid #e0dfdc;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
`;
export const AssistantMessageIcon = styled(Box)`
  font-family: "Visby Bold";
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AssistantIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  border: 1px solid black;
  border-radius: 50%;
`;
export const AssistantMessage = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
export const InputContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 60%;
  margin-bottom: 0.5rem;
  border-top: 1px solid grey;
`;
export const Disclaimer = styled(Box)`
  font-size: 13px;
  color: grey;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const AIActionContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AIActionsList = styled(Stack)`
  width: 55%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

export const AnimatedEllipsis = styled.span`
  animation: ${blink} 1s infinite;
  color: blue;
  font-size: 2.5rem;
`;
export const LinkedInNetworkAssistantContainer = styled(Box)`
  padding: 0.9rem;
`;

export const LinkedInProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: show;
`;
export const LinkedInProfileListContainer = styled.ul`
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #e0dfdc;
`;

export const ActionItems = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1px;
  justify-content: flex-start;
  align-items: start;
  margin-bottom: 1rem;
  width: 60%;
`;

export const AiActionContainer = styled.div`
  position: fixed;
  right: 10%;
  top: 45%;
  transform: translateY(-50%);
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: 10px;
  border: 1px solid #bcb4b4;
  border-radius: 10px;
  box-shadow: 0px 11px 22px 3px rgba(209, 202, 209, 1);
  cursor: pointer;
  @media (max-width: 1440px) {
    right: 5%;
  }
  &.active {
    right: 0;
  }
`;
