export const POST_PROJECTS = "GET_PROJECTS";
export const POST_PROJECTS_URL = "/projects/getAllProjects";

export const DifficultyLevelOptions = [
  { code: "Easy", optionValue: "Beginner" },
  { code: "Medium", optionValue: "Intermediate" },
  { code: "Hard", optionValue: "Expert" },
];

export const dummyDataProject = [
  {
    projectName:
      "project1project1project1project1project1project1project1project1project1project1project1",
    createdBy: "User1",
  },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
  { projectName: "project1", createdBy: "User1" },
];
