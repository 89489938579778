import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../../../api/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
const EditViewCoverLetter = ({
  openEditViewCoverLetter,
  setOpenEditViewCoverLetter,
  coverLetterActionType,
  coverLetterId,
}) => {
  const [coverLetterDetails, setCoverLetterDetails] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const canSave =
    coverLetterDetails?.coverLetterName &&
    coverLetterDetails?.companyName &&
    coverLetterDetails?.content;
  useEffect(() => {
    const handleGettingSingleCoverLetter = async () => {
      try {
        const response = await api.get(
          `/coverLetter/getSingleCoverLetter/${coverLetterId}`
        );
        if (response.data.success) {
          setCoverLetterDetails(response.data.data);
        }
      } catch (error) {
        showSnackbar(
          "Looks like something went wrong. Please try again later",
          "error"
        );
      }
    };
    if (coverLetterId) {
      handleGettingSingleCoverLetter();
    }
  }, [coverLetterId]);

  useEffect(() => {
    if (coverLetterDetails) {
      setCoverLetterDetails(coverLetterDetails);
    }
  }, [coverLetterDetails]);
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      showSnackbar("Failed to copy to clipboard. Please try again", "error");
    }
  };

  const updatingCoverLetter = async () => {
    try {
      const response = await api.patch(
        `/coverLetter/editCoverLetter/${coverLetterId}`,
        {
          coverLetterDetails,
        }
      );
      if (response.data.success) {
        setOpenEditViewCoverLetter(false);
      }
    } catch (error) {
      showSnackbar(
        "Failed to update the cover letter. Please try again",
        "error"
      );
    }
  };
  return (
    <Dialog
      open={openEditViewCoverLetter}
      onClose={() => setOpenEditViewCoverLetter(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={coverLetterActionType === "view" ? "md" : "lg"}
      fullWidth={coverLetterActionType === "view"}
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          {coverLetterActionType === "view"
            ? coverLetterDetails?.coverLetterName
            : "Edit Cover Letter"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenEditViewCoverLetter(false)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {coverLetterActionType === "view" ? (
          <>
            <Grid item lg={12} sx={{ marginTop: "1rem" }}>
              <TextField
                key={coverLetterDetails?.content || "loading"}
                multiline
                name="content"
                color="secondary"
                label="Cover letter"
                rows={8}
                disabled={coverLetterActionType === "view"}
                value={coverLetterDetails?.content}
                sx={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  backgroundColor: "white",
                  "& .Mui-disabled": {
                    "-webkit-text-fill-color": "unset",
                    color: "black",
                    "& .MuiInputBase-input": {
                      WebkitTextFillColor: "black",
                    },
                  },
                }}
              />
            </Grid>
            <Button
              variant="contained"
              sx={{ marginTop: "1rem" }}
              onClick={() => copyToClipboard(coverLetterDetails?.content)}
            >
              Copy to clipboard
            </Button>
          </>
        ) : (
          <>
            <form>
              <Grid container spacing={2} sx={{ marginTop: "0.1rem" }}>
                <Grid item xs={12}>
                  <TextField
                    key="coverLetterName"
                    color="secondary"
                    name="title"
                    label="Title"
                    type="text"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    value={coverLetterDetails?.coverLetterName}
                    onChange={(e) =>
                      setCoverLetterDetails({
                        ...coverLetterDetails,
                        coverLetterName: e.target.value,
                      })
                    }
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="secondary"
                    name="company"
                    label="Company Name"
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={coverLetterDetails?.companyName}
                    onChange={(e) =>
                      setCoverLetterDetails({
                        ...coverLetterDetails,
                        companyName: e.target.value,
                      })
                    }
                    required
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    name="content"
                    color="secondary"
                    label="Cover letter description"
                    rows={8}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    value={coverLetterDetails?.content}
                    onChange={(e) =>
                      setCoverLetterDetails({
                        ...coverLetterDetails,
                        content: e.target.value,
                      })
                    }
                    sx={{
                      width: "100%",
                      marginBottom: "0.5rem",
                      backgroundColor: "white",
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {coverLetterActionType === "view" ? (
          <Button
            autoFocus
            variant="contained"
            onClick={() => setOpenEditViewCoverLetter(false)}
          >
            Close
          </Button>
        ) : (
          <>
            <Button
              autoFocus
              variant="contained"
              disabled={!canSave}
              onClick={() => updatingCoverLetter()}
            >
              Save
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={() => setOpenEditViewCoverLetter(false)}
            >
              Cancel
            </Button>
          </>
        )}
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default EditViewCoverLetter;
