import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../api/api";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import ToastNotification from "../../commonComponents/ToastNotification/ToastNotification";
const CoverLetterStorage = ({
  openCoverLetterStorage,
  setOpenCoverLetterStorage,
  coverLetterContent,
}) => {
  const uid = getUIDFromLocalStorage();
  const [coverLetterDetails, setCoverLetterDetails] = useState({
    content: coverLetterContent,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const canSave =
    coverLetterDetails.coverLetterName &&
    coverLetterDetails.companyName &&
    coverLetterDetails.content;
  useEffect(() => {
    setCoverLetterDetails((prevDetails) => ({
      ...prevDetails,
      content: coverLetterContent,
    }));
  }, [coverLetterContent]);
  const handleSavingCoverLetter = async () => {
    try {
      const response = await api.post(`/coverLetter/addCoverLetter/${uid}`, {
        coverLetterDetails,
      });
      if (response.data.success) {
        setOpenCoverLetterStorage(false);
        showSnackbar("Added cover letter successfully!", "success");
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  return (
    <Dialog
      open={openCoverLetterStorage}
      onClose={() => setOpenCoverLetterStorage(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          marginBottom: "0.1rem",
        }}
      >
        <Typography variant="h6" component="div">
          Save Cover letter
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpenCoverLetterStorage(false)}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ marginTop: "0.1rem" }}>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                name="title"
                label="Title"
                type="text"
                required
                fullWidth
                value={coverLetterDetails.coverLetterName}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    coverLetterName: e.target.value,
                  })
                }
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                name="company"
                label="Company Name"
                type="text"
                value={coverLetterDetails.companyName}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    companyName: e.target.value,
                  })
                }
                required
                fullWidth
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                name="content"
                color="secondary"
                label="Cover letter description"
                rows={8}
                required
                value={coverLetterDetails?.content}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    content: e.target.value,
                  })
                }
                sx={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  backgroundColor: "white",
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSavingCoverLetter}
          autoFocus
          variant="contained"
          disabled={!canSave}
        >
          Save
        </Button>
        <Button
          onClick={() => setOpenCoverLetterStorage(false)}
          autoFocus
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default CoverLetterStorage;
