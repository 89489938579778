import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoEllipsisHorizontalOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router";
import api from "../../api/api";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import ToastNotification from "../../commonComponents/ToastNotification/ToastNotification";
import { getTimeElapsed } from "../../utils/ChatbotUtil";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import { CircularProgressContainer } from "../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import { menuItems } from "../Threads/Threads.constants";
import {
  Container,
  FilteredListContainer,
  FilteredThreadContainer,
  HeaderContainer,
  IconButtonContainer,
  LeftHeaderContainer,
  ListOptionContainer,
  ListOptionLeftContainer,
  ListOptionRightContainer,
  NoThreadCollectionContainer,
  NoThreadMessages,
  RightHeaderContainer,
  ThreadCollectionContainer,
  ThreadCollectionHeader,
  ThreadCollectionHeaderContainer,
  ThreadCollectionIconContainer,
  ThreadContainer,
} from "../Threads/Threads.styles";
import CreateNewCoverLetter from "./components/CreateNewCoverLetter/CreateNewCoverLetter";
import DeleteAllCoverLetters from "./components/DeleteAllCoverLetters/DeleteAllCoverLetters";
import EditViewCoverLetter from "./components/EditViewCoverLetter/EditViewCoverLetter";
import {
  GET_COVER_LETTER,
  GET_COVER_LETTER_URL,
} from "./CoverLetter.constants";
const CoverLetter = () => {
  const [openEditViewCoverLetter, setOpenEditViewCoverLetter] = useState(false);
  const [coverLetterActionType, setCoverLetterActionType] = useState("edit");
  const [coverLetterId, setCoverLetterId] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [message, setMessage] = useState("");
  const [openCoverLetter, setOpenCoverLetter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const uid = getUIDFromLocalStorage();
  useTrackPageViewDuration("Chatbot Menu Page");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const {
    data: coverLetterData,
    isLoading: isCoverLetterDataLoading,
    isError: isCoverLetterDataError,
    refetch,
  } = useApiQuery(GET_COVER_LETTER, {
    method: API_CONSTANTS.GET,
    url: `${GET_COVER_LETTER_URL}${uid}`,
    enabled: uid !== null,
  });

  useEffect(() => {
    if (!isCoverLetterDataLoading && !isCoverLetterDataError) {
      setFilteredList(coverLetterData.data);
    }
  }, [isCoverLetterDataLoading, isCoverLetterDataError, coverLetterData]);

  const handleNewChat = async (message, type) => {
    try {
      const response = await api.post(`/chatting/startNewChat/${uid}`, {
        message: message,
      });
      if (response.data.success) {
        navigate(`/search/${response.data.data.thread_id}`, {
          state: { startMessage: message, actionType: type },
        });
      }
    } catch (error) {
      console.error("Error starting new chat:", error);
    }
  };

  useEffect(() => {
    if (!openDialogBox || !openEditViewCoverLetter) {
      refetch();
    }
  }, [openEditViewCoverLetter, openDialogBox]);
  useEffect(() => {
    if (searchName === "") {
      setFilteredList(coverLetterData?.data);
    } else {
      const filteredSearchList = searchName
        ? coverLetterData?.data.filter((chat) =>
            chat.coverLetterName
              .toLowerCase()
              .includes(searchName.toLowerCase())
          )
        : coverLetterData?.data;
      setFilteredList(filteredSearchList);
    }
  }, [searchName]);
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete(
        `/coverLetter/deleteChat/${coverLetterId}`
      );
      if (response.data.success) {
        setIsLoading(false);
        const newSearchList = coverLetterData?.data.filter(
          (chat) => chat._id !== coverLetterId
        );
        setFilteredList(newSearchList);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const handleOpenEditDialog = () => {
    setOpenEditViewCoverLetter(true);
    setCoverLetterActionType("edit");
  };

  const menuItemList = menuItems(handleDelete, handleOpenEditDialog);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isCoverLetterDataLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Box>
          <Container>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {menuItemList.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      item.onClick();
                      handleClose();
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ marginRight: "5px", marginTop: "7px" }}>
                        {item.icon}
                      </Box>
                      <Typography variant="inherit">{item.label}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Menu>
            <CreateNewCoverLetter
              open={openCoverLetter}
              setOpenAIActions={setOpenCoverLetter}
              setMessage={setMessage}
              handleNewChat={handleNewChat}
              uid={uid}
            />
            <HeaderContainer>
              <LeftHeaderContainer></LeftHeaderContainer>
              <RightHeaderContainer>
                <TextField
                  label="Search..."
                  id="outlined-size-small"
                  size="small"
                  value={searchName}
                  color="secondary"
                  onChange={(e) => setSearchName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HiMagnifyingGlass />
                      </InputAdornment>
                    ),
                  }}
                />
              </RightHeaderContainer>
            </HeaderContainer>
            <ThreadCollectionContainer>
              <ThreadContainer>
                <ThreadCollectionHeaderContainer>
                  <ThreadCollectionHeader>
                    <FaEnvelopeOpenText
                      style={{
                        marginRight: "0.2rem",
                        paddingTop: "0.3rem",
                        fontSize: "20px",
                      }}
                    />
                    <Typography variant="h5">Cover Letters</Typography>
                  </ThreadCollectionHeader>
                  <ThreadCollectionIconContainer>
                    {filteredList?.length !== 0 && (
                      <IconButtonContainer
                        aria-label="more"
                        id="long-button"
                        onClick={() => setOpenDialogBox(true)}
                      >
                        <MdDeleteOutline
                          style={{ color: "black", fontSize: "16px" }}
                        />
                      </IconButtonContainer>
                    )}
                    <DeleteAllCoverLetters
                      openDialogBox={openDialogBox}
                      setOpenDialogBox={setOpenDialogBox}
                      HandlingGettingChats={refetch}
                    />
                  </ThreadCollectionIconContainer>
                </ThreadCollectionHeaderContainer>
                {filteredList?.length > 0 ? (
                  <Box>
                    {filteredList.map((chat, index) => (
                      <FilteredThreadContainer
                        bottomBorder={
                          index === filteredList.length - 1 && "false"
                        }
                        key={chat._id}
                      >
                        <FilteredListContainer>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setCoverLetterActionType("view");
                              setOpenEditViewCoverLetter(true);
                              setCoverLetterId(chat._id);
                            }}
                          >
                            <Typography variant="subtitle1">
                              {chat.coverLetterName.length > 50
                                ? chat.coverLetterName.substring(0, 80) + "..."
                                : chat.coverLetterName}
                            </Typography>
                          </Box>
                          <ListOptionContainer>
                            <ListOptionLeftContainer>
                              <Typography variant="body2" color="textSecondary">
                                {getTimeElapsed(chat.updatedAt)} ago
                              </Typography>
                            </ListOptionLeftContainer>
                            <ListOptionRightContainer>
                              <IconButtonContainer
                                aria-label="more"
                                id="long-button"
                                onClick={(e) => {
                                  handleClick(e);
                                  setCoverLetterId(chat._id);
                                }}
                              >
                                <IoEllipsisHorizontalOutline
                                  style={{ color: "black", fontSize: "16px" }}
                                />
                              </IconButtonContainer>
                            </ListOptionRightContainer>
                          </ListOptionContainer>
                        </FilteredListContainer>
                      </FilteredThreadContainer>
                    ))}
                  </Box>
                ) : (
                  <NoThreadCollectionContainer>
                    {searchName.length > 0 ? (
                      <NoThreadMessages variant="body1">
                        No cover letters found.
                      </NoThreadMessages>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => setOpenCoverLetter(true)}
                      >
                        Create a cover letter
                      </Button>
                    )}
                  </NoThreadCollectionContainer>
                )}
              </ThreadContainer>
              <EditViewCoverLetter
                openEditViewCoverLetter={openEditViewCoverLetter}
                setOpenEditViewCoverLetter={setOpenEditViewCoverLetter}
                coverLetterActionType={coverLetterActionType}
                coverLetterId={coverLetterId}
              />
            </ThreadCollectionContainer>
          </Container>
          <ToastNotification
            open={openSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarSeverity={snackbarSeverity}
          />
        </Box>
      )}
    </>
  );
};

export default CoverLetter;
