import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
const InterviewPreparation = ({
  open,
  setOpenAIActions,
  handleNewChat,
  sendPrompt,
  chatType,
}) => {
  const [jobRoundName, setJobRoundName] = useState("");
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const navigate = useNavigate();
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const jobDescription = useSelector((state) => state?.ai?.jobDescription);
  const [isJobDescription, setIsJobDescription] = useState(
    jobDescription || ""
  );
  const [currentStep, setCurrentStep] = useState(1);

  const HandlingInterviewPrep = (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let prompt;
    if (isJobDescription === "") {
      prompt = `Create an interview prep guide for ${jobRoundName} Give questions that one can be asked in this round with possible answers. Also provide questions that needs to be asked to the interviewer in this round. At the end provide the user with tips on how to prepare for the round and provide a thank you email.`;
    } else {
      prompt = `Create an interview prep guide for ${jobRoundName} for the job whose job description is ${isJobDescription}, Give questions that one can be asked in this round with possible answers. Also provide questions that needs to be asked to the interviewer in this round. At the end provide the user with tips on how to prepare for the round and provide a thank you email.`;
    }
    if (chatType !== "newChat") {
      sendPrompt(prompt, "interviewPreparation");
    } else {
      handleNewChat(prompt, "interviewPreparation");
    }

    setOpenAIActions(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10}>
              <TextField
                color="secondary"
                label="What round are you preparing for?"
                placeholder="Hiring manager round, Coding round, etc."
                type="text"
                fullWidth
                required
                value={jobRoundName}
                onChange={(e) => setJobRoundName(e.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                label="Job Description"
                placeholder="Describe the job requirements..."
                type="text"
                multiline
                rows={4}
                fullWidth
                value={isJobDescription}
                onChange={(e) => setIsJobDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  const dialogWidth = currentStep === 2 ? "lg" : "md";

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpenAIActions(false)}
        fullWidth
        maxWidth={dialogWidth}
      >
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ flex: 1 }}>
            Interview Round Preparation
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenAIActions(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={HandlingInterviewPrep}>
            {renderStepContent(currentStep)}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAIActions(false)} variant="contained">
            Cancel
          </Button>
          {pricingPlan?.price?.actionsIncluded <= 3 ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/pricing")}
            >
              Upgrade
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={HandlingInterviewPrep}
                disabled={currentStep === 1 && !jobRoundName.trim()}
              >
                {currentStep === 1 ? "Next" : "Submit"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InterviewPreparation;
