import { Box, Card } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin: 1rem;
  height: 100%;
`;

export const FilterContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-height: 5%;
  padding: 1rem;
  @media (max-width: 426px) {
    width: 90%;
  }
`;

export const SearchJobRoleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 426px) {
    gap: 5px;
  }
`;

export const FilterOptionsContainer = styled(Box)``;
export const JobsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 100%;
`;

export const JobsListContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-top: 1rem;
  height: 100%;
`;

export const JobListLeftContainer = styled(Box)`
  width: 25%;
  height: calc(100vh - 2rem);
  overflow-y: auto;
`;

export const JobListRightContainer = styled(Box)`
  width: 75%;
  height: 100%;
  overflow-y: auto;
`;

export const JobsCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  height: auto;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    .company-name {
      text-decoration: underline;
    }
  }
`;

export const ImageContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  img {
    margin-right: 0.5rem;
  }
`;

export const PaginationContainer = styled(Box)`
  margin: 1rem 1rem 1rem 0rem;
  display: flex;
  justify-content: space-between;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0rem;
  border-bottom: 1px solid #e0e0e0;
  @media (max-width: 426px) {
    & > h4 {
      font-size: 1.3rem;
    }
  }
`;

export const LoadMoreJobsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
`;

export const UpdatedCreditsContainer = styled(Box)`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionHrefLink = styled.a`
  color: #af9eff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: #af9eff;
  }
`;
