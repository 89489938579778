import {
  GoogleAuthProvider,
  deleteUser,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import api from "../api/api";
import app from "../assets/firebase/firebaseConfig";

const auth = getAuth(app);

export const googleAuthentication = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    let response;
    response = await api.post("/auth/login", {
      email: user.email,
      type: "google",
    });
    if (response.data.success) {
      return response.data;
    }
    const nameParts = user.displayName.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    response = await api.post("/auth/register", {
      email: user.email,
      firstName,
      lastName,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteGoogleUser = () => {
  const user = auth.currentUser;
  deleteUser(user)
    .then(() => {})
    .catch((error) => {});
};
