import { createSlice } from "@reduxjs/toolkit";

export const landingPageSlice = createSlice({
  name: "landingPage",
  initialState: {
    chatLogInformation: [],
    newUser: false,
  },
  reducers: {
    addingChatLogs: (state, action) => {
      state.chatLogInformation.push(action.payload);
    },
    removeChatLog: (state) => {
      state.chatLogInformation = [];
    },
  },
});

export const { addingChatLogs, removeChatLog } = landingPageSlice.actions;
export default landingPageSlice.reducer;
