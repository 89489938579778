import React, { useState, useEffect } from "react";
import { tips } from "../../helpers/loadingContent";
import {
  Container,
  ProgressBarContainer,
  ProgressBarFill,
  ProgressText,
} from "./LoadingContainer.styles";
import { Typography } from "@mui/material";
const LoadingContainer = ({ number = 1000 }) => {
  const [randomNumber, setRandomNumber] = useState(0);
  const [facts, setFacts] = useState(tips);
  const [progress, setProgress] = useState(0);

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * facts.length);
  };
  useEffect(() => {
    setRandomNumber(generateRandomNumber());

    setProgress(0);

    const factInterval = setInterval(() => {
      setRandomNumber(generateRandomNumber());
    }, 5000);

    const progressInterval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress < 100) {
          const newProgress = oldProgress + 1;
          return newProgress;
        }
        return 100;
      });
    }, number);
    return () => {
      clearInterval(factInterval);
      clearInterval(progressInterval);
    };
  }, []);
  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBarFill
          style={{ width: `${progress}%`, transition: "width 0.1s linear" }}
        >
          <ProgressText>{progress}%</ProgressText>
        </ProgressBarFill>
      </ProgressBarContainer>

      <div>
        {facts[randomNumber].pick === "quote" ? (
          <>
            <Typography style={{ margin: 0, marginTop: "1rem" }}>
              "{facts[randomNumber].title}"
            </Typography>
            <Typography style={{ fontStyle: "italic", margin: 0 }}>
              - {facts[randomNumber].subTitle}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              style={{ fontStyle: "bold", marginBottom: 0 }}
              mt={3}
            >
              {facts[randomNumber].title}
            </Typography>
            <Typography style={{ margin: 0 }}>
              {facts[randomNumber].subTitle}
            </Typography>
          </>
        )}
      </div>
    </Container>
  );
};

export default LoadingContainer;
