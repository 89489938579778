import * as Yup from "yup";

export const validationSchema = Yup.object({
  organizationName: Yup.string()
    .required("Organization name is required")
    .max(75, "Organization name must be at most 75 characters")
    .matches(
      /^[a-zA-Z0-9\s-]+$/,
      "Organization cannot contain special characters."
    ),
  location: Yup.string()
    .required("Location is required")
    .max(100, "Location must be at most 100 characters"),
  organizationType: Yup.string().required("Organization type is required"),
  numberOfMembers: Yup.string().required("Organization size is required"),
});
