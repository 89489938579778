export const teams = [
  { name: "John", priviledges: "admin" },
  { name: "John", priviledges: "editor" },
  { name: "John", priviledges: "viewer" },
  { name: "John", priviledges: "viewer" },
  { name: "John", priviledges: "admin" },
  { name: "John", priviledges: "admin" },
  { name: "John", priviledges: "editor" },
  { name: "John", priviledges: "viewer" },
];
