import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavBar from "../../commonComponents/NavBar/NavBar";
import { Container, SubContainer } from "./404Page.style";
const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home");
  };

  return (
    <Container>
      <NavBar />
      <SubContainer>
        <Box>
          <Typography variant="h1" gutterBottom>
            Oops!
          </Typography>
          <Typography variant="h5" gutterBottom>
            We can’t seem to find the page you’re looking for. <br /> Try going
            back to the Home page.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleGoHome}>
            Go to Home
          </Button>
        </Box>
      </SubContainer>
    </Container>
  );
};

export default NotFoundPage;
