export const initialValue = {
  organizationName: "",
  industry: "",
  location: "",
  organizationType: "",
  organizationCategory: "",
  otherIndustry: "",
  numberOfMembers: "",
};

export const OrganizationType = [
  { label: "Individual", value: "Individual" },
  { label: "Company", value: "Company" },
];

export const NumberOfMembers = [
  { label: "1-10", value: "1-10" },
  { label: "10-100", value: "10-100" },
  { label: "100-1000", value: "100-1000" },
  { label: "1000+", value: "1000+" },
];

export const Industry = [
  { label: "Agriculture", value: "Agriculture" },
  { label: "Automotive", value: "Automotive" },
  { label: "Banking", value: "Banking" },
  { label: "Construction", value: "Construction" },
  { label: "Consumer Goods", value: "Consumer Goods" },
  { label: "Education", value: "Education" },
  { label: "Energy", value: "Energy" },
  { label: "Engineering", value: "Engineering" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Food & Beverage", value: "Food & Beverage" },
  { label: "Government", value: "Government" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Insurance", value: "Insurance" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Media", value: "Media" },
  { label: "Nonprofit", value: "Nonprofit" },
  { label: "Pharmaceuticals", value: "Pharmaceuticals" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Retail", value: "Retail" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Transportation", value: "Transportation" },
  { label: "Utilities", value: "Utilities" },
  { label: "Other", value: "Other" },
];
