import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaPencil } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../../../../api/api";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import LoadingContainer from "../../../../commonComponents/LoadingContainer/LoadingContainer";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { decrementPriceInformation } from "../../../../redux/userSlice";
import { CalculateTokenCost } from "../../../../utils/AICost";
import {
  getEmailFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../../../utils/EmailUtil";
import {
  POST_PROFILE_DETAILS,
  POST_PROFILE_DETAILS_URL,
} from "../../Profile.constants";
import { CardContainer, HeaderContainer } from "../../Profile.styles";
const Achievements = ({ profileInfo, refetch, type }) => {
  const uid = getUIDFromLocalStorage();
  const email = getEmailFromLocalStorage();
  const { userInformation } = useSelector((state) => state.user);
  const [profileData, setProfileData] = useState({
    achievements: profileInfo?.achievements || "",
  });
  const [originalTextResponse, setOriginalTextResponse] = useState([
    profileInfo?.achievements,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAchievements, setOpenAchievements] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentVersionIndex, setCurrentVersionIndex] = useState(1);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const onSettled = (data) => {
    if (data.error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    } else {
      setOpenAchievements(false);
      refetch();
    }
  };
  const { mutate: updatedProfileData } = useApiQuery(POST_PROFILE_DETAILS, {
    method: API_CONSTANTS.POST,
    url: `${POST_PROFILE_DETAILS_URL}/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });

  const navigateVersion = (direction) => {
    let newIndex = currentVersionIndex + direction;
    newIndex = Math.max(1, Math.min(newIndex, originalTextResponse.length)); // Ensure index is within bounds and starts at 1
    setCurrentVersionIndex(newIndex);
    setProfileData({
      ...profileData,
      achievements: originalTextResponse[newIndex - 1],
    });
  };

  const handleGenerateClick = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(`/ai/enhanceProfile/${email}/${type}`, {
        text: originalTextResponse,
      });
      if (response.data.success) {
        pollForResult(response.data.jobId);
      } else {
        showSnackbar(
          "Looks like something went wrong. Please try again later",
          "error"
        );
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const pollForResult = async (jobId) => {
    try {
      const response = await api.get(`/ai/polling-result/${jobId}`);
      if (response.data.status === "done") {
        const creditCount = CalculateTokenCost(
          response.data.usage,
          response.data.modelName
        );
        setOriginalTextResponse((prevState) => [
          ...prevState,
          response.data.data,
        ]);
        setCurrentVersionIndex((prevIndex) => prevIndex + 1);
        setIsLoading(false);
        try {
          await api.post(`/price/updateCredit/${email}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
          setCurrentVersionIndex(currentVersionIndex + 1);
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      } else {
        setTimeout(() => pollForResult(jobId), 5000);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const handleTextChange = (text) => {
    const updatedTextResponse = [...originalTextResponse];
    updatedTextResponse[currentVersionIndex - 1] = text;
    setOriginalTextResponse(updatedTextResponse);
    setProfileData({ ...profileData, achievements: text });
  };
  return (
    <CardContainer>
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          Achievements
        </Typography>
        {uid && uid === profileInfo?._id && (
          <ToolTip label={"Add & Edit with AI"}>
            <IconButton
              size="small"
              onClick={() => setOpenAchievements(true)}
              sx={{
                padding: 0,
                marginBottom: "0.3rem",
                color: "black",
              }}
            >
              <FaPencil style={{ fontSize: "1rem" }} />
            </IconButton>
          </ToolTip>
        )}
      </HeaderContainer>
      <Dialog
        open={openAchievements}
        onClose={() => setOpenAchievements(false)}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": { minHeight: "40vh", height: "auto" }, // Adjust '80vh' as needed
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            Achievements
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenAchievements(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        {isLoading ? (
          <LoadingContainer number={1000} />
        ) : (
          <>
            <DialogContent dividers>
              <form>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <TextField
                      color="secondary"
                      label="Achievements"
                      multiline
                      rows={5}
                      required
                      fullWidth
                      value={
                        originalTextResponse[currentVersionIndex - 1] || ""
                      }
                      onChange={(e) => handleTextChange(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {" "}
                    {pricingPlan?.price?.actionsIncluded <= 5 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/pricing")}
                      >
                        Upgrade
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ mx: 1 }}
                        onClick={handleGenerateClick}
                      >
                        Optimize
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      disabled={currentVersionIndex <= 1}
                      onClick={() => navigateVersion(-1)}
                      sx={{ mx: 1 }}
                    >
                      Prev
                    </Button>
                    <Typography sx={{ mx: 2 }}>
                      Version: {currentVersionIndex} /{" "}
                      {originalTextResponse.length}
                    </Typography>
                    <Button
                      variant="outlined"
                      disabled={
                        currentVersionIndex >= originalTextResponse.length
                      }
                      onClick={() => navigateVersion(1)}
                      sx={{ mx: 1 }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  updatedProfileData({
                    profileData: {
                      achievements:
                        originalTextResponse[currentVersionIndex - 1],
                    },
                  });
                }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setProfileData({
                    achievements: profileInfo.achievements || "",
                  });
                  setOpenAchievements(false);
                }}
                variant="contained"
              >
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      {profileData?.achievements?.length > 0 && (
        <div>
          <Typography variant="body1" sx={{ marginTop: "1rem" }}>
            {profileData?.achievements}
          </Typography>
        </div>
      )}
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </CardContainer>
  );
};

export default Achievements;
