import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { Container } from "../Profile/Profile.styles";
import AboutMe from "../Profile/components/AboutMe/AboutMe";
import Achievements from "../Profile/components/Achievements/Achievements";
import Certification from "../Profile/components/Certification/Certification";
import CourseList from "../Profile/components/CourseList/CourseList";
import Education from "../Profile/components/Education/Education";
import Experience from "../Profile/components/Experience/Experience";
import Intro from "../Profile/components/Intro/Intro";
import Languages from "../Profile/components/Languages/Languages";
import { CircularProgressContainer } from "../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import Project from "../Profile/components/Project/Project";
import SkillSet from "../Profile/components/SkillSet/SkillSet";
import {
  GET_SLUG_WEBSITE,
  GET_SLUG_WEBSITE_URL,
} from "./SlugWebsite.constants";
const SlugWebsite = () => {
  const location = useLocation();
  const slugWebsite = location.pathname.split("/")[2];
  const {
    data: slugProfileData,
    isLoading: isProfileDataLoading,
    isError: isProfileError,
    refetch,
  } = useApiQuery(GET_SLUG_WEBSITE, {
    method: API_CONSTANTS.GET,
    url: `${GET_SLUG_WEBSITE_URL}/${slugWebsite}`,
    enabled: slugWebsite !== null,
  });
  useEffect(() => {
    if (slugWebsite) {
      refetch();
    }
  }, [slugWebsite, refetch]);
  return (
    <>
      {isProfileDataLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Container>
          <Intro
            profileData={slugProfileData?.data}
            refetch={refetch}
            type={"slugWebsite"}
          />
          <AboutMe
            profileData={slugProfileData?.data}
            refetch={refetch}
            type={"Bio"}
          />
          <Experience profileInfo={slugProfileData?.data} refetch={refetch} />
          <Education profileInfo={slugProfileData?.data} refetch={refetch} />
          <Project profileInfo={slugProfileData?.data} refetch={refetch} />
          <Achievements
            profileInfo={slugProfileData?.data}
            refetch={refetch}
            type={"Achievements"}
          />
          <Languages profileInfo={slugProfileData?.data} refetch={refetch} />
          <SkillSet profileInfo={slugProfileData?.data} refetch={refetch} />
          <Certification
            profileInfo={slugProfileData?.data}
            refetch={refetch}
          />
          <CourseList profileInfo={slugProfileData?.data} refetch={refetch} />
        </Container>
      )}
    </>
  );
};

export default SlugWebsite;
