export const EnvironmentSelection = () => {
  switch (process.env.REACT_APP_API_ENDPOINT) {
    case "staging":
      return "https://hrherostage-ecb203589f80.herokuapp.com/";
    case "production":
      return "https://hrheroprod-f239fb7b49f1.herokuapp.com/";
    default:
      return "http://localhost:3002/";
  }
};

export const EnvironmentSelectionForMixPanel = () => {
  switch (process.env.REACT_APP_API_ENDPOINT) {
    case "staging":
      return "staging";
    case "production":
      return "production";
    default:
      return "development";
  }
};
