export const POST_JOBS = "POST_JOBS";
export const POST_JOBS_URL = "/jobs/getJobs";
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_PROFILE_URL = "/profile";
export const FilterTypes = ["Date Posted", "Job Type"];
export const POST_LINKEDIN_PROFILES = "POST_LINKEDIN_PROFILES";
export const POST_LINKEDIN_RECRUITER_PROFILE = "/jobs/recruiterLinkedIn";
export const POST_SELECTED_JOBS = "POST_SELECTED_JOBS";
export const POST_SELECTED_JOBS_URL = "/jobs/addingSelectedJob";
export const DatePostedFilterOptions = [
  "All",
  "Past day",
  "Past 3 days",
  "Past week",
  "Past month",
];
export const TabItems = [
  ["All", "Past day", "Past 3 days", "Past week", "Past month"],
  ["All", "Internship", "Full-time", "Part-time"],
];
