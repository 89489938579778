import { jwtDecode } from "jwt-decode";
import DefaultProtectedRouteLayout from "../../commonComponents/DefaultProtectedRouteLayout/DefaultProtectedRouteLayout";
const ProtectedRouteNoLayout = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };
  if (user && token && !isTokenExpired(token)) {
    return (
      <DefaultProtectedRouteLayout>{children}</DefaultProtectedRouteLayout>
    );
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("plan");
    window.location.href = "/";
    return null;
  }
};

export default ProtectedRouteNoLayout;
