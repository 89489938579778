import { Box, Card } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 10px;
  margin-bottom: 1rem;
`;
export const CircularProgressContainer = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

//These styles is the default styles for the profile page
export const CardContainer = styled(Card)`
  width: 50%;
  padding: 1rem;
  @media (max-width: 1024px) {
    width: 70%;
  }
`;
export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 0.5rem;
  background: #e9f3ff;
`;
