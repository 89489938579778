import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../../../api/api";
import { useDispatch } from "react-redux";
import { decrementPriceInformation } from "../../../../redux/userSlice";
import { CalculateTokenCost } from "../../../../utils/AICost";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import { EnvironmentSelection } from "../../../../utils/EnvUtil";
const JobCoverLetter = ({
  uid,
  openCoverLetter,
  setOpenCoverLetter,
  selectedJob,
}) => {
  const [coverLetterDetails, setCoverLetterDetails] = useState({
    coverLetterName: "",
    companyName: "",
    content: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const dispatch = useDispatch();
  const addingSelectedJob = async () => {
    try {
      const response = await api.post("/jobs/addingSelectedJob", {
        uid: uid,
        jobData: selectedJob,
      });
      if (response.data.success) {
        setCoverLetterDetails((prevState) => {
          return {
            ...prevState,
            coverLetterName: selectedJob.title,
            companyName: selectedJob.company_name,
          };
        });
        fetchCoverLetter(response.data.data.jobId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const environment = EnvironmentSelection();
  const fetchCoverLetter = (jobId) => {
    if (window.eventSource) {
      window.eventSource.close();
    }
    const url = `${environment}jobs/jobAiAction?jobId=${jobId}&uid=${uid}&type=coverLetter`;
    window.eventSource = new EventSource(url);
    let isStartOfNewMessage = true;
    setIsLoading(true);
    window.eventSource.onmessage = async (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.messageType === "ONGOING") {
        if (isStartOfNewMessage) {
          setCoverLetterDetails((prev) => ({
            ...prev,
            content: prev.content + eventData.data,
          }));
        }
      } else if (eventData.messageType === "DONE") {
        isStartOfNewMessage = false;
        const tokenCounts = {
          prompt_tokens: eventData.data.totalInputTokens,
          completion_tokens: eventData.data.totalOutputTokens,
        };
        setIsLoading(false);
        const creditCount = CalculateTokenCost(tokenCounts, "GPT");
        try {
          await api.post(`/credit/updateCredit/${uid}`, {
            decrementBy: creditCount,
          });
          dispatch(decrementPriceInformation(creditCount));
        } catch (error) {
          console.error("Error updating credits:", error);
        }
      }
    };
    window.eventSource.onerror = () => {
      window.eventSource.close();
    };
  };
  useEffect(() => {
    if (openCoverLetter && coverLetterDetails.content === "") {
      addingSelectedJob();
    }
  }, [openCoverLetter]);
  const handleSavingCoverLetter = async () => {
    try {
      const response = await api.post(`/coverLetter/addCoverLetter/${uid}`, {
        coverLetterDetails,
      });
      if (response.data.success) {
        setCoverLetterDetails({
          coverLetterName: "",
          companyName: "",
          content: "",
        });
        setOpenCoverLetter(false);
        showSnackbar("Added cover letter successfully!", "success");
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      showSnackbar("Failed to copy to clipboard. Please try again", "error");
    }
  };
  const canSave =
    coverLetterDetails.coverLetterName &&
    coverLetterDetails.companyName &&
    coverLetterDetails.content;
  return (
    <Dialog
      open={openCoverLetter}
      onClose={() => {
        setCoverLetterDetails({
          coverLetterName: "",
          companyName: "",
          content: "",
        });
        setOpenCoverLetter(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" component="div">
          Cover letter for {selectedJob?.company_name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setCoverLetterDetails({
              coverLetterName: "",
              companyName: "",
              content: "",
            });
            setOpenCoverLetter(false);
          }}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} sx={{ marginTop: "0.1rem" }}>
            <Grid item xs={12}>
              <TextField
                key="coverLetterName"
                color="secondary"
                name="title"
                label="Title"
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={coverLetterDetails?.coverLetterName}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    coverLetterName: e.target.value,
                  })
                }
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                color="secondary"
                name="company"
                label="Company Name"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                value={coverLetterDetails?.companyName}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    companyName: e.target.value,
                  })
                }
                required
                fullWidth
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                name="content"
                color="secondary"
                label="Cover letter description"
                rows={8}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                value={coverLetterDetails?.content}
                onChange={(e) =>
                  setCoverLetterDetails({
                    ...coverLetterDetails,
                    content: e.target.value,
                  })
                }
                sx={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  backgroundColor: "white",
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Button
          variant="contained"
          onClick={() => copyToClipboard(coverLetterDetails?.content)}
        >
          Copy to clipboard
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSavingCoverLetter}
          autoFocus
          variant="contained"
          disabled={!canSave || isLoading}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setCoverLetterDetails({
              coverLetterName: "",
              companyName: "",
              content: "",
            });
            setOpenCoverLetter(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </Dialog>
  );
};

export default JobCoverLetter;
