import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// Set up global Axios error handling
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

let baseURL;
switch (process.env.REACT_APP_API_ENDPOINT) {
  case "staging":
    baseURL = "https://hrherostage-ecb203589f80.herokuapp.com/api/v1";
    break;
  case "production":
    baseURL = "https://hrheroprod-f239fb7b49f1.herokuapp.com/api/v1";
    break;
  default:
    baseURL = "http://localhost:3002/api/v1";
}

const apiClient = axios.create({
  baseURL,
});

const request = ({ method, url, data, params }) => {
  return apiClient({
    method,
    url,
    data,
    params,
  });
};

export const API_CONSTANTS = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PATCH: "patch",
  DELETE_BY_ID: "deleteById",
};

const useApiQuery = (queryKey, options) => {
  const {
    enabled = true,
    method,
    onSettled,
    params = {},
    ...restOptions
  } = options;

  const fetchData = async () => {
    try {
      const response = await request({
        ...restOptions,
        method: API_CONSTANTS.GET,
        params,
      });
      return response.data; // Extract data from the response
    } catch (e) {
      console.error(e);
      throw e; // Re-throw the error to be caught by the query
    }
  };

  const mutateData = async (data) => {
    try {
      if (method === API_CONSTANTS.DELETE_BY_ID) {
        // Include the ID as part of the URL for delete operation
        const response = await request({
          ...restOptions,
          method: API_CONSTANTS.DELETE,
          url: `${restOptions.url}/${data.id}`,
        });
        return response.data;
      } else {
        // For other methods (POST, PATCH), use the original logic
        const response = await request({ ...restOptions, method, data });
        return response.data;
      }
    } catch (e) {
      console.error(e);
      throw e; // Re-throw the error to be caught by the mutation
    }
  };

  // Use useMutation for the POST, PATCH, or DELETE request
  const { mutate, error, ...mutationRest } = useMutation({
    mutationFn: mutateData,
    onSettled: (data, error) => {
      if (onSettled) {
        onSettled({ data, error });
      }
    },
  });

  // Use useQuery for the GET request
  const { refetch, ...queryRest } = useQuery({
    queryKey: [queryKey],
    queryFn: fetchData,
    ...options,
    enabled,
    onSettled: (data, error) => {
      if (onSettled) {
        onSettled({ data, error });
      }
    },
  });

  return {
    mutate,
    error,
    refetch,
    ...mutationRest,
    ...queryRest,
  };
};

export default useApiQuery;
