import { Box, Card } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #e9f3ff 60%, #ffffff 100%);
`;

export const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: start;
  padding: 0rem 3rem;
  @media (max-width: 768px) {
    display: none;
    width: 0%;
  }
`;

export const RightContainer = styled(Box)`
  display: flex;
  width: 60%;
  margin-top: 8rem;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const CarouselContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  h1 {
    margin-bottom: 2rem;
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  button {
    border-radius: 10px;
  }
`;
export const InputButton = styled.label`
  background-color: #fed33c;
  border: 0.1px solid #d9d9d9;
  height: 1.5rem;
  color: black;
  border-radius: 5px;
  padding: 0.6rem;
  text-align: center;
  cursor: pointer;
`;

export const HeaderTaglineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  margin-bottom: 1rem;
`;

export const RoleContainer = styled(Card)`
  padding: 1rem;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UploadingResume = styled(Card)`
  padding: 1rem;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PromptRegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResumeLeftMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const ResumeUpperContainer = styled(Box)`
  height: 60%;
  display: flex;
  margin-left: 4rem;
  flex-direction: column;
  justify-content: center;
`;

export const ResumeBottomContainer = styled(Box)`
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4rem;
`;

export const DragDropBox = styled(Box)`
  border: 2px dashed #b0c4de;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  &:hover {
    background-color: #f0f8ff;
  }
  margin-top: 1rem;
  height: 50%;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const UploadResumeHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 25%;
`;

export const UploadResumeBody = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 50%;
`;

export const UploadResumeFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 25%;
`;
