import React from "react";
import DefaultLayout from "../../commonComponents/DefaultLayout/DefaultLayout";
const PublicPrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return <DefaultLayout>{children}</DefaultLayout>;
  } else {
    return <>{children}</>;
  }
};

export default PublicPrivateRoute;
