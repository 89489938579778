import { Typography } from "@mui/material";
import {
  getFirstNameFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../../../utils/EmailUtil";
import {
  CarouselContainer,
  Container,
  LeftContainer,
  ResumeBottomContainer,
  ResumeLeftMainContainer,
  ResumeUpperContainer,
  RightContainer,
  CarouselSlide,
} from "./Setup.styles";
import RegisterOrganization from "./RegisterOrganization/RegisterOrganization";
const Setup = () => {
  const uid = getUIDFromLocalStorage();
  const firstName = getFirstNameFromLocalStorage();
  return (
    <Container>
      <LeftContainer>
        <ResumeLeftMainContainer>
          <ResumeUpperContainer>
            <Typography variant="h4" gutterBottom>
              Hey {firstName},
            </Typography>
            <Typography variant="h6">
              Congratulations on choosing hrhero! Let's set up your organization
              by filling in some info and creating your first project together.
            </Typography>
          </ResumeUpperContainer>
          <ResumeBottomContainer>
            <Typography variant="h6">
              Fun fact: Setting up an org and project with us speeds up your
              research, POC phase, and MVP testing. MILA can also help you find
              the right people based on your requirements!
            </Typography>
          </ResumeBottomContainer>
        </ResumeLeftMainContainer>
      </LeftContainer>
      <RightContainer>
        <CarouselContainer>
          <CarouselSlide>
            <RegisterOrganization />
          </CarouselSlide>
        </CarouselContainer>
      </RightContainer>
    </Container>
  );
};

export default Setup;
