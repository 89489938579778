import careerPathDiscovery from "../../assets/images/careerPathDiscovery.jpg";
import resumeReview from "../../assets/images/resumeReview.jpg";
import skillGapAnalyzer from "../../assets/images/skillGapAnalyzer.jpg";
export const LandingPagePrompts = [
  {
    id: 0,
    promptTitle: "Career Path Discovery",
    prompt:
      "Share your major and career goals to explore exciting paths and key milestones tailored to you.",
    iconLink: careerPathDiscovery,
  },
  {
    id: 1,
    promptTitle: "Resume Review",
    prompt:
      "Upload your resume for instant, AI-driven feedback and actionable improvement tips.",
    iconLink: resumeReview,
  },
  {
    id: 2,
    promptTitle: "Skill Gap Analyzer",
    prompt:
      "Interested in a specific role? Tell us and we’ll analyze the skills you’ll need to land it.",
    iconLink: skillGapAnalyzer,
  },
];

export const POST_LANDING_PAGE_SETTING_MESSAGE =
  "POST_LANDING_PAGE_SETTING_MESSAGE";
export const POST_LANDING_PAGE_SETTING_MESSAGE_URL =
  "/landingPage/settingFirstMessage";
export const POST_RESUME_EXTRACTOR = "POST_RESUME_EXTRACTOR";
export const POST_RESUME_EXTRACTOR_URL = "/landingPage/resume-extractor";
