import { Tooltip, Typography } from "@mui/material";
const ToolTip = ({ label, children }) => {
  return (
    <Tooltip
      title={<Typography style={{ fontSize: "1rem" }}>{label}</Typography>}
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "rgba(97, 97, 97, 0.9)",
            fontSize: "1rem",
            cursor: "pointer",
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default ToolTip;
