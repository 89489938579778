import { Box, IconButton, Typography, Card } from "@mui/material";
import styled from "styled-components";
import TeamMembers from "./TeamMembers";
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 90vh;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #e0e0e0;
`;

export const TeamMembersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const MemberContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: flex-start;
`;
export const MemberHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
`;
export const MemberHeader = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const MemberIconContainer = styled(Box)`
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconButtonContainer = styled(IconButton)`
  && {
    color: black;
    border: 1px solid grey;
    border-radius: 50%;
    cursor: "pointer";
  }
`;

export const MemberBodyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1.5rem;

  width: 100%;
`;
export const MemberBody = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0rem;
  border-bottom: ${(props) =>
    props.bottomBorder === "false" ? "none" : "1px solid #e0e0e0"};
`;
