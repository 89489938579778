import { Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import DropDown from "../../../../../commonComponents/FormComponents/DropDown";
import TextInput from "../../../../../commonComponents/FormComponents/TextInput";
import {
  Industry,
  NumberOfMembers,
  OrganizationType,
  initialValue,
} from "./RegisterOrganization.constants";
import {
  Body,
  ButtonContainer,
  Container,
  Footer,
  Header,
} from "./RegisterOrganization.styles";
import { useNavigate } from "react-router";
import { validationSchema } from "./RegisterOrganization.validationSchema";

const RegisterOrganization = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <Typography variant="h5" gutterBottom>
          Tell us about your organization!
        </Typography>
        <Typography variant="h6" gutterBottom color="text.secondary">
          We will help you test your POC, MVP, and create your projects.
        </Typography>
      </Header>
      <Body>
        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            navigate("/organization/dashboard");
            console.log(values);
          }}
        >
          {({ isValid, values, dirty }) => {
            return (
              <Form>
                <TextInput
                  label="Organization Name"
                  name="organizationName"
                  color="secondary"
                  required
                  fullWidth
                />
                <TextInput
                  label="Location"
                  name="location"
                  color="secondary"
                  required
                  fullWidth
                />
                <DropDown
                  label="Industry"
                  name="industry"
                  color="secondary"
                  fullWidth
                  required
                  options={Industry}
                />
                {values.industry === "Other" && (
                  <TextInput
                    label="Other"
                    name="otherIndustry"
                    color="secondary"
                    required
                    fullWidth
                  />
                )}

                <DropDown
                  label="Organization Type"
                  name="organizationType"
                  color="secondary"
                  fullWidth
                  required
                  options={OrganizationType}
                />
                <DropDown
                  label="Organization Size"
                  name="numberOfMembers"
                  color="secondary"
                  fullWidth
                  required
                  options={NumberOfMembers}
                />
                <Footer>
                  <ButtonContainer>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!(dirty && isValid)}
                    >
                      Next
                    </Button>
                  </ButtonContainer>
                </Footer>
              </Form>
            );
          }}
        </Formik>
      </Body>
    </Container>
  );
};

export default RegisterOrganization;
