import { Box, Card } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Card)`
  padding: 1rem;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 15%;
`;

export const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 60%;
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 25%;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  button {
    border-radius: 10px;
  }
`;
