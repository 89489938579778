import { Box } from "@mui/material";
import { keyframes, styled } from "styled-components";

export const Container = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.4rem;
`;

export const MessageContainer = styled(Box)`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.isEmpty ? "center" : "flex-start")};
`;

export const InputContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 60%;
  margin: 0.5rem 0rem;
  border-top: 1px solid grey;
  @media (max-width: 426px) {
    width: 80%; 
  }
`;

export const Disclaimer = styled(Box)`
  font-size: 13px;
  color: grey;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

export const AnimatedEllipsis = styled.span`
  animation: ${blink} 1s infinite;
  color: blue;
  font-size: 2.5rem;
`;

export const UserMessageContainer = styled(Box)`
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.2rem;
  border-radius: 10px;
  border: 1px solid #e0dfdc;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
`;

export const UserMessageIcon = styled(Box)`
  font-family: "Visby Bold";
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const UserMessage = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AssistantMessageContainer = styled(Box)`
  width: 60%;
  margin-top: 1rem;
  line-height: 1.2rem;
  border-radius: 10px;
  border: 1px solid #e0dfdc;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
`;
export const AssistantMessageIcon = styled(Box)`
  font-family: "Visby Bold";
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AssistantIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
  border: 1px solid black;
  border-radius: 50%;
`;
export const AssistantMessage = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const CardContainer = styled(Box)`
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Cards = styled(Box)`
  display: flex;
  gap: 10px;
  flex-direction: row; 
  @media (max-width: 768px) {
    flex-direction: column; 
  }

`;
