import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
const ToastNotification = ({ open, snackbarMessage, snackbarSeverity }) => {
  const [openSnackbar, setOpenSnackbar] = useState(open);
  const closeSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ToastNotification;
