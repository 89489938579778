import { Avatar, Badge, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { addUserPriceInformation } from "../../redux/userSlice";
import {
  getFirstNameFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../utils/EmailUtil";
import { trackingNewUserSignUp } from "../../utils/MixPanelUtil";
import { googleAuthentication } from "../../utils/SSO";
import {
  GET_INVITES_COUNT,
  GET_INVITES_COUNT_URL,
  SideBarMenuItems,
  TopNavItems,
} from "./SideBar.constant";
import {
  BottomMenuContainer,
  Container,
  IconStyle,
  MenuContainer,
  MenuItems,
  MenuLabel,
  StyledGoogleButton,
} from "./SideBar.styles";
import Google from "./components/Google/Google";

const SideBar = ({ onLinkClick }) => {
  const uid = getUIDFromLocalStorage();
  const { data: notificationCount } = useApiQuery(GET_INVITES_COUNT, {
    method: API_CONSTANTS.GET,
    url: `${GET_INVITES_COUNT_URL}/${uid}`,
    enabled: uid !== null,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(1);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const userInformation = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const name = getFirstNameFromLocalStorage();

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelectedKey(SideBarMenuItems[path]);
  }, [location]);

  const handleItemClick = (link, key) => {
    setSelectedKey(key);
    if (userInformation) {
      navigate(link);
    } else {
      setOpenSignInDialog(true);
    }
    if (window.innerWidth <= 768) {
      onLinkClick();
    }
  };

  const handleAuthentication = async () => {
    trackingNewUserSignUp("sign-up-button-clicked");
    try {
      const response = await googleAuthentication();
      if (response.data.authType === "register") {
        dispatch(addUserPriceInformation(response.data.pricingPlan));
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
        trackingNewUserSignUp("sign up");
        navigate("/register-info");
      } else {
        dispatch(addUserPriceInformation(response.pricingPlan));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data.token);
        navigate("/home");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const bottomItems = [
    {
      key: "9",
      label: "Settings",
      link: "/settings",
      value: name,
      icon: <Avatar>{name?.charAt(0)?.toUpperCase()}</Avatar>,
    },
  ];
  return (
    <Container>
      <MenuContainer>
        {TopNavItems.map((item) => (
          <MenuItems
            onClick={() => handleItemClick(item.link, item.key)}
            isSelected={selectedKey == item.key}
            key={item.key}
          >
            <IconStyle>
              {item.key === "6" && notificationCount?.pendingCount > 0 ? (
                <Badge
                  badgeContent={notificationCount?.pendingCount}
                  color="error"
                >
                  {item.icon}
                </Badge>
              ) : (
                item.icon
              )}
            </IconStyle>
            <MenuLabel>{item.label}</MenuLabel>
          </MenuItems>
        ))}
      </MenuContainer>
      <BottomMenuContainer>
        {userInformation ? (
          <>
            {bottomItems.map((item) => (
              <div key={item.key}>
                <MenuItems
                  onClick={() => handleItemClick(item.link, item.key)}
                  isSelected={selectedKey == item.key}
                  key={item.key}
                >
                  <IconStyle>{item.icon}</IconStyle>
                  <MenuLabel>{item.value}</MenuLabel>
                </MenuItems>
              </div>
            ))}
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ margin: "1rem 0.9rem" }}>
              Log in to save your conversation history, sync with Google,
              generate cover letters, improve your resume, talk to MILA and view
              jobs.
            </Typography>
            <StyledGoogleButton
              label="Continue with Google"
              type="light"
              onClick={handleAuthentication}
            />
          </>
        )}
      </BottomMenuContainer>
      <Google
        openSignInDialog={openSignInDialog}
        setOpenSignInDialog={setOpenSignInDialog}
        type="sidebar"
      />
    </Container>
  );
};

export default SideBar;
