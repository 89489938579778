import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import ToolTip from "../../../../commonComponents/ToolTip/ToolTip";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import {
  calculateDuration,
  processDescription,
} from "../../../../utils/ProfileUtil";
import {
  CardContainer,
  HeaderContainer,
  DescriptionContainer,
} from "../../Profile.styles";
import EducationDisplay from "../Education/component/EducationDisplay";
const Education = ({ profileInfo, refetch }) => {
  const uid = getUIDFromLocalStorage();
  const [openEducation, setOpenEducation] = useState(false);
  const [infoId, setInfoId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const onSettled = (data) => {
    if (data.data.success) {
      refetch();
      showSnackbar("Successfully updated your profile", "success");
    } else {
      showSnackbar("Failed to update your profile", "error");
    }
  };
  const { mutate: deleteEducation } = useApiQuery("DELETE_EDUCATION_BY_ID", {
    method: API_CONSTANTS.DELETE,
    url: `/profile/delete-experience-education/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });
  return (
    <CardContainer>
      <HeaderContainer>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ marginBottom: "1rem" }}
        >
          Education
        </Typography>
        {uid && uid === profileInfo?._id && (
          <ToolTip label={"Add & Edit with AI"}>
            <IconButton
              size="small"
              onClick={() => {
                setInfoId(null);
                setOpenEducation(true);
              }}
              sx={{
                padding: 0,
                marginBottom: "0.3rem",
                color: "black",
              }}
            >
              <FaPencil style={{ fontSize: "1rem" }} />
            </IconButton>
          </ToolTip>
        )}
      </HeaderContainer>
      {profileInfo?.educations?.length > 0 && (
        <div>
          {profileInfo?.educations?.map((education, index) => {
            return (
              <Box sx={{ marginBottom: "1.1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    fontWeight="bold"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {education?.school}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {(education.fromYear && education.fromMonth) ||
                    (education.toYear && education.toMonth) ? (
                      <Typography variant="subtitle1">
                        {calculateDuration(
                          education.fromMonth,
                          education.fromYear,
                          education.toMonth,
                          education.toYear,
                          education.current
                        )}
                      </Typography>
                    ) : null}
                    {uid && uid === profileInfo?._id && (
                      <>
                        <ToolTip label={"Edit with AI"}>
                          <IconButton
                            onClick={() => {
                              setInfoId(education._id);
                              setOpenEducation(true);
                            }}
                            size="small"
                            sx={{
                              padding: 0,
                              marginBottom: "0.1rem",
                              marginLeft: "0.3rem",
                            }}
                          >
                            <FaPencil style={{ fontSize: "1rem" }} />
                          </IconButton>
                        </ToolTip>
                        <ToolTip label={"Delete"}>
                          <IconButton
                            onClick={() =>
                              deleteEducation({
                                data: { type: "education", id: education._id },
                              })
                            }
                            size="small"
                            sx={{
                              padding: 0,
                              marginBottom: "0.1rem",
                              marginLeft: "0.3rem",
                            }}
                          >
                            <FaRegTrashCan
                              style={{ fontSize: "1rem", color: "red" }}
                            />
                          </IconButton>
                        </ToolTip>
                      </>
                    )}
                  </Box>
                </Box>
                <DescriptionContainer>
                  {processDescription(education, "education")}
                </DescriptionContainer>
              </Box>
            );
          })}
        </div>
      )}
      <EducationDisplay
        openEducation={openEducation}
        OnClose={() => setOpenEducation(false)}
        refreshProfile={refetch}
        infoId={infoId}
        type="education"
      />
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </CardContainer>
  );
};

export default Education;
