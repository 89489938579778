import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GoPlus, GoStack } from "react-icons/go";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoEllipsisHorizontalOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router";
import api from "../../api/api";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import ToastNotification from "../../commonComponents/ToastNotification/ToastNotification";
import { getTimeElapsed } from "../../utils/ChatbotUtil";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import { useTrackPageViewDuration } from "../../utils/MixPanelUtil";
import { CircularProgressContainer } from "../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import { GET_THREADS, GET_THREADS_URL, menuItems } from "./Threads.constants";
import {
  Container,
  FilteredListContainer,
  FilteredThreadContainer,
  HeaderContainer,
  IconButtonContainer,
  LeftHeaderContainer,
  ListOptionContainer,
  ListOptionLeftContainer,
  ListOptionRightContainer,
  NoThreadCollectionContainer,
  NoThreadMessages,
  RightHeaderContainer,
  ThreadCollectionContainer,
  ThreadCollectionHeader,
  ThreadCollectionHeaderContainer,
  ThreadCollectionIconContainer,
  ThreadContainer,
} from "./Threads.styles";
import DeleteAllThreadsConfirmation from "./components/DeleteAllThreadsConfirmation/DeleteAllThreadsConfirmation";
import EditThreadName from "./components/EditThreadName/EditThreadName";
const Threads = () => {
  const [openEditDialogBox, setOpenEditDialogBox] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [openDialogType, setOpenDialogType] = useState("");
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const uid = getUIDFromLocalStorage();
  useTrackPageViewDuration("Chatbot Menu Page");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const {
    data: threadsData,
    isLoading: isThreadsDataLoaded,
    isError: isThreadsDataError,
    refetch,
  } = useApiQuery(GET_THREADS, {
    method: API_CONSTANTS.GET,
    url: `${GET_THREADS_URL}/${uid}`,
    enabled: uid !== null,
  });

  useEffect(() => {
    if (!isThreadsDataLoaded && !isThreadsDataError) {
      setFilteredList(threadsData.data);
    }
  }, [isThreadsDataLoaded, isThreadsDataError, threadsData]);
  useEffect(() => {
    if (!openDialogBox) {
      refetch();
    }
  }, [openDialogBox]);
  useEffect(() => {
    if (searchName === "") {
      setFilteredList(threadsData?.data);
    } else {
      const filteredSearchList = searchName
        ? threadsData?.data.filter((chat) =>
            chat.thread_name.toLowerCase().includes(searchName.toLowerCase())
          )
        : threadsData?.data;
      setFilteredList(filteredSearchList);
    }
  }, [searchName]);
  const handleDeleteAllThreads = (type) => {
    setOpenDialogBox(true);
    setOpenDialogType(type);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await api.delete(
        `/chatting/deleteChat/${selectedChatId}`
      );
      if (response.data.success) {
        setIsLoading(false);
        const newSearchList = threadsData?.data.filter(
          (chat) => chat._id !== selectedChatId
        );
        setFilteredList(newSearchList);
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  const handleOpenEditDialog = () => {
    setOpenEditDialogBox(true);
  };

  const menuItemList = menuItems(handleDelete, handleOpenEditDialog);

  const navigateToChat = (chat) => {
    navigate(`/search/${chat.thread_id}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isThreadsDataLoaded ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <Box>
          <Container>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {menuItemList.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      item.onClick();
                      handleClose();
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ marginRight: "5px", marginTop: "7px" }}>
                        {item.icon}
                      </Box>
                      <Typography variant="inherit">{item.label}</Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Menu>
            <HeaderContainer>
              <LeftHeaderContainer>
                {/* <Typography variant="h1">Threads</Typography> */}
              </LeftHeaderContainer>
              <RightHeaderContainer>
                <TextField
                  label="Search..."
                  id="outlined-size-small"
                  size="small"
                  value={searchName}
                  color="secondary"
                  onChange={(e) => setSearchName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HiMagnifyingGlass />
                      </InputAdornment>
                    ),
                  }}
                />
              </RightHeaderContainer>
            </HeaderContainer>
            <ThreadCollectionContainer>
              <ThreadContainer>
                <ThreadCollectionHeaderContainer>
                  <ThreadCollectionHeader>
                    <GoStack
                      style={{
                        marginRight: "0.2rem",
                        paddingTop: "0.3rem",
                        fontSize: "1.5rem",
                      }}
                    />
                    <Typography variant="h5"style={{ fontSize: "1.5rem" }}>Threads</Typography>
                  </ThreadCollectionHeader>
                  <ThreadCollectionIconContainer>
                    <IconButtonContainer
                      aria-label="more"
                      id="long-button"
                      onClick={() => handleDeleteAllThreads("threads")}
                      disabled={filteredList?.length === 0}
                      style={{ fontSize: "1rem" }}
                    >
                      <MdDeleteOutline
                        style={{ color: "black", fontSize: "16px" }}
                      />
                    </IconButtonContainer>
                    <IconButtonContainer
                      aria-label="add"
                      onClick={() => navigate("/home")}
                    >
                      <GoPlus style={{ color: "black", fontSize: "1rem" }} />
                    </IconButtonContainer>
                    <DeleteAllThreadsConfirmation
                      openDialogBox={openDialogBox}
                      setOpenDialogBox={setOpenDialogBox}
                      HandlingGettingChats={refetch}
                      type={openDialogType}
                    />
                    <EditThreadName
                      openEditDialogBox={openEditDialogBox}
                      setOpenEditDialogBox={setOpenEditDialogBox}
                      HandlingGettingChats={refetch}
                      selectedChatId={selectedChatId}
                    />
                  </ThreadCollectionIconContainer>
                </ThreadCollectionHeaderContainer>
                {filteredList?.length > 0 ? (
                  <Box>
                    {filteredList.map((chat, index) => (
                      <FilteredThreadContainer
                        key={chat._id}
                        bottomBorder={
                          index === filteredList.length - 1 && "false"
                        }
                      >
                        <FilteredListContainer>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigateToChat(chat)}
                          >
                            <Typography variant="subtitle1">
                              {chat.thread_name.length > 50
                                ? chat.thread_name.substring(0, 80) + "..."
                                : chat.thread_name}
                            </Typography>
                          </Box>
                          <ListOptionContainer>
                            <ListOptionLeftContainer>
                              <Typography variant="body2" color="textSecondary">
                                {getTimeElapsed(chat.updatedAt)} ago
                              </Typography>
                            </ListOptionLeftContainer>
                            <ListOptionRightContainer>
                              <IconButtonContainer
                                aria-label="more"
                                id="long-button"
                                onClick={(e) => {
                                  handleClick(e);
                                  setSelectedChatId(chat._id);
                                }}
                              >
                                <IoEllipsisHorizontalOutline
                                  style={{ color: "black", fontSize: "16px" }}
                                />
                              </IconButtonContainer>
                            </ListOptionRightContainer>
                          </ListOptionContainer>
                        </FilteredListContainer>
                      </FilteredThreadContainer>
                    ))}
                  </Box>
                ) : (
                  <NoThreadCollectionContainer>
                    {searchName.length > 0 ? (
                      <NoThreadMessages variant="body1">
                        No threads found.
                      </NoThreadMessages>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => navigate("/home")}
                      >
                        Start a thread
                      </Button>
                    )}
                  </NoThreadCollectionContainer>
                )}
              </ThreadContainer>
            </ThreadCollectionContainer>
          </Container>
          <ToastNotification
            open={openSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarSeverity={snackbarSeverity}
          />
        </Box>
      )}
    </>
  );
};

export default Threads;
