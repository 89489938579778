import PrivacyPolicy from "../commonComponents/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../commonComponents/TermsOfService/TermsOfService";
import NotFoundPage from "../pages/404Page/404Page";
import RegisterInfo from "../pages/Auth/RegisterInfo/RegisterInfo";
import Connections from "../pages/Connections/Connections";
import MyNetwork from "../pages/Connections/component/MyNetwork/MyNetwork";
import CoverLetter from "../pages/CoverLetter/CoverLetter";
import Home from "../pages/Home/Home";
import Search from "../pages/Home/Search";
import Jobs from "../pages/Jobs/Jobs";
import LandingPage from "../pages/LandingPage/LandingPage";
import Organization from "../pages/Organizations/Organizations";
import Setup from "../pages/Organizations/component/Setup/Setup";
import TeamMembers from "../pages/Organizations/component/TeamMembers/TeamMembers";
import Pricing from "../pages/Pricing/Pricing";
import Profile from "../pages/Profile/Profile";
import Projects from "../pages/Projects/Projects";
import Settings from "../pages/Settings/Settings";
import SlugWebsite from "../pages/SlugWebsite/SlugWebsite";
import Threads from "../pages/Threads/Threads";
export const PublicRoutesValues = [{ path: "/", component: <LandingPage /> }];
export const ProtectedRouteValues = [
  { path: "/threads", component: <Threads /> },
  { path: "/profile", component: <Profile /> },
  { path: "/home", component: <Home /> },
  { path: "/search/:threadId", component: <Search /> },
  { path: "/coverLetter", component: <CoverLetter /> },
  { path: "/settings", component: <Settings /> },
  { path: "/jobs", component: <Jobs /> },
  { path: "/people", component: <Connections /> },
  { path: "/myNetwork/:uid", component: <MyNetwork /> },
  { path: "/projects", component: <Projects /> },
];

export const ProtectedRoutesNoLayoutValues = [
  { path: "/pricing", component: <Pricing /> },
  { path: "/register-info", component: <RegisterInfo /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-of-service", component: <TermsOfService /> },
  { path: "/organizationSetup", component: <Setup /> },
];

export const PublicProtectedRouteValues = [
  { path: "/connecthub/:profilename", component: <SlugWebsite /> },
];

export const NoPageFoundRoute = { path: "*", component: <NotFoundPage /> };

export const OrganizationRouteValue = [
  { path: "/organization/dashboard", component: <Organization /> },
  { path: "/organization/teamMembers", component: <TeamMembers /> },
];
