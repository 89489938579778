import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
`;

export const SubContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  height: 100%;
`;

export const StyledTypographyH4 = styled(Typography)``;

export const StyledTypographyH5 = styled(Typography)``;

export const StyledButton = styled(Button)``;
