import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#fed33c",
      dark: "#ecc11d",
      light: "#f9e29b",
      contrastText: "#000000",
    },
    secondary: {
      main: "#007bff",
      light: "#2e91ff",
      dark: "#0154b1",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000000",
    },
    error: {
      main: "#ff2929",
    },
    tertiary: {
      main: "#Af9eff",
    },
  },
  breakpoints: {
    values: {
      xs: 0, // extra-small devices
      sm: 375, // small devices
      md: 768, // medium devices (tablets)
      lg: 1024, // large devices (desktops)
      xl: 1440, // extra-large devices (large desktops)
    },
  },
  typography: {
    fontFamily: '"Visby Medium", sans-serif',
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          fontSize: "1rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-fontWeightBold": {
            fontFamily: '"Visby Bold", sans-serif',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          padding: "1rem",
          borderRadius: "5px",
          boxShadow:
            "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          padding: 0,
          fontSize: "1rem",
        },
      },
    },
  },
});
