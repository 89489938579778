import styled from "styled-components";
import { Box } from "@mui/system";
export const LinkedInNetworkAssistantContainer = styled(Box)`
  padding: 0.9rem;
`;

export const LinkedInProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: show;
  height: 20rem;
`;

export const LinkedInProfileListContainer = styled.ul`
  height: 20rem;
  overflow-y: scroll;
  border: 1px solid #e0dfdc;
`;
