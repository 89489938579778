export const pricingConfig = [
  {
    title: "Explorer",
    totalActions: 100,
    prod_id:"prod_PLBWErKUvRBrnU",
    monthlyCost: "Free",
    features: {
      title: "Full Access to All Features: Dive into the full suite of AI-powered career development tools.",
      features: [
        "Credits: 100 (one-time allocation)",
        "Start exploring and see the immediate impact on your career journey.",
      ],
    },
  },
  {
    title: "Navigator",
    totalActions: 1000,
    prod_id:"prod_PLBWErKUvRBrnU",
    monthlyCost: 12,
    features: {
      title: "Full Access to All Features: Continue your growth with unrestricted use of our comprehensive career tools.",
      features: [
        "Credits: 1,000",
        "Advance your career with precision.",
      ],
    },
  },
  {
    title: "Voyager",
    totalActions: 2000,
    prod_id:"prod_PLBWErKUvRBrnU",
    monthlyCost: 18,
    features: {
      title: "Full Access to All Features: Unleash the full potential of your career with our most extensive support.",
      features: [
        "Credits: 2,000",
        "Ideal for the ambitious professional eager to fast-track their career.",
      ],
    },
  },
];
