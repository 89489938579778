import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import useAPIQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { CircularProgressContainer } from "../../pages/Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import { stringToColor } from "../../utils/ChangeColor";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import { isProfileComplete } from "../../utils/ProfileUtil";
import {
  GET_PROFILE_DATA,
  GET_PROFILE_URL,
  POST_JOBS,
  POST_JOBS_URL,
} from "./Jobs.constant";
import {
  Container,
  FilterContainer,
  HeaderContainer,
  ImageContainer,
  JobListLeftContainer,
  JobListRightContainer,
  JobsCardContainer,
  JobsContainer,
  JobsListContainer,
  LoadMoreJobsContainer,
  SearchJobRoleContainer,
} from "./Jobs.styles";
import SelectedJobs from "./components/SelectedJobs/SelectedJobs";
const Jobs = () => {
  const uid = getUIDFromLocalStorage();
  const [jobsData, setJobsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState({});
  const [profileComplete, setProfileComplete] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState(null);
  const [selectedJobIndex, setSelectedJobIndex] = useState(0);
  const [listOfJobsLength, setListOfJobsLength] = useState(0);
  const { data: profileData, isLoading: isProfileLoaded } = useAPIQuery(
    GET_PROFILE_DATA,
    {
      method: API_CONSTANTS.GET,
      url: `${GET_PROFILE_URL}/${uid}`,
      enabled: uid !== null,
    }
  );
  const onSettled = (data) => {
    setIsLoading(false);
    if (data.error) {
    } else {
      setIsLoading(false);
      setJobsData((prevState) => [
        ...prevState,
        ...data.data.data.jobs_results,
      ]);
    }
  };
  const { mutate: postJobs } = useAPIQuery(POST_JOBS, {
    method: API_CONSTANTS.POST,
    url: `${POST_JOBS_URL}/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });

  useEffect(() => {
    if (jobsData) {
      setSelectedJobs(jobsData[0]);
    }
  }, [jobsData]);
  useEffect(() => {
    const fetchingJobs = () => {
      postJobs({ filterOptions, listOfJobsLength });
    };

    if (!isProfileLoaded) {
      setProfileComplete(isProfileComplete(profileData?.data));
      setFilterOptions({
        jobTitle: profileData?.data?.primaryRole,
        location: profileData?.data?.location,
      });
      fetchingJobs();
    }
  }, [isProfileLoaded]);
  const gettingMoreJobs = (value) => {
    postJobs({ filterOptions, listOfJobsLength: value });
  };
  return (
    <>
      {isLoading ? (
        <>
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        </>
      ) : (
        <>
          <Container>
            <HeaderContainer>
              <Typography variant="h4">Find Your Dream Jobs Here</Typography>
            </HeaderContainer>
            <JobsContainer>
              <FilterContainer>
                <SearchJobRoleContainer>
                  <TextField
                    label="Job Title..."
                    id="outlined-size-small"
                    size="small"
                    value={filterOptions?.jobTitle}
                    color="secondary"
                    onChange={(e) =>
                      setFilterOptions((prevState) => {
                        return { ...prevState, jobTitle: e.target.value };
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiMagnifyingGlass />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Location..."
                    id="outlined-size-small"
                    size="small"
                    value={filterOptions?.location}
                    color="secondary"
                    onChange={(e) =>
                      setFilterOptions((prevState) => {
                        return { ...prevState, location: e.target.value };
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiMagnifyingGlass />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<HiMagnifyingGlass />}
                    onClick={() => {
                      setJobsData([]);
                      gettingMoreJobs(0);
                      setIsLoading(true);
                    }}
                  >
                    Search
                  </Button>
                </SearchJobRoleContainer>
              </FilterContainer>
              {jobsData?.length === 0 ? (
                <LoadMoreJobsContainer>
                  <Typography variant="h5">
                    No jobs found. Add your search criteria and search for a job
                  </Typography>
                </LoadMoreJobsContainer>
              ) : (
                <JobsListContainer>
                  <JobListLeftContainer>
                    {jobsData?.map((job, index) => {
                      return (
                        <JobsCardContainer
                          key={index}
                          selected={selectedJobIndex == index}
                          onClick={() => {
                            setSelectedJobs(job);
                            setSelectedJobIndex(index);
                          }}
                          sx={{
                            backgroundColor:
                              selectedJobIndex === index ? "#c8e1ff" : "#fff",
                          }}
                        >
                          <ImageContainer>
                            {job.thumbnail ? (
                              <img
                                src={job.thumbnail}
                                alt={`${job.title} thumbnail`}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: stringToColor(job.company_name),
                                  width: 48,
                                  height: 48,
                                  border: "1px solid #000",
                                }}
                              >
                                {job.company_name?.charAt(0).toUpperCase()}
                              </Avatar>
                            )}
                          </ImageContainer>
                          <Box>
                            <Box sx={{ flexGrow: 1 }}>
                              <Typography className="company-name">
                                {job?.title.length > 25
                                  ? job.title.substring(0, 25) + "..."
                                  : job?.title}
                              </Typography>
                              <Typography className="company-name">
                                {job?.company_name}
                              </Typography>
                              <Typography>{job?.location}</Typography>
                            </Box>
                          </Box>
                        </JobsCardContainer>
                      );
                    })}
                    <>
                      <LoadMoreJobsContainer>
                        {isLoading ? (
                          <CircularProgressContainer>
                            <CircularProgress />
                          </CircularProgressContainer>
                        ) : (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              setListOfJobsLength(
                                (prevState) => prevState + 10
                              );
                              gettingMoreJobs(listOfJobsLength + 10);
                              setIsLoading(true);
                            }}
                          >
                            Load More
                          </Button>
                        )}
                      </LoadMoreJobsContainer>
                    </>
                  </JobListLeftContainer>
                  <JobListRightContainer>
                    <SelectedJobs
                      uid={uid}
                      selectedJobs={selectedJobs}
                      profileComplete={profileComplete}
                    />
                  </JobListRightContainer>
                </JobsListContainer>
              )}
            </JobsContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default Jobs;
