import { Box, Card } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  margin: 1rem;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0rem;
  border-bottom: 1px solid #e0e0e0;
  @media (max-width: 426px) {
    & > h4 {
      font-size: 1.3rem;
    }
  }
`;

export const ProjectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 100%;
`;

export const FilterOptionContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-height: 5%;
  padding: 1rem;
  @media (max-width: 1025px) {
    width: 95%;
  }
`;

export const SearchContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 426px) {
    gap: 5px;
    flex-direction: column;
  }
`;

export const ProjectCardContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 1rem;
  @media (max-width: 426px) {
    grid-template-columns: 1fr;
  }
`;

export const ProjectCard = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0;
`;

export const ProjectCardHeader = styled(Box)`
  width: 100%;
  height: 50px;
  padding: 0;
  background: radial-gradient(ellipse at top, #e9f3ff, #bdd5f2),
    radial-gradient(ellipse at bottom, #4d9f0c, transparent);
`;

export const ProjectCardName = styled(Box)`
  margin: 10px 0;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ProjectCardDescription = styled(Box)`
  margin: 10px 0;
`;

export const ProjectCardButton = styled(Box)`
  margin-top: auto;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0.5rem 0rem;
  gap: 10px;
`;
