import { BiSolidMessageSquare } from "react-icons/bi";
import { IoSettings, IoPersonSharp } from "react-icons/io5";
import { MdDashboard, MdGroup } from "react-icons/md";
import { FaProjectDiagram } from "react-icons/fa";
export const TopNavItems = [
  {
    key: "1",
    label: "Dashboard",
    link: "/organization/dashboard",
    icon: <MdDashboard style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "2",
    label: "Projects",
    link: "/organization/projects",
    icon: <FaProjectDiagram style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "3",
    label: "Team Members",
    link: "/organization/teamMembers",
    icon: <MdGroup style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "4",
    label: "Messages",
    link: "/organization/messages",
    icon: <BiSolidMessageSquare style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "5",
    label: "Profile",
    link: "/organization/profile",
    icon: <IoPersonSharp style={{ marginRight: "0.1rem" }} />,
  },
  {
    key: "6",
    label: "Org. Profile",
    link: "/organization/companyProfile",
    icon: <IoSettings style={{ marginRight: "0.1rem" }} />,
  },
];

export const OrgSideBarItems = {
  dashboard: 1,
  projects: 2,
  teamMembers: 3,
  messages: 4,
  profile: 5,
  companyProfile: 6,
};
