import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useNavigate } from "react-router";
import logo from "../../assets/images/logo.png";
import SideBar from "../SideBar/SideBar";
import {
  Container,
  Image,
  ImageContainer,
  LeftContainer,
  RightContainer,
} from "./DefaultLayout.styles";
const DefaultLayout = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setShowSideBar(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSideBar = () => setShowSideBar(!showSideBar);

  return (
    <Container>
      {!showSideBar && (
        <AiOutlineMenuUnfold
          style={{
            fontSize: "1.3rem",
            position: "fixed",
            top: "1rem",
            left: "1rem",
            cursor: "pointer",
            zIndex: "30",
          }}
          onClick={() => setShowSideBar(true)}
        />
      )}
      <LeftContainer show={showSideBar}>
        {showSideBar && (
          <Box>
            <ImageContainer>
              <Image src={logo} alt="logo" onClick={() => navigate("/home")} />
              <AiOutlineMenuFold
                style={{
                  fontSize: "1.5rem",
                  paddingTop: "2rem",
                  paddingBottom: "0.5rem",
                  cursor: "pointer",
                  paddingLeft: "7rem",
                }}
                onClick={() => setShowSideBar(false)}
              />
            </ImageContainer>
            <SideBar onLinkClick={toggleSideBar} />
          </Box>
        )}
      </LeftContainer>
      <RightContainer show={showSideBar}>{children}</RightContainer>
    </Container>
  );
};

export default DefaultLayout;
