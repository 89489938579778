import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ButtonContainer,
  IncompleteMessageContainer,
  JobBodyContainer,
  OriginalJobDescriptionContainer,
  TextArea,
} from "../AIActions/AIActions.styles";
const FitCheck = ({
  open,
  setOpenAIActions,
  profileIncomplete,
  setMessage,
  handleNewChat,
  sendPrompt,
  chatType,
}) => {
  const [jobDescription, setJobDescription] = useState("");
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const navigate = useNavigate();
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const handleSendingAiPrompt = () => {
    if (chatType !== "newChat") {
      sendPrompt(jobDescription, "fitCheck");
      setJobDescription("");
      setOpenAIActions(false);
    } else {
      setMessage(jobDescription);
      setJobDescription("");
      setOpenAIActions(false);
      handleNewChat(jobDescription, "fitCheck");
    }
  };
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ flex: 1 }}>
            FitCheck
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAIActions(false);
            }}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
              cursor: "pointer",
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <>
            {profileIncomplete ? (
              <JobBodyContainer>
                <IncompleteMessageContainer>
                  Oh, it appears your profile's a bit on the shy side, hiding
                  some details. Completing it is like giving me a pair of
                  spectacles – the clearer I can see, the better accurately I
                  can give you a score! Shall we fill in the blanks together?{" "}
                  <a href="/profile/personal-profile">Fill Profile</a>
                </IncompleteMessageContainer>
              </JobBodyContainer>
            ) : (
              <div>
                <OriginalJobDescriptionContainer>
                  <Typography variant="body1">
                    Provide a Job Description
                  </Typography>
                  <div>
                    <TextArea
                      reduceSize={true}
                      heightIncrease={true}
                      value={jobDescription}
                      onChange={(e) => setJobDescription(e.target.value)}
                    />
                  </div>
                </OriginalJobDescriptionContainer>
                <ButtonContainer>
                  {pricingPlan?.price?.actionsIncluded <= 3 ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/pricing")}
                    >
                      Upgrade
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        disabled={!jobDescription}
                        onClick={handleSendingAiPrompt}
                      >
                        Analyze
                      </Button>
                    </>
                  )}

                  <Button
                    variant="contained"
                    onClick={() => setOpenAIActions(false)}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
              </div>
            )}
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FitCheck;
