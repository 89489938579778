import { Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
  margin: 1rem 0rem;
  text-align: center;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
  margin-bottom: 1rem;
`;

export const ProgressBarFill = styled.div`
  height: 13px;
  background-color: #fed33c;
`;

export const ProgressText = styled.span`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
`;
