import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import api from "../../../../api/api";
const DeleteAllThreadsConfirmation = ({
  openDialogBox,
  setOpenDialogBox,
  type,
}) => {
  const uid = getUIDFromLocalStorage();
  const handleDeletingAllThreads = async () => {
    try {
      await api.delete(`/chatting/deleteAllThreads/${uid}`);
      setOpenDialogBox(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };
  return (
    <Dialog
      open={openDialogBox}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          marginBottom: "1rem",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {type === "threads"
            ? "Are you sure you want to delete all threads?"
            : "Are you sure you want to delete all collections?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Never mind
        </Button>
        <Button
          onClick={handleDeletingAllThreads}
          autoFocus
          variant="outlined"
          color="error"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAllThreadsConfirmation;
