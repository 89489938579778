import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute/PrivateRoute.js";
import ProtectedRouteNoLayout from "./routes/ProtectedRouteNoLayout/ProtectedRouteNoLayout.js";
import PublicPrivateRoute from "./routes/PublicPrivateRoute/PublicPrivateRoute";
import PublicRoute from "./routes/PublicRoute/PublicRoute";
import OrganizationRoute from "./routes/OrganizationRoute/OrganizationRoute.js";
import {
  NoPageFoundRoute,
  ProtectedRouteValues,
  ProtectedRoutesNoLayoutValues,
  PublicProtectedRouteValues,
  PublicRoutesValues,
  OrganizationRouteValue,
} from "./routes/route.js";
const App = () => {
  return (
    <>
      {/* <TawkTo />  */}
      <Routes>
        {PublicRoutesValues.map((route) => {
          return (
            <>
              <Route
                path={route.path}
                element={<PublicRoute>{route.component}</PublicRoute>}
              />
            </>
          );
        })}
        {ProtectedRouteValues.map((route) => {
          return (
            <>
              <Route
                path={route.path}
                element={<PrivateRoute>{route.component}</PrivateRoute>}
              />
            </>
          );
        })}
        {ProtectedRoutesNoLayoutValues.map((route) => {
          return (
            <>
              <Route
                path={route.path}
                element={
                  <ProtectedRouteNoLayout>
                    {route.component}
                  </ProtectedRouteNoLayout>
                }
              />
            </>
          );
        })}
        {PublicProtectedRouteValues.map((route) => {
          return (
            <>
              <Route
                path={route.path}
                element={
                  <PublicPrivateRoute>{route.component}</PublicPrivateRoute>
                }
              />
            </>
          );
        })}
        {OrganizationRouteValue.map((route) => {
          return (
            <>
              <Route
                path={route.path}
                element={
                  <OrganizationRoute>{route.component}</OrganizationRoute>
                }
              />
            </>
          );
        })}
        <Route
          path={NoPageFoundRoute.path}
          element={NoPageFoundRoute.component}
        />
        ;
      </Routes>
    </>
  );
};

export default App;
