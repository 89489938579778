import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import api from "../../../../api/api.js";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil.js";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification.js";
import {
  ButtonContainer,
  DragDropBox,
  HiddenInput,
  UploadResumeBody,
  UploadResumeFooter,
  UploadResumeHeader,
  UploadingResume,
} from "../RegisterInfo.styles";

const AddingResume = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [fileSelected, setFileSelected] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const closeSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const uid = getUIDFromLocalStorage();

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileSelected(true);
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
  };

  const handleUpload = async (selectedFile) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    setIsLoading(true);
    try {
      const response = await api.post(
        `/register-info/adding-resume/${uid}`,
        formData
      );
      if (response.data.success === true) {
        const queryText =
          response.data.message +
          " Optimize and provide me a resume that will pass ATS and also always provide me an analysis on it by giving it a score.";
        navigate(`/search/${response.data.thread.thread_id}`, {
          state: { startMessage: queryText, actionType: "general" },
        });
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar(
        "We had some issue with your resume. Please try again.",
        "error"
      );
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      setFileSelected(true);
      setFileName(selectedFile.name);
      handleUpload(selectedFile);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <UploadingResume>
        <UploadResumeHeader>
          <Typography variant="h4">Build your profile!</Typography>
          <Typography variant="h6" gutterBottom color="text.secondary">
            We will help you stand out to recruiters and hiring teams.
          </Typography>
        </UploadResumeHeader>
        <UploadResumeBody>
          <DragDropBox
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById("fileInput").click()}
          >
            <Typography variant="body1" color="text.secondary">
              Drag & Drop your resume here, or click to select a file
            </Typography>
            <HiddenInput
              id="fileInput"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            />
            {fileSelected && (
              <Typography
                variant="subtitle1"
                color="text.primary"
                sx={{ marginTop: "1rem" }}
              >
                Selected file: {fileName}
              </Typography>
            )}
          </DragDropBox>
          <Typography variant="subtitle1" color="text.secondary">
            Supported file types: PDF
          </Typography>
        </UploadResumeBody>
        <UploadResumeFooter>
          <ButtonContainer>
            <Button
              variant="contained"
              disabled={!fileSelected}
              onClick={() => handleUpload(file)}
            >
              Optimize and fill profile
            </Button>
            <Button variant="contained" onClick={() => navigate("/home")}>
              I'll do it manually
            </Button>
          </ButtonContainer>
        </UploadResumeFooter>
        <ToastNotification
          open={openSnackbar}
          snackbarMessage={snackbarMessage}
          snackbarSeverity={snackbarSeverity}
        />
      </UploadingResume>
    </>
  );
};

export default AddingResume;
