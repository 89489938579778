export const jobTypeConfig = [
  "Full-time",
  "Part-time",
  "Contract",
  "Internship",
];
export const freelanceConfig = ["Yes", "No"];
export const jobSearchConfig = [
  "Not looking right now",
  "Ready for interviews",
];
export const profileConfigName = {
  Bio: "bio",
  Achievements: "achievements",
  Creator: "creatorBio",
  Freelancer: "freelancerBio",
};
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const freelanceProfileConfig = [
  "Available",
  "Unavailable",
  "Currently Engaged",
  "On a Break",
];
export const creatorProfileConfig = [
  "Ready to collaborate",
  "Not right now, but will be ready in the future",
  "Not Interested",
];

export const socialMediaHandleConfigs = [
  "YouTube",
  "TikTok",
  "Instagram",
  "X",
  "LinkedIn",
  "Facebook",
  "Twitch",
  "Pinterest",
  "SubStack",
  "Medium",
  "Spotify",
  "Apple Podcast",
];
