import DefaultLayout from "../../commonComponents/DefaultLayout/DefaultLayout";
const PublicRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    window.location.href = "/profile/preview";
  } else {
    return <DefaultLayout>{children}</DefaultLayout>;
  }
};

export default PublicRoute;
