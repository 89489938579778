import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { trackingNewUserSignUp } from "../../../../utils/MixPanelUtil";
import GoogleButton from "react-google-button";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { addUserPriceInformation } from "../../../../redux/userSlice";
import { googleAuthentication } from "../../../../utils/SSO";
import { Container } from "./Google.styles";

const Google = ({ openSignInDialog, setOpenSignInDialog, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAuthentication = async () => {
    trackingNewUserSignUp("sign-up-button-clicked");
    try {
      const response = await googleAuthentication();
      if (response.data.authType === "register") {
        dispatch(addUserPriceInformation(response.data.pricingPlan));
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
        trackingNewUserSignUp("sign up");
        navigate("/register-info");
      } else {
        dispatch(addUserPriceInformation(response.pricingPlan));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data.token);
        navigate("/home");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Dialog
      open={openSignInDialog}
      onClose={() => setOpenSignInDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenSignInDialog(false)}
          sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            marginBottom: "0.2rem",
            fontWeight: 100,
          }}
          color="secondary"
        >
          Welcome to hrhero
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {type === "landingPage"
            ? "Log in to save your chat history with MILA."
            : "Sign in or sign up with Google"}
        </Typography>
        <Container>
          <GoogleButton
            label="Continue with Google"
            type="dark"
            onClick={handleAuthentication}
          />
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default Google;
