import React, { useState, useEffect } from "react";
import {
  stripePayment,
  stripe_prod_payment,
} from "./../../assets/StripeConfigs/stripeConfig";
import { pricingConfig } from "../../assets/pricingConfigs/pricingConfigs";
import { getEmailFromLocalStorage } from "../../utils/EmailUtil";
import { Button, Snackbar, Alert } from "@mui/material";
import {
  Container,
  PricingHeaderContainer,
  Column,
  PriceText,
  MostPopularTag,
} from "./Pricing.styles";
import api from "../../api/api";
import { useNavigate } from "react-router";
import NavBar from "./../../commonComponents/NavBar/NavBar";
import { EnvironmentSelectionForMixPanel } from "../../utils/EnvUtil";
const Pricing = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [pricingPaymentMenu, setPricingPaymentMenu] = useState([]);
  const env = EnvironmentSelectionForMixPanel();
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const closeSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  useEffect(() => {
    if (env === "production") {
      setPricingPaymentMenu(stripe_prod_payment);
    } else {
      setPricingPaymentMenu(stripePayment);
    }
  }, []);
  const email = getEmailFromLocalStorage();
  const navigate = useNavigate();
  const handlingPaymentOptions = async (value) => {
    try {
      const response = await api.post(
        `/payment/create-checkout-session/${email}`,
        {
          value,
        }
      );
      if (response.data.success) {
        window.location.href = response.data.url;
      } else {
        showSnackbar("You already have an active subscription.", "error");
      }
    } catch (error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    }
  };
  return (
    <>
      <NavBar pricingPage={false} />
      <Container>
        <PricingHeaderContainer>
          {pricingConfig.map((pricing, index) => {
            return (
              <Column key={index} isVoyager={pricing.title !== "Voyager"}>
                {pricing.title === "Voyager" && (
                  <MostPopularTag>Most Popular</MostPopularTag>
                )}
                <h2>{pricing.title} </h2>
                <p>{pricing.features.title}</p>
                <PriceText pricingValue={pricing.title === "Explorer"}>
                  {pricing.title !== "Explorer" && "$"}
                  {pricing.monthlyCost}{" "}
                  {pricing.title !== "Explorer" && <span>per month</span>}
                </PriceText>
                {pricing.title === "Explorer" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/home")}
                  >
                    Try it for free
                  </Button>
                )}
                {pricing.title !== "Explorer" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handlingPaymentOptions(
                        pricing.title === "Navigator"
                          ? pricingPaymentMenu[0].price_data.price_id
                          : pricingPaymentMenu[1].price_data.price_id
                      )
                    }
                  >
                    Subscribe
                  </Button>
                )}
                <p>This includes:</p>
                <ul>
                  {pricing.features.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              </Column>
            );
          })}
        </PricingHeaderContainer>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default Pricing;
