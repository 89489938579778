import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useApiQuery, { API_CONSTANTS } from "../../../../axiosQuery/api";
import ToastNotification from "../../../../commonComponents/ToastNotification/ToastNotification";
import { getUIDFromLocalStorage } from "../../../../utils/EmailUtil";
import { CircularProgressContainer } from "../../../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style";
import {
  POST_ROLE_LOCATION,
  POST_ROLE_LOCATION_URL,
} from "../RegisterInfo.constants";
import {
  ButtonContainer,
  RoleContainer,
  UploadResumeBody,
  UploadResumeFooter,
  UploadResumeHeader,
} from "../RegisterInfo.styles";
const RoleLocation = ({ NextSlide }) => {
  const [roleLocation, setRoleLocation] = useState({
    primaryRole: "",
    location: "",
    isStudent: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const uid = getUIDFromLocalStorage();
  const onSettled = (data) => {
    if (data.error) {
      showSnackbar("Please try again", "error");
    } else {
      NextSlide();
    }
  };
  const { mutate: postRoleLocation } = useApiQuery(POST_ROLE_LOCATION, {
    method: API_CONSTANTS.POST,
    url: `${POST_ROLE_LOCATION_URL}/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });

  return (
    <>
      {isLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <RoleContainer>
          <UploadResumeHeader>
            <Typography variant="h5" gutterBottom>
              Tell us about you!
            </Typography>
            <Typography variant="h6" gutterBottom color="text.secondary">
              We will help you find the right jobs, skills to learn and people
              to network.
            </Typography>
          </UploadResumeHeader>
          <UploadResumeBody>
            <TextField
              color="secondary"
              label="What is your primary role?"
              type="text"
              required
              value={roleLocation.primaryRole}
              onChange={(e) =>
                setRoleLocation({
                  ...roleLocation,
                  primaryRole: e.target.value,
                })
              }
              sx={{ width: "100%", marginBottom: 2 }}
            />
            <TextField
              color="secondary"
              label="Where are you located?"
              type="text"
              required
              value={roleLocation.location}
              onChange={(e) =>
                setRoleLocation({
                  ...roleLocation,
                  location: e.target.value,
                })
              }
              sx={{ width: "100%", marginBottom: 2 }}
            />
            <FormControl
              fullWidth
              sx={{ width: "100%", marginBottom: 2 }}
              color="secondary"
            >
              <InputLabel id="student-select-label">
                Are you currently a student?
              </InputLabel>
              <Select
                labelId="student-select-label"
                id="student-select"
                value={roleLocation.isStudent}
                label="Are you currently a student?"
                onChange={(e) =>
                  setRoleLocation({
                    ...roleLocation,
                    isStudent: e.target.value,
                  })
                }
                required
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </UploadResumeBody>
          <UploadResumeFooter>
            <ButtonContainer>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  roleLocation.location === "" ||
                  roleLocation.primaryRole === "" ||
                  roleLocation.isStudent === ""
                }
                onClick={() => postRoleLocation({ roleLocation })}
              >
                Next
              </Button>
            </ButtonContainer>
          </UploadResumeFooter>
          <ToastNotification
            open={openSnackbar}
            snackbarMessage={snackbarMessage}
            snackbarSeverity={snackbarSeverity}
          />
        </RoleContainer>
      )}
    </>
  );
};

export default RoleLocation;
