import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import useApiQuery, { API_CONSTANTS } from "../../../../../../axiosQuery/api";
import ToastNotification from "../../../../../../commonComponents/ToastNotification/ToastNotification";
import { getUIDFromLocalStorage } from "../../../../../../utils/EmailUtil";
import {
  POST_PROFILE_DETAILS,
  POST_PROFILE_DETAILS_URL,
} from "../../../../Profile.constants";
const HomeAbout = ({ profileInfo, open, setOpenHomeAbout, refreshProfile }) => {
  const uid = getUIDFromLocalStorage();
  const [profileData, setProfileData] = useState(profileInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const onSettled = (data) => {
    setIsLoading(false);
    if (data.error) {
      showSnackbar(
        "Looks like something went wrong. Please try again later",
        "error"
      );
    } else {
      setOpenHomeAbout(false);
      refreshProfile();
    }
  };
  const { mutate: updatedProfileData } = useApiQuery(POST_PROFILE_DETAILS, {
    method: API_CONSTANTS.POST,
    url: `${POST_PROFILE_DETAILS_URL}/${uid}`,
    enabled: false,
    onSettled: onSettled,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpenHomeAbout(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ flex: 1 }}>
            Profile Details
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenHomeAbout(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="First Name"
                  type="text"
                  required
                  fullWidth
                  value={profileData?.firstName}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      firstName: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="Last Name"
                  type="text"
                  required
                  fullWidth
                  value={profileData?.lastName}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      lastName: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="Where are you based?"
                  type="text"
                  required
                  fullWidth
                  value={profileData?.location}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      location: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="What is your primary role?"
                  placeholder="e.g., Software Engineer"
                  type="text"
                  required
                  fullWidth
                  value={profileData?.primaryRole}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      primaryRole: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="hrhero"
                  type="text"
                  fullWidth
                  value={profileData?.socialMedia?.hrhero}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="Github"
                  type="text"
                  fullWidth
                  value={profileData?.socialMedia?.github}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      socialMedia: {
                        ...prevState.socialMedia,
                        github: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="LinkedIn"
                  type="text"
                  fullWidth
                  value={profileData?.socialMedia?.linkedin}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      socialMedia: {
                        ...prevState.socialMedia,
                        linkedin: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="secondary"
                  label="Twitter/X"
                  type="text"
                  fullWidth
                  value={profileData?.socialMedia?.twitter}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      socialMedia: {
                        ...prevState.socialMedia,
                        twitter: e.target.value,
                      },
                    }))
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setIsLoading(true);
              updatedProfileData({ profileData });
            }}
            disabled={isLoading}
          >
            Save
          </Button>
          <Button onClick={() => setOpenHomeAbout(false)} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastNotification
        open={openSnackbar}
        snackbarMessage={snackbarMessage}
        snackbarSeverity={snackbarSeverity}
      />
    </>
  );
};

export default HomeAbout;
