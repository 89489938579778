import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useNavigate } from "react-router";
import useApiQuery, { API_CONSTANTS } from "../../axiosQuery/api";
import { getUIDFromLocalStorage } from "../../utils/EmailUtil";
import { stringToColor } from "../../utils/ChangeColor";
import {
  GET_POTENTIAL_CONNECTIONS,
  GET_POTENTIAL_CONNECTIONS_URL,
} from "./Connections.constant";
import {
  Container,
  InvitationBodyContainer,
  InvitationContainer,
  InvitationHeaderContainer,
  PeopleBodyContainer,
  PeopleHeaderContainer,
  PeopleListContainer,
  ProfileCardContainer,
  ImageContainer,
} from "./Connections.styles";
const Connections = () => {
  const uid = getUIDFromLocalStorage();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchProfile, setSearchProfile] = useState("");
  const [invitationList, setInvitationList] = useState([]);
  const limit = 10;
  const skip = (page - 1) * limit;
  const {
    data: potentialContacts,
    isLoading: isPotentialContactsLoading,
    isError: isPotentialContactsError,
    refetch,
  } = useApiQuery(GET_POTENTIAL_CONNECTIONS, {
    method: API_CONSTANTS.GET,
    url: `${GET_POTENTIAL_CONNECTIONS_URL}/${uid}`,
    enabled: false,
    params: { limit, skip },
  });
  useEffect(() => {
    refetch();
  }, [page]);
  return (
    <Container>
      <InvitationContainer>
        <InvitationHeaderContainer>
          <Box>
            <Typography variant="body1">No pending invitation </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate(`/myNetwork/${uid}`)}
          >
            Manage network
          </Button>
        </InvitationHeaderContainer>
        {invitationList.length > 0 && (
          <InvitationBodyContainer>Body</InvitationBodyContainer>
        )}
      </InvitationContainer>
      <PeopleListContainer>
        <PeopleHeaderContainer>
          <Typography variant="h6">
            People you would like to network{" "}
          </Typography>
          <TextField
            label="Search profiles..."
            id="outlined-size-small"
            size="small"
            value={searchProfile}
            color="secondary"
            onChange={(e) => setSearchProfile(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HiMagnifyingGlass />
                </InputAdornment>
              ),
            }}
          />
        </PeopleHeaderContainer>
        <PeopleBodyContainer>
          {isPotentialContactsLoading && <Box>Loading...</Box>}
          {isPotentialContactsError && <Box>Error loading data</Box>}
          {potentialContacts &&
            searchProfile.length === 0 &&
            potentialContacts.data.map((contact) => (
              <ProfileCardContainer key={contact._id}>
                <ImageContainer>
                  {contact?.profileImage ? (
                    <img
                      src={contact.profileImage}
                      alt={`${contact.title} thumbnail`}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: stringToColor(contact?.name),
                        width: 96,
                        height: 96,
                        border: "1px solid #000",
                      }}
                    >
                      {contact?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </ImageContainer>
                <Typography>
                  {contact?.firstName} {contact?.lastName}
                </Typography>
                <Typography color="text.secondary">
                  {contact?.primaryRole}
                </Typography>
                <Typography color="text.secondary">
                  {contact?.location}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/connecthub/${contact?.slugName}`)}
                >
                  View Profile
                </Button>
              </ProfileCardContainer>
            ))}
        </PeopleBodyContainer>
      </PeopleListContainer>
    </Container>
  );
};

export default Connections;
