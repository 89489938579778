import styled from "styled-components";
import { Card } from "@mui/material";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:80vh;
  overflow-y: auto;
  margin: 0rem 10rem;
  @media(max-width: 1024px){
    margin:0rem 1rem;
    justify-content:space-between
  }
`;

export const PricingHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  gap:2rem;
  @media(max-width: 1024px){
    display:flex;
    flex-direction:column;
   
  }
`;

export const Column = styled(Card)`
  flex: 1;
  padding: 1rem;
  border-radius:10px;
  border: 1px solid #ddd;
  h2 {
    font-weight: 700;
    font-size: 1.8rem;
    max-height:2rem;
    margin-top:${props => props.isVoyager ? '3.5rem' : '1.8rem'};
  }
  h1 {
    font-size: 2.2rem;
  }
  ul {
    padding-left: 1rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  Button {
    width: 100%;
  }

`;
export const ValueTable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  h1 {
    margin-top: 1rem;
  }
  p:first-child {
    margin-bottom: 0rem;
    font-size: 1rem;
  }
  p:last-child {
    margin-top: 0rem;
  }
`;

export const PriceText = styled.h1`
font-weight: bold;
color: black;
margin-bottom:0rem;
span {
  font-weight: normal;
  color: #b3b3b3;
  font-size:01rem;
}
@media (max-width: 1024px) {
min-height:${props => props.pricingValue && '5rem'};
}
`;

export const MostPopularTag = styled.div`
  background-color: #ffde92; 
  color:  #983705;;
  padding:0rem 0.2rem;
  border-radius: 5px;
  font-size: 1rem;
  display: inline-block; 
`;