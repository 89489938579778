import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApiQuery, { API_CONSTANTS } from "../../../axiosQuery/api";
import {
  getFirstNameFromLocalStorage,
  getUIDFromLocalStorage,
} from "../../../utils/EmailUtil";
import {
  POST_ADDING_NEW_THREAD,
  POST_ADDING_NEW_THREAD_URL,
} from "./RegisterInfo.constants";
import {
  CarouselContainer,
  CarouselSlide,
  Container,
  LeftContainer,
  ResumeBottomContainer,
  ResumeLeftMainContainer,
  ResumeUpperContainer,
  RightContainer,
} from "./RegisterInfo.styles";
import AddingResume from "./components/AddingResume";
import RoleLocation from "./components/RoleLocation";
const RegisterInfo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const landingPageChatLog = useSelector(
    (state) => state.landingPage.chatLogInformation
  );
  const uid = getUIDFromLocalStorage();
  const firstName = getFirstNameFromLocalStorage();
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const onSettled = (data) => {};
  const { mutate: createAndAddingChatLog } = useApiQuery(
    POST_ADDING_NEW_THREAD,
    {
      method: API_CONSTANTS.POST,
      url: `${POST_ADDING_NEW_THREAD_URL}/${uid}`,
      enabled: false,
      onSettled: onSettled,
    }
  );

  useEffect(() => {
    if (landingPageChatLog.length > 0) {
      createAndAddingChatLog({ chatLog: landingPageChatLog });
    }
  }, []);
  const componentVariables = [
    <RoleLocation NextSlide={nextSlide} />,
    <AddingResume />,
  ];
  return (
    <Container>
      <LeftContainer>
        {currentIndex === 0 ? (
          <ResumeLeftMainContainer>
            <ResumeUpperContainer>
              <Typography variant="h4" gutterBottom>
                Hey {firstName},
              </Typography>
              <Typography variant="h6">
                Welcome to hrhero! Before we get started on advancing your
                career, let's gather some information about you.
              </Typography>
            </ResumeUpperContainer>
            <ResumeBottomContainer>
              <Typography variant="h6">
                Fun fact: Sharing a bit about your career journey helps MILA
                find the best jobs, offer tailored career advice, connect you
                with the right people, and suggest skills to learn!
              </Typography>
            </ResumeBottomContainer>
          </ResumeLeftMainContainer>
        ) : (
          <>
            <ResumeLeftMainContainer>
              <ResumeUpperContainer>
                <Typography variant="h4" gutterBottom>
                  Upload your resume
                </Typography>
                <Typography variant="h6">
                  Save time and let us complete your profile with your resume.
                </Typography>
              </ResumeUpperContainer>
              <ResumeBottomContainer>
                <Typography variant="h6">
                  Fun fact: By uploading your resume, we not only complete your
                  profile but also optimize it to create the best ATS version.
                  Once filled in, MILA will provide personalized career advice
                  to help you find jobs, learn new skills, and network with the
                  right people.
                </Typography>
              </ResumeBottomContainer>
            </ResumeLeftMainContainer>
          </>
        )}
      </LeftContainer>
      <RightContainer>
        <CarouselContainer>
          <CarouselSlide>{componentVariables[currentIndex]}</CarouselSlide>
        </CarouselContainer>
      </RightContainer>
    </Container>
  );
};

export default RegisterInfo;
