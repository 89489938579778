export const tips = [
  {
    title:
      "Choose a job you love, and you will never have to work a day in your life.",
    subTitle: "Confucius",
    pick: "quote",
  },
  {
    title: "Opportunities don't happen. You create them.",
    subTitle: "Chris Grosser",
    pick: "quote",
  },
  {
    title: "The only way to do great work is to love what you do.",
    subTitle: "Steve Jobs",
    pick: "quote",
  },
  {
    title:
      "The future belongs to those who believe in the beauty of their dreams.",
    subTitle: "Eleanor Roosevelt",
    pick: "quote",
  },
  {
    title: "Don't watch the clock; do what it does. Keep going.",
    subTitle: "Sam Levenson",
    pick: "quote",
  },
  {
    title: "I find that the harder I work, the more luck I seem to have.",
    subTitle: "Thomas Jefferson",
    pick: "quote",
  },
  {
    title:
      "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
    subTitle: "Albert Schweitzer",
    pick: "quote",
  },
  {
    title: "The best way to predict the future is to create it.",
    subTitle: "Peter Drucker",
    pick: "quote",
  },
  {
    title:
      "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.",
    subTitle: "Steve Jobs",
    pick: "quote",
  },
  {
    title: "You miss 100% of the shots you don't take.",
    subTitle: "Wayne Gretzky",
    pick: "quote",
  },
  {
    title: "Network Continuously:",
    subTitle:
      "Networking is not about just connecting people. It's about connecting people with people, people with ideas, and people with opportunities.",
    pick: "noquote",
  },
  {
    title: "Learn Constantly:",
    subTitle:
      "Invest time in upskilling yourself. The most valuable asset you have is your mind and what you put into it.",
    pick: "noquote",
  },
  {
    title: "Be Adaptable:",
    subTitle:
      "Embrace change and be willing to adapt. The most successful professionals are those who can adapt to the evolving job market.",
    pick: "noquote",
  },
  {
    title: "Set Clear Goals:",
    subTitle:
      "Define your career goals clearly and revisit them often. Knowing where you're headed is the first step to getting there.",
    pick: "noquote",
  },
  {
    title: "Showcase Your Skills:",
    subTitle:
      "Your resume is your story. Make sure it highlights your achievements and the unique skills you bring to the table.",
    pick: "noquote",
  },
  {
    title: "Prepare Thoroughly:",
    subTitle:
      "For every job interview, preparation is key. Research the company, understand the role, and be ready to explain how you can contribute.",
    pick: "noquote",
  },
  {
    title: "Seek Feedback:",
    subTitle:
      "Never underestimate the power of feedback. It is the breakfast of champions. Seek it actively, and use it to improve.",
    pick: "noquote",
  },
  {
    title: "Build Your Brand:",
    subTitle:
      "Think of yourself as a brand. What do you want people to associate with you when they think of your name?",
    pick: "noquote",
  },
  {
    title: "Focus on Solutions:",
    subTitle:
      "Always focus on solutions, not problems. Be the person who solves issues and adds value to every project.",
    pick: "noquote",
  },
  {
    title: "Maintain Work-Life Balance:",
    subTitle:
      "Remember to balance work with personal time. Work is just one part of your life, not all of it.",
    pick: "noquote",
  },
];
export const improvingResumeFacts = [
  "Hang tight! We're carefully crafting your profile to maximize your career potential.",
  "MILA, your AI Career CoPilot, is warming up to guide you on your career journey.",
  "Get ready for personalized guidance tailored to your unique skills and experiences.",
  "Imagine the possibilities — MILA is about to open a world of education and mentorship opportunities.",
  "Management made easy — our tools are designed to streamline your career planning and job search.",
  "Mentorship magic in the making — connect with industry mentors who can help elevate your career.",
  "Acing interviews just got easier with practice questions and tips from MILA.",
  "Skill gaps? We've got you. MILA is on it, preparing custom skill development plans.",
  "Unlocking your network potential — MILA will help you find and forge valuable connections.",
  "Just a little longer — your tailored dashboard with AI-driven insights is almost ready.",
  "Your personalized career command center, powered by MILA and hrhero.ai, is just moments away.",
];
