import styled from "styled-components";
import Button from "@mui/material/Button";
import { Card, TextField } from "@mui/material";
import { AiOutlineLoading } from "react-icons/ai";
export const Container = styled.div`
  border: 1px solid #e0dfdc;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: columns;
  width: 100%;
  border-bottom: ${(props) => props.showBottomBorder && "1px solid #e0dfdc"};
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

export const LeftSideRow = styled.div`
  width: 30%;
  padding-right: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const RightSideRow = styled.div`
  width: 70%;
  form {
    div {
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

export const Input = styled.input`
  border: 1px solid #e0dfdc;
  border-radius: 10px;
  background-color: white;
  padding: 0.7rem;
  margin-bottom: 0.5rem;
  width: ${(props) => props.reduceSize && "10rem"};
`;

export const InputNumber = styled.input`
  width: 8rem;
  border: 1px solid #e0dfdc;
  border-radius: 10px;
  padding: 0.7rem;
`;

export const TextArea = styled.textarea`
  resize: none;
  height: 10rem;
  border: 1px solid #e0dfdc;
  border-radius: 10px;
  padding: 0.5rem;
  font-family: "Visby Regular";
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 100%;
  margin-bottom: 1rem;
`;
export const SaveButton = styled(Button)`
  && {
    width: 6rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px !important;
    font-family: "Visby Regular";
    margin-right: 0.5rem;
  }
`;

export const SubLabel = styled.p`
  font-size: 12px;
  color: ${(props) => (props.redColor ? "red" : "black")};
`;

export const DropdownInfo = styled.p``;

export const CharacterCount = styled.p`
  font-size: 0.9rem;
  margin-top: 0.1rem;
`;

export const ExperienceShowContainer = styled(Card)`
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
`;
export const ExperienceLeftContainer = styled.div`
  width: 15%;
  padding: 1rem;
  @media (max-width: 426px) {
    display: none;
  }
`;

export const ExperienceRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  h3 {
    margin-top: 1.1rem;
    font-size: 1rem;
    font-family: "Visby bold";
  }
`;

export const ProfileDescription = styled.div`
  font-size: 1.1rem;
`;
export const ExperienceButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0rem;

  button:first-child {
    border: none;
    width: 10rem;
    height: 2rem;
    padding: 0.1rem;
    cursor: pointer;
    text-transform: none;
    font-family: "Visby Regular";
  }
  button:last-child {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 14px;
    text-transform: none;
    font-family: "Visby Regular";
  }
  @media (max-width: 426px) {
    display: flex;
    flex-direction: column;
    button:first-child {
      width: 100%;
      margin-bottom: 1rem;
    }
    button:last-child {
      margin-left: 0rem;
    }
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 95%;
  border-radius: 10px;
  color: black;
  padding: 0.3rem;
  border: 1px solid #e0dfdc;
`;
export const Loaders = styled(AiOutlineLoading)`
  font-size: 52px;
  color: #027aae;
  top: 30%;
`;
export const SocialMediaContainer = styled(Card)`
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  h3 {
    margin-bottom: 0.1rem;
    margin-top: 0.3rem;
    font-size: 1.5rem;
  }
  h4 {
    margin: 0.1rem 0rem;
  }
  p {
    margin: 0.3rem 0rem;
    font-weight: italic;
    font-size: 1rem;
  }
  @media (max-width: 426px) {
    padding: 0.5rem;
  }
`;

export const CircularProgressContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FreelanceProjectsShowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97%;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  padding: 1rem;
  h3 {
    margin-bottom: 0.1rem;
    margin-top: 0.3rem;
  }
  h4 {
    margin: 0.1rem 0rem;
  }
  p {
    margin: 0.3rem 0rem;
    font-weight: italic;
    font-size: 0.8rem;
  }
`;
export const RateContainer = styled(Card)`
  padding: 2rem;
  margin-bottom: 1rem;
  width: 345px;
  border: 1px solid #ddd;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;
export const RateCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageContainer = styled.img`
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
`;

export const PayCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;
export const Bullet = styled.span`
  margin: 0 5px;
  font-size: 2rem;
  vertical-align: middle;
  font-family: "Visby bold";
  line-height: 0;
`;
