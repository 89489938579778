import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  LinkedInNetworkAssistantContainer,
  LinkedInProfileContainer,
  LinkedInProfileListContainer,
} from "../../Home.style.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../../../api/api.js";
import { CircularProgressContainer } from "../../../Profile/components/ProfileCommonStyles/ProfileCommonStyles.style.js";
const LinkedInNetworkAssistant = ({ open, setOpenAIActions, uid }) => {
  const [infoData, setInfoData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [linkedinProfileList, setLinkedInProfileList] = useState([]);
  const { userInformation } = useSelector((state) => state.user);
  const [pricingPlan, setPricingPlan] = useState(userInformation);
  const navigate = useNavigate();
  useEffect(() => {
    setPricingPlan(userInformation);
  }, [userInformation]);
  const handlingLinkedin = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(`/linkedInSearch/${uid}`, {
        infoData,
      });
      if (response.data.success) {
        console.log(response.data.data);
        setIsLoading(false);
        setLinkedInProfileList(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpenAIActions(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography variant="h6" component="div" style={{ flex: 1 }}>
            LinkedIn Networking Assistant
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAIActions(false);
            }}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "gray",
              cursor: "pointer",
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {isLoading ? (
            <LinkedInProfileListContainer>
              <CircularProgressContainer>
                <CircularProgress />
              </CircularProgressContainer>
            </LinkedInProfileListContainer>
          ) : (
            <>
              {linkedinProfileList.length > 0 ? (
                <>
                  <LinkedInProfileContainer>
                    <LinkedInProfileListContainer>
                      {linkedinProfileList.map((profile, index) => (
                        <li>
                          <p>{profile.title}</p>{" "}
                          <a
                            href={profile.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {profile.link}
                          </a>
                        </li>
                      ))}
                    </LinkedInProfileListContainer>
                  </LinkedInProfileContainer>
                </>
              ) : (
                <LinkedInNetworkAssistantContainer>
                  <TextField
                    color="secondary"
                    label="Target Role"
                    type="text"
                    required
                    value={infoData.role || ""}
                    onChange={(e) =>
                      setInfoData({
                        ...infoData,
                        role: e.target.value,
                      })
                    }
                    sx={{ width: "80%", marginBottom: 2 }}
                  />
                  <TextField
                    color="secondary"
                    label="Company of Interest"
                    type="text"
                    value={infoData.company || ""}
                    onChange={(e) =>
                      setInfoData({
                        ...infoData,
                        company: e.target.value,
                      })
                    }
                    sx={{ width: "80%", marginBottom: 2 }}
                  />
                  <TextField
                    color="secondary"
                    label="Networking Region"
                    type="text"
                    value={infoData.location || ""}
                    onChange={(e) =>
                      setInfoData({
                        ...infoData,
                        location: e.target.value,
                      })
                    }
                    sx={{ width: "80%", marginBottom: 2 }}
                  />
                </LinkedInNetworkAssistantContainer>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {pricingPlan?.price?.actionsIncluded <= 3 ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate("/pricing")}
            >
              Upgrade
            </Button>
          ) : (
            <>
              {linkedinProfileList.length === 0 && (
                <Button variant="contained" onClick={handlingLinkedin}>
                  Explore
                </Button>
              )}
            </>
          )}

          <Button
            variant="contained"
            onClick={() => {
              setOpenAIActions(false);
              setLinkedInProfileList([]);
              setInfoData({});
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LinkedInNetworkAssistant;
