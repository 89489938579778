import { styled } from "styled-components";
import { Box } from "@mui/material";
export const TextArea = styled.textarea`
  resize: none;
  height: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
  height: 13rem;
  margin-top: 0.5rem;
`;

export const JobBodyContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const IncompleteMessageContainer = styled.p`
  text-align: "justify";
  line-height: 1.9rem;
  font-size: 1.2rem;
  a {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  a:visited {
    color: black;
  }
`;

export const OriginalJobDescriptionContainer = styled(Box)`
  padding: 0.9rem;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  button {
    height: 2rem;
    width: 6rem;
    margin-right: 1rem;
  }
`;
