import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { base64encoded, base64encodedBold } from "./base64.js";
import { processUrl } from "./ProfileUtil.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs = {
  ...pdfMake.vfs,
  "VisbyRegular.ttf": base64encoded,
  "VisbyBold.ttf": base64encodedBold,
};
pdfMake.fonts = {
  VisbyRegular: {
    normal: "VisbyRegular.ttf",
    bold: "VisbyBold.ttf",
    italics: "VisbyRegular.ttf",
  },
};
export const createPdf = (profileData) => {
  const processDescription = (description) => {
    return description.split("\n").map((line) => line.replace(/^\s*-\s*/, ""));
  };
  const lineSeparator = (value) => {
    return {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 5,
          x2: value,
          y2: 5,
          lineWidth: 1,
        },
      ],
      margin: [0, 0, 0, 10],
    };
  };

  const formatDateRange = (fromMonth, fromYear, toMonth, toYear) => {
    return `${fromMonth}-${fromYear} to ${
      toYear ? `${toMonth}-${toYear}` : "Present"
    }`;
  };
  const renderSkills = () => {
    if (profileData?.skillSet && profileData.skillSet.length > 0) {
      const skillsText = profileData.skillSet.join(", ");
      return [
        { text: "Skills", style: "rightSectionHeader" },
        lineSeparator(45),
        { text: skillsText, style: "skillsCourse" },
      ];
    }
    return [];
  };

  const renderCourses = () => {
    if (profileData?.coursesList && profileData.coursesList.length > 0) {
      const courseText = profileData.coursesList.join(", ");
      return [
        { text: "Courses", style: "rightSectionHeader" },
        lineSeparator(70),
        { text: courseText, style: "skillsCourse" },
      ];
    }
    return [];
  };
  const docDefinition = {
    defaultStyle: {
      font: "VisbyRegular",
    },
    content: [
      { text: `${profileData?.name}`, style: "header" },
      {
        text: `${profileData?.primaryRole} • ${profileData?.location} • ${profileData?.email}`,
        style: "subHeader",
      },
      {
        text: `${processUrl(profileData?.socialMedia?.hrhero)}`,
        style: "content",
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5,
            x2: 515,
            y2: 5,
            lineWidth: 1,
          },
        ],
      },
      { text: "\n" },
      {
        width: "100%",
        stack: [
          { text: "Summary", style: "sectionHeader" },
          lineSeparator(80),
          { text: profileData?.bio, style: "bioStyle" },
          { text: "Work Experience", style: "sectionHeader" },
          lineSeparator(135),
          ...profileData?.experiences.map((exp) => ({
            stack: [
              {
                text: [
                  { text: `${exp.company} • `, bold: true, fontSize: 14 },
                  {
                    text: `${exp.title}`,
                  },
                  { text: ` • `, bold: true, fontSize: 14 },
                  {
                    text: formatDateRange(
                      exp.fromMonth,
                      exp.fromYear,
                      exp.toMonth,
                      exp.toYear
                    ),
                    fontSize: 10,
                  },
                ],

                style: "leftColumn",
              },

              {
                ul: processDescription(exp.description).map((line) => ({
                  text: line,
                  margin: [0, 5, 0, 5],
                })),
                style: "descriptionStyle",
              },
            ],
          })),
          ...renderSkills(),
          { text: "Education", style: "sectionHeader" },
          lineSeparator(80),
          ...profileData?.educations.map((education) => ({
            style: "educationStyle",
            stack: [
              {
                text: [
                  { text: `${education.school} • `, bold: true },

                  {
                    text: `${education.degree} in ${education.fieldOfStudy}`,
                  },
                  { text: ` • `, bold: true, fontSize: 14 },
                  {
                    text: formatDateRange(
                      education.fromMonth,
                      education.fromYear,
                      education.toMonth,
                      education.toYear
                    ),
                  },
                ],
                style: "leftColumn",
              },

              {
                ul: processDescription(education.description).map((line) => ({
                  text: line,
                  margin: [0, 5, 0, 5],
                })),
                style: "descriptionStyle",
              },
            ],
          })),
          ...renderCourses(),
          { text: "Projects", style: "sectionHeader" },
          lineSeparator(70),
          ...profileData?.projects.map((project) => ({
            style: "projectStyle",
            stack: [
              {
                text: [
                  { text: `${project.projectName} • `, bold: true },
                  {
                    text: formatDateRange(
                      project.fromMonth,
                      project.fromYear,
                      project.toMonth,
                      project.toYear
                    ),
                  },
                ],
                style: "leftColumn",
              },
              {
                ul: processDescription(project.description).map((line) => ({
                  text: line,
                  margin: [0, 5, 0, 5],
                })),
                style: "descriptionStyle",
              },
            ],
          })),
          { text: "Achievements", style: "sectionHeader" },
          lineSeparator(120),
          { text: profileData?.achievements, style: "bioStyle" },
        ],
      },
    ],
    styles: {
      header: {
        fontSize: 26,
        bold: true,
        margin: [0, 0, 0, 1],
        alignment: "center",
      },
      subHeader: {
        fontSize: 12,
        margin: [0, 0, 0, 7],
        alignment: "center",
      },
      content: {
        fontSize: 12,
        margin: [0, 0, 0, 7],
        alignment: "center",
      },
      bioStyle: {
        alignment: "left",
        lineHeight: 1.6,
      },
      sectionHeader: {
        bold: true,
        fontSize: 16,
        margin: [0, 20, 0, 1],
      },
      rightSectionHeader: {
        bold: true,
        fontSize: 16,
        margin: [0, 10, 0, 1],
      },
      experienceStyle: {
        margin: [0, 10, 0, 10],
      },
      educationStyle: {
        margin: [0, 5, 0, 10],
      },
      projectStyle: {
        margin: [0, 5, 0, 10],
      },
      leftColumn: {
        margin: [0, 10, 0, 10],
      },
      descriptionStyle: {
        margin: [0, 1, 0, 0],
      },
      skillsCourse: {
        alignment: "left",
        lineHeight: 1.6,
      },
    },
  };

  pdfMake.createPdf(docDefinition).download(`${profileData?.name}.pdf`);
};
