import { useState } from "react";
import { teams } from "../../dummyData";
import { GoPencil, GoPeople } from "react-icons/go";
import {
  Container,
  HeaderContainer,
  TeamMembersContainer,
  MemberContainer,
  MemberHeaderContainer,
  MemberHeader,
  MemberIconContainer,
  IconButtonContainer,
  MemberBodyContainer,
  MemberBody,
} from "./TeamMembers.styles";
import { Typography } from "@mui/material";
const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState(teams);
  return (
    <Container>
      <HeaderContainer>
        <Typography variant="h4">XYZ's Team</Typography>
      </HeaderContainer>
      <TeamMembersContainer>
        <MemberContainer>
          <MemberHeaderContainer>
            <MemberHeader>
              <GoPeople
                style={{
                  marginRight: "0.2rem",
                  paddingTop: "0.3rem",
                  fontSize: "20px",
                }}
              />
              <Typography variant="h5">Team Members</Typography>
            </MemberHeader>
            <MemberIconContainer>
              <IconButtonContainer aria-label="add">
                <GoPencil style={{ color: "black", fontSize: "16px" }} />
              </IconButtonContainer>
            </MemberIconContainer>
          </MemberHeaderContainer>
          <MemberBodyContainer>
            {teamMembers.map((member, index) => {
              return (
                <MemberBody
                  bottomBorder={index === teamMembers.length - 1 && "false"}
                >
                  <Typography variant="h6">{member.name}</Typography>
                  <Typography variant="h6">{member.priviledges}</Typography>
                </MemberBody>
              );
            })}
          </MemberBodyContainer>
        </MemberContainer>
      </TeamMembersContainer>
    </Container>
  );
};

export default TeamMembers;
