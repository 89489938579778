import { jwtDecode } from "jwt-decode";
import DefaultLayout from "../../commonComponents/DefaultLayout/DefaultLayout";

const PrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  if (user && token && !isTokenExpired(token)) {
    return <DefaultLayout>{children}</DefaultLayout>;
  } else {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("plan");
    window.location.href = "/";
    return null;
  }
};

export default PrivateRoute;
