import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBQADmHdaJ0QBBz5oJJjT4COOT5LykLzk4",
  authDomain: "authentication-86b5e.firebaseapp.com",
  projectId: "authentication-86b5e",
  storageBucket: "authentication-86b5e.appspot.com",
  messagingSenderId: "468113928526",
  appId: "1:468113928526:web:1da22485cb1387bc78ee1f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
